import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import ImageDefault from "../../../../assets/img/image-default.png";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";

import { useTranslation } from "react-i18next";

import PartnersTableInfoClient from './PartnersTableInfoClient/PartnersTableInfoClient';

import "../../../../assets/sass/accordion.scss";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { ErrorMessage } from "@hookform/error-message";

//Components EndPoints
import GetPromoters from "../../../../components/C2Points/GetPromoters";
import GetCompanyType from "../../../../components/C2Points/GetCompanyType";
import GetCountrys from "../../../../components/C2Points/GetCountrys";
//import GetCitys from "../../../../components/C2Points/GetCitys";

import GetAfiliatesType from "../../../../components/C2Points/GetAfiliatesType";
import GetStates from "../../../../components/C2Points/GetStates";
import GetBranchOffice from "../../../../components/C2Points/GetBranchOffice";
import GetCitysByState from "../../../../components/C2Points/GetCitysByState";

export interface propAccordion {
    action: any;
    ContextAwareToggle: any;
    editPartners?: any
    setPartnersControlObject?: any;
    isEdit?: any;
    infoEdit?: any;
    hasSavedInputs?: any;
};

const AccordionPersonal: React.FC<propAccordion> = React.memo(({
    action, ContextAwareToggle, isEdit = false, infoEdit, editPartners, hasSavedInputs = false
}: propAccordion) => {
    const { t } = useTranslation();

    const [profileImg, setProfileImg] = useState<any>((isEdit === true || hasSavedInputs === true) && infoEdit.compLogoBase64 !== null ? infoEdit.compLogoBase64 : ImageDefault);
    const [profileNameImg, setProfileNameImg] = useState<any>("None");
    const [idCompType, setIdCompType] = useState<string>((isEdit === true || hasSavedInputs === true) ? infoEdit.compType?.toString() : '1');
    const [messageError, setMessageError] = useState<boolean>(false);
    const [country, setCountry] = useState<any>((isEdit === true || hasSavedInputs === true) ? { description: infoEdit.countryDesc, id: `${infoEdit.country}` } : '');
    const [contact, setContact] = useState<any>((isEdit === true || hasSavedInputs === true) ? infoEdit.supplierContact : '');

    const [addressError, setAddressError] = useState<any>(false);
    const [countryError, setCountryError] = useState<any>(false);
    const [cpfError, setCpfError] = useState<any>(false);
    const [controlZipCode, setControlZipCode] = useState<any>(0);

    const idPartner = window.location.href.split('id=')[1];

    const imageHandler = (e: any) => {
        if (e.target.files[0].size <= 2097152) { //2MB -> 2097152
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setProfileImg(reader.result)
                }
            }
            reader.readAsDataURL(e.target.files[0]);
            setProfileNameImg(e.target.files[0].name);
        } else {
            alert('imagem muito grande');
        }
    }

    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(false);
    const validated = false;

    const getChangeCompType = (idCT: any) => {
        setIdCompType(idCT);
    };   

    const SetCompType = () => {
        if (idCompType === "1") {
            return (
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="formInfoCompanyCPF">
                        <Form.Label>CPF:</Form.Label>
                        <Controller
                            control={control}
                            name="cpf"
                            rules={{ required: { value: true, message: 'Por favor, informe o CPF.' } }}
                            render={({ field }: any) => (
                                <InputMask mask="999.999.999-99" value={field?.value} onChange={field?.onChange}>
                                    {(inputProps: any) => (
                                        <Form.Control
                                            {...inputProps}
                                            aria-invalid={errors?.cpf ? "true" : ""}
                                            label={t("accordionInfoCompany.subM")}
                                            variant="standard"
                                            margin="normal"
                                            type="text"
                                            required
                                            autoComplete='off'
                                        />
                                    )}
                                </InputMask>
                            )}
                        />
                        {cpfError === true
                            ? <small style={{ color: "red" }}>Por favor, informe um CPF válido.</small>
                            : <></>
                        }
                        <ErrorMessage
                            errors={errors}
                            name="cpf"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                </Row>
            );
        } else if (idCompType === "2") {
            return (
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="formInfoCompanyCNPJ">
                        <Form.Label>CNPJ:</Form.Label>
                        <Controller
                            control={control}
                            name="cnpj"
                            rules={{ required: { value: true, message: 'Por favor, informe o cnpj.' } }}
                            render={({ field }: any) => (
                                <InputMask mask="99.999.999/9999-99" value={field?.value} onChange={field?.onChange}>
                                    {(inputProps: any) => (
                                        <Form.Control
                                            {...inputProps}
                                            aria-invalid={errors?.cnpj ? "true" : ""}
                                            label={t('accordionInfoCompany.cnpj')}
                                            variant="standard"
                                            margin="normal"
                                            required
                                            autoComplete='off'
                                        />
                                    )}
                                </InputMask>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="cnpj"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formInfoCompanySubscription">
                        <Form.Label>{t('accordionInfoCompany.subM')}</Form.Label>
                        <Controller
                            control={control}
                            name="inscricaoMunicipal"
                            rules={{ required: { value: true, message: 'Por favor, informe a Inscrição Municipal.' } }}
                            render={({ field }: any) => (
                                <Form.Control
                                    {...field}
                                    aria-invalid={errors?.inscricaoMunicipal ? "true" : ""}
                                    label={t('accordionInfoCompany.subM')}
                                    variant="standard"
                                    margin="normal"
                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                    required
                                    autoComplete='off'
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="inscricaoMunicipal"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formInfoCompanySubscription">
                        <Form.Label>{t('accordionInfoCompany.subE')}</Form.Label>
                        <Controller
                            control={control}
                            name="inscricaoEstadual"
                            rules={{ required: { value: false, message: 'Por favor, informe o número de Inscrição Estadual.' } }}
                            render={({ field }: any) => (
                                <Form.Control
                                    {...field}
                                    aria-invalid={errors?.inscricaoEstadual ? "true" : ""}
                                    label={t('accordionInfoCompany.subE')}
                                    variant="standard"
                                    margin="normal"
                                    type="text"
                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                    required
                                    autoComplete='off'
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="inscricaoEstadual"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                </Row>
            )
        } else if (idCompType === "3") {
            return (
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="formInfoCompanyID">
                        <Form.Label>{t('accordionInfoCompany.id')}:</Form.Label>
                        <Controller
                            control={control}
                            name="code"
                            rules={{ required: { value: true, message: 'Por favor, informe o ID.' } }}
                            render={({ field }: any) => (
                                <Form.Control
                                    {...field}
                                    aria-invalid={errors?.code ? "true" : ""}
                                    label={t('accordionInfoCompany.id')}
                                    variant="standard"
                                    margin="normal"
                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                    required
                                    autoComplete='off'
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="code"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                </Row>
            );
        } else {
            return (<></>);
        }
    };

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            compName: (isEdit === true || hasSavedInputs === true) ? infoEdit.compName : '',
            compFantasia: (isEdit === true || hasSavedInputs === true) ? infoEdit.compFantasia : '',
            socialReason: (isEdit === true || hasSavedInputs === true) ? infoEdit.socialReason : '',
            website: (isEdit === true || hasSavedInputs === true) ? infoEdit.website : '',
            clientType: (isEdit === true || hasSavedInputs === true) ? infoEdit.clientType : '',
            filial: (isEdit === true || hasSavedInputs === true) ? infoEdit.filial : '',
            compType: (isEdit === true || hasSavedInputs === true) ? infoEdit.compType : '1',
            compLogoBase64: (isEdit === true || hasSavedInputs === true) ? infoEdit.compLogoBase64 : '',
            compLogoName: (isEdit === true || hasSavedInputs === true) ? infoEdit.compLogoName : '',
            cpf: (isEdit === true || hasSavedInputs === true) ? infoEdit.cpf : '',
            cnpj: (isEdit === true || hasSavedInputs === true) ? infoEdit.cnpj : '',
            code: (isEdit === true || hasSavedInputs === true) ? infoEdit.code : '',
            promotor: (isEdit === true || hasSavedInputs === true) ? infoEdit.promotor : '',
            country: (isEdit === true || hasSavedInputs === true) ? { description: infoEdit.countryDesc, id: infoEdit.country } : '',
            city: (isEdit === true || hasSavedInputs === true) ? infoEdit.country === 30 ? { description: infoEdit.municipioDesc, id: infoEdit.municipioId } : infoEdit.city : '',
            state: (isEdit === true || hasSavedInputs === true) ? infoEdit.country === 30 ? { description: infoEdit.stateDesc, id: infoEdit.state } : infoEdit.state : '',
            address: (isEdit === true || hasSavedInputs === true) ? infoEdit.address : '',
            numero: (isEdit === true || hasSavedInputs === true) ? infoEdit.numero : '',
            compComplem: (isEdit === true || hasSavedInputs === true) ? infoEdit.compComplem : '',
            compBairro: (isEdit === true || hasSavedInputs === true) ? infoEdit.compBairro : '',
            zipCode: (isEdit === true || hasSavedInputs === true) ? infoEdit.zipCode : '',
            municipioId: (isEdit === true || hasSavedInputs === true) ? infoEdit.municipioId : '',
            inscricaoEstadual: (isEdit === true || hasSavedInputs === true) ? infoEdit.inscricaoEstadual : '',
            inscricaoMunicipal: (isEdit === true || hasSavedInputs === true) ? infoEdit.inscricaoMunicipal : ''
        },
    });


    const allValues = () => {
        const data = getValues();
        validateSubmit(data);
    }

    const watchZipCode = watch(`zipCode`, false);
    const watchState = watch(`state`, '');

    useEffect(() => {
        setControlZipCode(watchZipCode?.length)
        if (watchZipCode?.length === 5) {
            if (watchZipCode?.length > controlZipCode) {
                setValue('zipCode', `${watchZipCode}-`)
            }
        }


        if (watchZipCode?.length > 9) {
            var controlLength: any = watchZipCode;
            controlLength = controlLength.split('');
            controlLength.pop();
            controlLength = controlLength.join('');
            setValue('zipCode', `${controlLength}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchZipCode])

    const getContacts = (objContact: any) => {
        setContact(objContact);
    }

    function cpfValidator(strCPF: any) {
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF === "00000000000") return false;

        for (var i: any = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }

    function validateSubmit(data: any) {
        var cpf: any = data.cpf.replace(/\./g, '').replace(/-/g, '').replace(/\//g, '');

        if (country == null || contact.length === 0 || (idCompType === '1' ? cpfValidator(cpf) === false : false) || data.address.length < 4) {
            if (country == null) {
                setCountryError(true);
            } else {
                setCountryError(false);
            }
            //
            if (contact.length === 0) {
                setMessageError(true);
            } else {
                setMessageError(false);
            }
            //
            if (data.address.length < 4) {
                setAddressError(true);
            } else {
                setAddressError(false);
            }
            //
            if (cpfValidator(cpf) === false) {
                setCpfError(true);
            } else {
                setCpfError(false);
            }

            return false;
        } else {
            setCountryError(false);
            setMessageError(false);
            setCpfError(false);
            setAddressError(false);
            return true;
        }
    }

    const onSubmit = (data: any) => {
        if (isEdit === false) {
            /// ADD partners PAGE
            if (validateSubmit(data)) {
                data.compFantasia = data.compName;
                data.compLogoBase64 = profileImg;
                data.compLogoName = profileNameImg;
                data.compType = idCompType;
                data.country = country.id;
                data.countryDesc = country.description;

                if (country.id === '30') { //// BRAZIL
                    data.stateDesc = data.state.description;
                    data.state = data.state.id;
                    const cityT = data.city;
                    data.city = cityT.description;
                    data.municipioId = cityT.id;
                    data.municipioDesc = cityT.description;
                } else {
                    data.municipioId = '0';
                }
                data.supplierContactModel = contact;
                data.supplierContact = contact;
                data.affiliateContact = contact;

                data.cnpj = idCompType !== "2" ? "" : data.cnpj.replace(/\./g, '').replace(/-/g, '').replace(/\//g, '');
                data.cpf = idCompType !== "1" ? "" : data.cpf.replace(/\./g, '').replace(/-/g, '').replace(/\//g, '');
                /* data.cpf = idCompType !== "1" ? "" : data.cpf.replace(/\./g, '').replace(/\-/g, '').replace(/\//g, ''); */
                data.code = idCompType !== "3" ? "" : data.code;
                data.inscricaoMunicipal = idCompType !== "2" ? 0 : Number(data.inscricaoMunicipal?.split('.').join('').split('-').join(''));
                data.inscricaoEstadual = idCompType !== "2" ? 0 : Number(data.inscricaoEstadual?.split('.').join('').split('-').join(''));

               
                if(data.inscricaoEstadual === 0){
                    delete data.inscricaoEstadual
                }
               

                data.zipCode = data.zipCode.replace(/-/g, '');

                setCtrlEventKey(true);
                action(data);

            }
        } else {
            /// EDIT partners PAGE
            let edit: any = { ...infoEdit, ...data };
            edit.affiliateId = parseInt(idPartner);
            console.log(edit)
            
            edit.inscricaoMunicipal = idCompType !== "2" ? 0 : Number(edit.inscricaoMunicipal.toString().split('.').join('').split('-').join(''));
            edit.inscricaoEstadual = idCompType !== "2" ? 0 : Number(edit.inscricaoEstadual?.toString().split('.').join('').split('-').join(''));

            if(edit.inscricaoEstadual === 0 || isNaN(edit.inscricaoEstadual)){
                delete edit.inscricaoEstadual
            }

           /* if (idCompType === '2') {
                edit.inscricaoEstadual = parseInt(data.inscricaoEstadual.split('.').join('').split('-').join(''));
                edit.inscricaoMunicipal = parseInt(data.inscricaoMunicipal.split('.').join('').split('-').join(''));
            } */
            edit.compType = idCompType;
            edit.compLogoBase64 = profileImg;

            if (edit.promotor?.id) {
                edit.promotor = edit.promotor.id;
            }
            if (edit.clientType?.id) {
                edit.clientType = edit.clientType.id;
            }

            edit.country = country.id;
            if (country.id === '30' || country.id === 30) { //// BRAZIL

                edit.state = edit.state.id;
                const cityT = edit.city;
                edit.city = cityT.description;
                edit.municipioId = cityT.id;
            } else {
                edit.municipioId = '0';
            }

            edit.supplierContactModel = contact;
            edit.costCenterSupplierModel = edit.costCenterSupplierDTO;
            edit.usersSupplierModel = edit.usersSupplierDTO;

            if(edit.masterFilho === null){
                edit.masterFilho = '0';
            }

            //console.log(edit)
            editPartners(edit);
        }
    };

    useEffect(() => {
        if (country?.id === '30') {
            setValue('city', '');
            setValue('state', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country])

    useEffect(() => {
        setTimeout(() => {
            if (country?.id === '30' && (isEdit === true || hasSavedInputs === true)) {
                setValue('city', { description: infoEdit.municipioDesc, id: infoEdit.municipioId });
                setValue('state', { description: infoEdit.stateDesc, id: infoEdit.state });
            }
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /* const testeScroll = () => {
        for (let champ in errors){
            console.log(`[name="${champ}"]`)
                var bar: any = document.querySelector(`[name="${champ}"]`);
                bar.scrollIntoView({behavior: 'smooth', block: 'start'});
                
                console.log(`${champ}`);
                break;
            } 
    } */

    return (
        <Card className="bg-white mt-4" id="editInfoCompanyForms">
            <Card.Header id="accordionPersonal">
                <ContextAwareToggle eventKey="0" status={ctrlEventKey}>Informações do Cliente</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Container className="p-0" fluid>
                        <Row>
                            <div>
                                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                                    <Row>
                                        <div className="label">
                                            <label className="image-upload" htmlFor="input">
                                                <div className="logo-info-company mx-3 mb-5 mt-2"
                                                    style={{ backgroundImage: `url(${profileImg})`, backgroundSize: 'contain', cursor: 'pointer', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                                    {/* <img src={profileImg} alt="Logo" /> */}
                                                </div>
                                            </label>
                                            <input type="file" accept="image/*" name="image-upload" id="input" onChange={imageHandler} />


                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        {infoEdit?.compType === 1 ?
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyName">
                                            <Form.Label>Nome</Form.Label>
                                            <Controller
                                                control={control}
                                                name="compName"
                                                rules={{ required: { value: true, message: 'Por favor, informe o nome do cliente.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.compName ? "true" : ""}
                                                        label={t("accordionInfoCompany.name")}
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="compName"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        :
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyName">
                                            <Form.Label>Razão Social</Form.Label>
                                            <Controller
                                                control={control}
                                                name="compName"
                                                rules={{ required: { value: idCompType === "2", message: 'Por favor, informe a razão social.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.compName ? "true" : ""}
                                                        label={t("accordionInfoCompany.compName")}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="compName"
                                                render={({ message }) => (
                                                    <small style={{ color: "red" }}>{message}</small>
                                                )}
                                            />
                                        </Form.Group>  
                                        }
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyNameFantasy">
                                            <Form.Label>Nome Fantasia</Form.Label>
                                            <Controller
                                                control={control}
                                                name="compFantasia"
                                                rules={{ required: { value: idCompType === "2", message: 'Por favor, informe o nome fantasia.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.compFantasia ? "true" : ""}
                                                        label={t("accordionInfoCompany.nameFant")}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="compFantasia"
                                                render={({ message }) => (
                                                    <small style={{ color: "red" }}>{message}</small>
                                                )}
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId="formInfoCompanyWebsite"
                                        >
                                            <Form.Label>{t("accordionInfoCompany.web")}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="website"
                                                rules={{
                                                    required: {
                                                        value: idCompType === "2",
                                                        message: "Por favor, informe o website do cliente",
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.website ? "true" : ""}
                                                        label={t("accordionInfoCompany.web")}
                                                        variant="standard"
                                                        margin="normal"s
                                                        //required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="website"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyNameFantasy">
                                            <Form.Label>Tipo de cliente</Form.Label>
                                            <Controller
                                                control={control}
                                                name="clientType"
                                                rules={{ required: { value: true, message: 'Por favor, selecione o tipo de cliente.' } }}
                                                render={({ field }: any) => (
                                                    <GetAfiliatesType
                                                        propsField={field}
                                                        propsErrors={errors}
                                                        propsLabel={'Tipo de Cliente'}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="clientType"
                                                render={({ message }) => (
                                                    <small style={{ color: "red" }}>{message}</small>
                                                )}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyPromoter">
                                            <Form.Label>{t('accordionInfoCompany.promo')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="promotor"
                                                rules={{ required: { value: idCompType === "2", message: 'Por favor, selecione o promotor.' } }}
                                                render={({ field }: any) => (
                                                    <GetPromoters
                                                        propsField={field}
                                                        propsErrors={errors}
                                                        propsLabel={t('accordionInfoCompany.web')}
                                                        isEdit={isEdit}
                                                        infoEdit={infoEdit}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="promotor"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyNameFantasy">
                                            <Form.Label>Filial</Form.Label>
                                            <Controller
                                                control={control}
                                                name="filial"
                                                rules={{ required: { value: false, message: 'Por favor, informe a filial.' } }}
                                                render={({ field }: any) => (
                                                    <GetBranchOffice propsField={field} propsErrors={errors}/>
                                                )}
                                            />
                                            {/* <div className={"endpoint"}>
                                                        <Form.Select
                                                            {...field}
                                                            aria-invalid={errors?.filial ? "true" : ""}
                                                            label="Filial"
                                                            as="select"
                                                            variant="standard"
                                                            margin="normal"
                                                            defaultChecked={'1'}
                                                            required
                                                        >
                                                            <option value='1'>Sim</option>
                                                            <option value='2'>Não</option>
                                                        </Form.Select>
                                                    </div> */}
                                            <ErrorMessage
                                                errors={errors}
                                                name="filial"
                                                render={({ message }) => (
                                                    <small style={{ color: "red" }}>{message}</small>
                                                )}
                                            />
                                        </Form.Group>
                                    </Row>                                   
                                    <small>{t('accordionInfoCompany.text')}</small>
                                    <Row>
                                        <Controller
                                            control={control}
                                            name="compType"
                                            rules={{ required: { value: true, message: '' } }}
                                            render={({ field }: any) => (
                                                <GetCompanyType
                                                    propsField={field}
                                                    getChangeCompType={getChangeCompType}
                                                    compType={(isEdit === true || hasSavedInputs === true) ? infoEdit.compType?.toString() : '1'}
                                                />
                                            )}
                                        />
                                    </Row>

                                    <SetCompType />

                                    <hr className="my-5" />
                                    <h5 className="text-uppercase mb-3">{t('accordionInfoCompany.address')}</h5>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyCountry">
                                            <Form.Label>{t('accordionInfoCompany.country')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="country"
                                                rules={{ required: { value: false, message: 'Por favor, selecione o país.' } }}
                                                render={({ field }: any) => (
                                                    <GetCountrys
                                                        propsField={field}
                                                        propsLabel={t('accordionInfoCompany.country')}
                                                        propsErrors={errors}
                                                        watchCountry={setCountry}
                                                    />
                                                )}
                                            />
                                            {countryError === true
                                                ? <small style={{ color: "red" }}>{t('accordionInfoCompany.validation.country')}</small>
                                                : <></>
                                            }
                                            <ErrorMessage
                                                errors={errors}
                                                name="country"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyCEP">
                                            <Form.Label>{t('accordionInfoCompany.cep')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="zipCode"
                                                rules={{ required: { value: true, message: 'Por favor, informe o CEP.' } }}
                                                render={({ field }: any) => (
                                                    <Controller
                                                        control={control}
                                                        name="zipCode"
                                                        rules={{ required: { value: true, message: t('accordionInfoCompany.validation.cep') } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.zipCode ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />
                                            {/* <InputMask mask="99999-999" value={field?.value} onChange={field?.onChange}>
                                                        {(inputProps: any) => (
                                                            <Form.Control
                                                                {...inputProps}
                                                                aria-invalid={errors?.zipCode ? "true" : ""}
                                                                label={t("accordionInfoCompany.sub")}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    </InputMask> */}
                                            <ErrorMessage
                                                errors={errors}
                                                name="zipCode"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        {country?.id === '30' || country?.id === 30
                                            ?
                                            <Form.Group as={Col} md="4" controlId="formInfoCompanyState">
                                                <Form.Label>{t('accordionInfoCompany.state')}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="state"
                                                    rules={{ required: { value: true, message: t('accordionInfoCompany.validation.state') } }}
                                                    render={({ field }: any) => (
                                                        <GetStates
                                                            propsField={field}
                                                            propsLabel={t('accordionInfoCompany.state')}
                                                            propsErrors={errors}
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="state"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                            :
                                            <Form.Group as={Col} md="4" controlId="formInfoCompanyState">
                                                <Form.Label>{t('accordionInfoCompany.state')}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="state"
                                                    rules={{ required: { value: true, message: t('accordionInfoCompany.validation.state') } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="state"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                        }
                                    </Row>
                                    <Row className="mb-3">
                                        {country?.id === '30' || country?.id === 30
                                            ?
                                            <Form.Group as={Col} md="4" controlId="formInfoCompanyCity">
                                                <Form.Label>{t('accordionInfoCompany.city')}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="city"

                                                    rules={{ required: { value: true, message: t('accordionInfoCompany.validation.city') } }}
                                                    render={({ field }: any) => (
                                                        <GetCitysByState propsField={field} propsErrors={errors} uf={watchState.id} />
                                                        )}
                                                        />
                                                        {/* <GetCitys
                                                            propsField={field}
                                                            propsLabel={t('accordionInfoCompany.city')}
                                                            propsErrors={errors}
                                                        /> */}
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="city"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                            :
                                            <Form.Group as={Col} md="4" controlId="formInfoCompanyCity">
                                                <Form.Label>{t('accordionInfoCompany.city')}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="city"

                                                    rules={{ required: { value: true, message: t('accordionInfoCompany.validation.city') } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="city"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                        }
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyDistrict">
                                            <Form.Label>{t('accordionInfoCompany.distr')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="compBairro"
                                                rules={{ required: { value: true, message: 'Por favor, informe o bairro.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.compBairro ? "true" : ""}
                                                        label={t('accordionInfoCompany.distr')}
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="compBairro"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyAddress">
                                            <Form.Label>{t('accordionInfoCompany.address')}:</Form.Label>
                                            <Controller
                                                control={control}
                                                name="address"
                                                rules={{ required: { value: false, message: 'Por favor, informe o endereço.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.zipCode ? "true" : ""}
                                                        label={t("accordionInfoCompany.address")}
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            {addressError === true
                                                ? <small style={{ color: "red" }}>Por favor, informe um endereço com pelo menos 4 caracteres.</small>
                                                : <></>
                                            }
                                            <ErrorMessage
                                                errors={errors}
                                                name="address"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyNumber">
                                            <Form.Label>{t('accordionInfoCompany.num')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="numero"
                                                rules={{ required: { value: true, message: 'Por favor, informe o número.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.numero ? "true" : ""}
                                                        label={t("accordionInfoCompany.num")}
                                                        variant="standard"
                                                        margin="normal"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="numero"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyComplement">
                                            <Form.Label>{t('accordionInfoCompany.comp')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="compComplem"
                                                //rules={{ required: { value: true, message: 'Por favor, informe o nome fantasia.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        label={t("accordionInfoCompany.comp")}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            {/* <ErrorMessage
                                                errors={errors}
                                                name="compComplem"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            /> */}
                                        </Form.Group>
                                    </Row>
                                    <hr className="my-5" />
                                    <h5 className="text-uppercase mb-3">{t('accordionInfoCompany.contact')}</h5>
                                    <Row>
                                        <PartnersTableInfoClient
                                            setMessageError={setMessageError}
                                            messageError={messageError}
                                            action={getContacts}
                                            isEdit={isEdit}
                                            hasSavedInputs={hasSavedInputs}
                                            infoEdit={infoEdit?.affiliateContact}
                                        />
                                    </Row>
                                    <Row className="d-flex justify-content-end mx-0 mt-5">
                                        {
                                            isEdit === true
                                                ?
                                                <div className="data" style={{ width: 'unset' }}>
                                                    <div className="btn-default mr-2 text-uppercase btn btn-outline-primary" onClick={() => { window.location.reload() }}>Cancelar</div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        <Button
                                            type="submit"
                                            className="form-button"
                                            onClick={allValues}
                                        >
                                            {t('accordionInfoCompany.buttonContinue')}
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </Row>
                    </Container>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
});

export default AccordionPersonal;
