import React from 'react';
import { Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface propCard {
    info: any,
    t?: any,
};

const DetailsCkeckoutTour: React.FC<propCard> = ({
    info, t
}: propCard) => {

    return (
        <>
            <Card className="card-unit bg-white">
                <div className="container-fluid">
                    <FontAwesomeIcon
                        className='icon-primary'
                        icon={["fad", "map-marked-alt"]}
                        size="1x"
                        style={{ marginBottom: "5px", width: "25px", fontSize: "1.5rem" }}
                    />
                    <div className="mb-2">
                        <h3 className="details-title">{info.productName}</h3>
                        <small>{info.modalityName}</small>
                    </div>

                    <div className="details-date d-flex justify-content-between">
                        <small>{t("checkout.detailsCheckout.date")} {info.date.split('-').reverse().join('/')}</small>
                        <small>{t("checkout.detailsCheckout.hour")} {info.time}</small>
                    </div>

                    <div className='details-boarding'>
                        {
                            info.sellingType === 2
                                ?
                                <>
                                    {info.tourEmbarque && <p><strong>{t("checkout.detailsCheckout.boarding")}</strong> {info.tourEmbarque}</p>}
                                    {info.meetingPoint && <p><strong>{t("checkout.detailsCheckout.address")}</strong> {info.meetingPoint}</p>}
                                    {info.tourHoraEmbarque && <p><strong>{t("checkout.detailsCheckout.boardingHour")}</strong> {info.tourHoraEmbarque}</p>}
                                </>
                                :
                                <>
                                    {info.meetingPoint && <p><strong>{t("checkout.detailsCheckout.address")}</strong> {info.meetingPoint}</p>}
                                </>
                        }
                    </div>

                    <hr />
                    {info.sellingType === 1 ?
                        <div className="details-price">
                            <div className="price-per">
                                <p>{t("checkout.detailsCheckout.people")}</p>
                                <p>{info.totalPeople}x</p>
                                <p>R$ {(info.priceGlobalPeople).toFixed(2).replace('.', ',')}</p>
                            </div>
                        </div>
                        :
                        <div className="details-price">
                            {info.adults > 0
                                ?
                                <div className="price-per">
                                    {/* <p>{t("checkout.detailsCheckout.adults")}</p> */}
                                    <p>{info?.labelsSorted[0]}</p>
                                    <p>{info.adults}x</p>
                                    <p>R$ {(info.priceAdults).toFixed(2).replace('.', ',')}</p>
                                </div>
                                :
                                <></>
                            }
                            {info.childs > 0
                                ?
                                <div className="price-per">
                                    {/* <p>{t("checkout.detailsCheckout.children")}</p> */}
                                    <p>{info?.labelsSorted[1]}</p>
                                    <p>{info.childs}x</p>
                                    <p>R$ {(info.priceChilds).toFixed(2).replace('.', ',')}</p>
                                </div>
                                :
                                <></>
                            }
                            {info.infants > 0
                                ?
                                <div className="price-per">
                                    {/* <p>{t("checkout.detailsCheckout.infants")}</p> */}
                                    <p>{info?.labelsSorted[2]}</p>
                                    <p>{info.infants}x</p>
                                    <p>R$ {(info.priceInfants).toFixed(2).replace('.', ',')}</p>
                                </div>
                                :
                                <></>
                            }
                            {info.elders > 0
                                ?
                                <div className="price-per">
                                    {/* <p>{t("checkout.detailsCheckout.elder")}</p> */}
                                    <p>{info?.labelsSorted[3]}</p>
                                    <p>{info.elders}x</p>
                                    <p>R$ {(info.priceElders).toFixed(2).replace('.', ',')}</p>
                                </div>
                                :
                                <></>
                            }
                            {info.student > 0
                                ?
                                <div className="price-per">
                                    {/* <p>{t("checkout.detailsCheckout.students")}</p> */}
                                    <p>{info?.labelsSorted[4]}</p>
                                    <p>{info.student}x</p>
                                    <p>R$ {(info.priceStudent).toFixed(2).replace('.', ',')}</p>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    }

                    <hr />

                    <div className='total-price'>
                        <p><strong>R${((info.adults * info.priceAdults) + (info.childs * info.priceChilds) + (info.infants * info.priceInfants) + (info.elders * info.priceElders) + (info.student * info.priceStudent) + ((info.globalPeople || 0) * info.priceGlobalPeople)).toFixed(2).replace('.', ',')}</strong></p>
                    </div>
                </div>
            </Card>
        </>
    );

}

export default DetailsCkeckoutTour;