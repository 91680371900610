// import React, { useEffect, useState } from 'react';
// import Select from 'react-select';
// import api from '../../services/api';

// import Form from 'react-bootstrap/Form';
// import { useAppSelector } from '../../store/hooks';

// export interface propPlus {
//   props: number;
//   actionPickup: any;
//   alert: any;
// }

// const GetPickupsSIG: React.FC<propPlus> = ({
//   props, actionPickup, alert
// }: propPlus) => {
//   const cartDest: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

//   const [info, setInfo] = useState<any>(null);

//   const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

//   const [check, setCheck] = useState<any>(true);

//   const onMenuOpen = () => {
//     if (isMenuOpen === false) {
//       setIsMenuOpen(true);
//     } else {
//       setIsMenuOpen(false);
//     }
//   }

//   useEffect(() => {
//     const token = localStorage.getItem("GroupId") || "{}";
//     const config = {
//       headers: { Authorization: `Bearer ${token}` },
//     };
//     async function listPickups() {
//       try {
//         const { data } = await api.get(`/api/Products/ListPickupLocationSigXSig?ProductCode=${cartDest.dados[0].productCode}&ProductModCode=${cartDest.dados[0].productModCode}`, config);
//         if (data.status !== 400) {
//           setInfo(data.data.data);
//         }
//       } catch (error: any) {
//         if (error.response.status === 401) {
//           window.location.href = window.location.origin + "/";
//         }
//       }
//     }
//     // listPickups();
//   }, []);

//   const handleChange = (options: any) => {
//     actionPickup(options, 0, true);
//     setCheck(false)
//   };

//   if (info !== null) {
//     return (
//       <>
//         <h6>Local de Embarque</h6>
//         {
//           cartDest.dados[index].pickupInfo === undefined
//             ?
//             <>
//               <Select
//                 aria-labelledby="aria-label"
//                 inputId="aria-example-input"
//                 name="aria-live-color"
//                 onMenuOpen={onMenuOpen}
//                 onMenuClose={onMenuOpen}
//                 onChange={handleChange}
//                 placeholder={"Selecione"}
//                 options={Array.from(info)}
//                 getOptionLabel={(option: any) => `${option.nomePickup}: ${option.address}`}
//               />
//               {
//                 check === true
//                   ?
//                   alert
//                   :
//                   ""
//               }
//             </>
//             :
//             <Select
//               aria-labelledby="aria-label"
//               inputId="aria-example-input"
//               className="endpoint"
//               name="aria-live-color"
//               placeholder="Selecione"
//               onMenuOpen={onMenuOpen}
//               onMenuClose={onMenuOpen}
//               onChange={handleChange}
//               options={Array.from(info)}
//               getOptionLabel={(option: any) => `${option.nomePickup}: ${option.address}`}
//               defaultValue={{
//                 "idPickupLocation": cartDest.dados[index].pickup,
//                 "nomePickup": `${cartDest.dados[index].pickupInfo.nomePickup}: ${cartDest.dados[index].pickupInfo.address}`
//               }}
//             />
//         }
//       </>
//     );
//   } else {
//     return (
//       <>
//         <h6>Local de Embarque</h6>
//         <Form.Select>
//           <option value='' disabled></option>
//         </Form.Select>
//       </>
//     )

//   }
// };

// export default GetPickupsSIG;

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  props: any;
  actionPickup: any;
  alert: any;
}

const GetPickupsSIG: React.FC<propPlus> = ({
  props, actionPickup, alert
}: propPlus) => {

  const [info, setInfo] = useState<any>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const [check, setCheck] = useState<any>(true);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { Authorization: `Bearer ${token}`  },
    };
    async function listPickups() {
      try {
        const { data } = await api.get(`/Products/ListPickupLocationSigXSig?ProductCode=${props?.productCode}&ProductModCode=${props?.productModCode}`, config);
        if (data.status !== 400) {
          setInfo(data.data.data);          
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + "/";
        }
      }
    }
    listPickups();
  }, [props?.productCode, props?.productModCode /*  */]);

  const handleChange = (options: any) => {
    actionPickup(options);
    setCheck(false)
  };

  if (info !== null) {
    return (
      <>
        <Select
          aria-labelledby="aria-label"
          inputId="aria-example-input"
          className="endpoint"
          name="aria-live-color"
          placeholder="Selecione"
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuOpen}
          onChange={handleChange}
          options={Array.from(info)}
          getOptionLabel={(option: any) => `${option.nomePickup}: ${option.address}`}
          
        />
        {
          check === true
            ?
            alert
            :
            ""
        }
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
};

export default GetPickupsSIG;

