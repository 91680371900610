import React from 'react';

export interface propEdit {
    setChangeEdit: any,
    info: any,
};

const EditAdditionalInfo: React.FC<propEdit> = ({
    setChangeEdit, info
}: propEdit) => {

    return (
        <>
            <div className="border" id="editAdditionalInfo">
                <div style={{ backgroundColor: "#fff", paddingBottom: "60px" }}>
                    <div className="edit-title container-fluid">
                        <h3 className="">Informações adicionais</h3>
                        <button onClick={() => { setChangeEdit(false) }}>EDITAR</button>
                    </div>
                    <div className="data">
                        <div className="data-unit col-50">
                            <p>POSSUI SISTEMA DE RESERVA?</p>
                            <p>{info.reservationSystem !== null
                                ? parseInt(info.reservationSystem) === 1 ? "Sim"
                                    : parseInt(info.reservationSystem) === 2 ? "Não"
                                        : parseInt(info.reservationSystem) === 3 ? "Não sei"
                                            : "não definido" : 'não definido'}</p>
                        </div>
                        <div className="data-unit blank col-50"></div>
                        <div className="data-unit col-50">
                            <p>SISTEMA DE RESERVA:</p>
                            <p>{info.reservationSystems !== null ? info.reservationSystems : "não definido"}</p>
                        </div>

                        <div className="d-flex w-100">
                            <div className="w-50">
                                <div className="data-unit col-100" style={{ maxWidth: "90%" }}>
                                    <p>ENDPOINT FORNECEDOR(URL):</p>
                                    <p>{info.integrationUrl !== null ? info.integrationUrl : "não definido"}</p>
                                </div>
                                <div className="data-unit col-100" style={{ maxWidth: "90%" }}>
                                    <p>INTEGRATION API KEY (CASO MULTICLUBES):</p>
                                    <p>{info.integrationApiKey !== null ? info.integrationApiKey : "não definido"}</p>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </>
    );
}

export default EditAdditionalInfo;