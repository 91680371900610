import React, { useEffect, useState } from "react";
import api from "../../services/api";
import configData from "../../config/config.json";

import DatePicker from "react-multi-date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-multi-date-picker/styles/layouts/mobile.css"

import "./Calendar.scss";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
];

const numberMonths = window.innerWidth < 768 ? 1 : 2;

export interface productInfo {
    modalityID: any;
    product: any;
    productCode: any;
    changePriceDateTour: any;
    index: any;
    dateTour: any;
    startTimeLocal?: any;
    oneMonth?: any;
}

const CustomMultipleInput = ({ openCalendar, value, modalityID, valueCalendar, startTimeLocal, setNewGetDate }: any) => {
    const { t } = useTranslation();

    if (startTimeLocal) {
        if (valueCalendar.data === undefined) {
            var teste: any = document.getElementById(`date-${modalityID}`);
            if (teste !== null) {
                value = startTimeLocal.props.children;
            }
        }
    }

    function getArrowLimit() {
        var today: any = new Date();
        var monthControl = (today.getMonth() + 1);
        //var actual: any = monthControl;

        let timer1 = setTimeout(() => {

            var teste: any = document.getElementsByClassName('rmdp-arrow-container');


            if (teste.length >= 1) {

                teste[0].addEventListener("click", (e: any) => {
                    monthControl = monthControl - 1;
                }, false)

                teste[1].addEventListener("click", (e: any) => {
                    monthControl = monthControl + 1;
                    setNewGetDate(monthControl);

                }, false)
            }

        }, 500);

        return () => {
            clearTimeout(timer1);
        };
    }

    return (
        <div className="d-flex">
            <FontAwesomeIcon
                icon={["fal", "calendar-alt"]}
                size="1x"
                style={{ color: "#000", position: "absolute", transform: "translate(10px, 10px)" }}
            />
            <input
                className={`form-control rounded-0 bg-white py-2 rounded calendar-input date-${modalityID}`}
                onFocus={() => { openCalendar(); getArrowLimit(); }}
                value={value}
                data-object={JSON.stringify(valueCalendar)}
                readOnly
                id={`date-${modalityID}`}
                placeholder={t("sales.tourList.accordion.when")}
                autoComplete='off'
            />
        </div>
    );
};

const DoubleCalendar: React.FC<productInfo> = ({
    modalityID,
    product,
    productCode,
    changePriceDateTour,
    dateTour,
    index,
    startTimeLocal = undefined,
    oneMonth = false
}: productInfo) => {
    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : "BR";

    const [dayProduct, setDayProduct] = React.useState<any>({});
    const [valueCalendar, setValueCalendar] = useState<any>({});
    const [newGetDate, setNewGetDate] = React.useState<any>(null);

    const [width, setWidth] = useState<any>(window.innerWidth);
    const [auxTarif, setAuxTarif] = useState<any>(null);

    useEffect(() => {
        window.addEventListener("resize", function () {
            var newWidth = window.innerWidth;
            setWidth(newWidth);
        });
    }, []);

    function handleOk() {
        setTimeout(() => {
            let buttonOK: any = document.querySelector('.rmdp-action-buttons .rmdp-action-button:nth-child(1)');
            buttonOK?.click();
        }, 100);
    }

    function handleDateTour(objCalendar: any) {
        let auxTar: any = [];

        for (let i = 0; i < auxTarif?.length; i++) {
            if (auxTarif[i]?.selectedDate.split("T")[0] === objCalendar?.data) {
                auxTar.push(auxTarif[i])
            }
        }

        var aux: any = dateTour;
        aux[index].data = objCalendar?.data;
        aux[index].idSellingType = objCalendar?.idSellingType;
        aux[index].daysUniqueTarCode = objCalendar?.daysUniqueTarCode;
        aux[index].prodModUniqueCode = objCalendar?.prodModUniqueCode;
        aux[index].tarUniqueCode = objCalendar?.tarUniqueCode;
        aux[index].auxTarif = auxTar;
        aux[index].isFreeSale = objCalendar?.isFreeSale;

        if (objCalendar?.isFreeSale === 1) {
            aux[index].adult = objCalendar.priceAdultFinal;
            aux[index].child = objCalendar.priceChildFinal;
            aux[index].infant = objCalendar.priceInfantFinal;
            aux[index].student = objCalendar.priceStudentFinal;
            aux[index].elders = objCalendar.priceEldersFinal;
            aux[index].global = objCalendar.priceGlobalFinal;
        }

        changePriceDateTour(aux);
    }

    useEffect(() => {
        let aux: any = [];

        async function getDays(info: any) {
            var today: any;
            var todayDay: any;
            var todayMonth: any;
            var todayYear: any;
            var endMonth: any;
            var endYear: any;

            if (newGetDate === null) {
                today = new Date();
                todayDay = today.getDate();
                todayMonth = (today.getMonth() + 1);
                todayYear = today.getFullYear();
                endMonth = (today.getMonth() + 5);
                endYear = today.getFullYear();

                if (endMonth > 11) {
                    endMonth = endMonth - 11;
                    endYear = parseInt(endYear) + 1;
                }
            } else {
                today = new Date();
                todayDay = today.getDate();
                todayMonth = newGetDate - 1;
                todayYear = today.getFullYear();
                endMonth = newGetDate + 5;
                endYear = today.getFullYear();

                if (todayMonth > 12) {
                    todayMonth = todayMonth - 12;
                    todayYear = parseInt(todayYear) + 1;
                }
                if (endMonth > 12) {
                    endMonth = endMonth - 12;
                    endYear = parseInt(endYear) + 1;
                }
            }
            // console.log(todayDay)

            todayDay = todayDay >= 28 ? 28 : todayDay;

            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };

            // console.log(todayYear)
            // console.log(todayMonth)
            // console.log(todayDay)

            try {
                const { data } = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/Products/GetCalendarTourAuthSigAsync  `,
                    {
                        dateStart: /* startTimeLocal !== undefined ? startTimeLocal.props.children.split("/").reverse().join("-") : */ todayYear + '-' + todayMonth + '-' + todayDay,
                        dateEnd: endYear + '-' + endMonth + '-' + todayDay,
                        productCode: `${productCode}`,
                        tarCode: `${info.tarCode}`,
                        ProdModCode: `${product.prodModCode}`,
                        lang: lng,
                        idCanal: localStorage.getItem('idCanal') || 1
                    }, config
                );
                if (data.status !== 400) {
                    aux.push(...data.data.dates);

                    data.data.dates.forEach((dayProduct: any) => {
                        let year = dayProduct.selectedDate.split("-")[0];
                        let month = dayProduct.selectedDate.split("-")[1];
                        let day = dayProduct.selectedDate.split("-")[2];

                        let controlObject: { [x: number]: { price: any; data: any, idTarif: any, priceAdultFinal: any, priceChildFinal: any, priceGlobalFinal: any, priceInfantFinal: any, idSellingType: any, daysUniqueTarCode: any, prodModUniqueCode: any, tarUniqueCode: any, isFreeSale: any } };
                        let sControl: any = year + "-" + month + "-" + day.split("T")[0];
                        controlObject = {
                            [sControl]: {
                                price:
                                    dayProduct.priceAdultFinal === 0.0
                                        ? dayProduct.priceGlobalFinal
                                        : dayProduct.priceAdultFinal,
                                idTarif: dayProduct.idTarifario,
                                priceAdultFinal: dayProduct.priceAdultFinal,
                                priceChildFinal: dayProduct.priceChildFinal,
                                priceInfantFinal: dayProduct.priceInfantFinal,
                                priceGlobalFinal: dayProduct.priceGlobalFinal,
                                idSellingType: dayProduct.idSellingType,
                                daysUniqueTarCode: dayProduct.daysUniqueTarCode,
                                prodModUniqueCode: dayProduct.prodModUniqueCode,
                                tarUniqueCode: dayProduct.tarUniqueCode,
                                data: year + "-" + month + "-" + day.split("T")[0],
                                isFreeSale: dayProduct.isFreeSale
                            },
                        };
                        setDayProduct((curObjDeal: any) => ({
                            ...curObjDeal,
                            ...controlObject,
                        }));

                        setAuxTarif(aux);
                    });
                }
            } catch (error: any) { }
        }

        for (let i = 0; i < product.allTarif.length; i++) {
            getDays(product.allTarif[i]);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, productCode, newGetDate]);

    useEffect(() => {
        const dateChoose: any = startTimeLocal.props.children.split("/").reverse().join("-");
        
        handleDateTour(dayProduct[dateChoose])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dayProduct])
    if (dayProduct !== null) {
        return (
            <DatePicker
                weekDays={weekDays}
                numberOfMonths={numberMonths}
                disableMonthPicker
                disableYearPicker
                    
                format={configData.DATE.PT}
                render={<CustomMultipleInput modalityID={modalityID} valueCalendar={valueCalendar} startTimeLocal={startTimeLocal} setNewGetDate={setNewGetDate} />}
                className={width > 767 ? oneMonth !== true ? "multi-locale-days" : "rmdp-mobile" : "rmdp-mobile"}
                mapDays={({ date }): any => {
                    let controlNumber =
                        JSON.stringify(date.day).length === 1 ? "0" + date.day : date.day;
                    let controlMonth =
                        JSON.stringify(date.month.number).length === 1
                            ? "0" + date.month.number
                            : date.month.number;
                    let controlYear = date.year;

                    const objCalendar = dayProduct[controlYear + "-" + controlMonth + "-" + controlNumber];


                    if (objCalendar !== undefined && objCalendar.price !== "null") {
                        if (
                            controlYear + "-" + controlMonth + "-" + controlNumber ===
                            objCalendar.data
                        ) {
                            return {
                                children: (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            fontSize: "14px",
                                            height: "100%",
                                            justifyContent: "center"
                                        }}
                                        onClick={() => { setValueCalendar(objCalendar); handleDateTour(objCalendar); handleOk() }}
                                    >
                                        <div style={{ textAlign: "center" }}>
                                            {date.format("D")}
                                        </div>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                fontSize: "9px",
                                                minHeight: "14px",
                                            }}
                                        >
                                            {" "}
                                            {objCalendar !== undefined
                                                ? objCalendar.price !== "null"
                                                    ? `R$ ${objCalendar.price
                                                        .toFixed(2)
                                                        .replace(".", ",")}`
                                                    : ""
                                                : ""}
                                        </div>
                                    </div>
                                ),
                            };
                        } else {
                            return {
                                disabled: true,
                            };
                        }
                    } else {
                        return {
                            disabled: true,
                        };
                    }
                }}
            ></DatePicker>
        );
    } else {
        return <></>;
    }
};

export default DoubleCalendar;
