import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';
import Select from 'react-select';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  pickupId: any,
  setPickup: any,
  pickup?: any
};

const GetPickupLocation: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, pickupId, setPickup, pickup
}: propPlus) => {
  const [info, setInfo] = useState<any>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function productsListCod() {
      try {
        const { data } = await api.get(`/Decolar/GetPickupLocationsAsync/${pickupId}`, config);
        if (data.status !== 400) {
          setInfo(data.data)
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    productsListCod();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChangeMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if(setPickup){
      setPickup(newValue);
    }
    propsField.onChange(e);
  }

  if (info !== null) {
    const transformed = info.map(({ id, description }: any) => ({ value: id, label: description }));
    return (
      <>
        <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error" : "endpoint"}>
            {/* <Form.Control
              as="select"
              {...propsField}
              required
              autoComplete='off'
              className="form-select"
              onChange={handleChangeMethod}
              value={pickup}
              >
              <option value='0'>Selecione</option>
              {info.length > 0 ? info.map((info: any) => (
                <option value={info.id}>{info.description}</option>
                )) : ''}
              </Form.Control> */}
          <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            //aria-invalid={propsErrors?.coastCenterId ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={"Selecione"}
            options={Array.from(transformed)}
            getOptionLabel={(option: any) => `${option.label}`}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )
  }
}

export default GetPickupLocation;