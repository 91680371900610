/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

import Pagination from "../../../../../../../components/Pagination/Pagination";

import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";

//import dataInvoice from './locales/invoice.json';

import './TableInvoice.scss';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface propExp {
    totalRows: any;
    invoiceBills: any;
    invoicePageCount: any;
    setInvoicePageCount: any;
    invoiceRowsPerPage: any; 
    setInvoiceRowsPerPage: any;
    confereChecked: any;
    handleClick: any;
}



const TableInvoice: React.FC<propExp> = ({
    totalRows, invoiceBills, invoicePageCount, setInvoicePageCount, invoiceRowsPerPage,
    setInvoiceRowsPerPage, confereChecked, handleClick
}: propExp) => {
    
    const {
        control,
    } = useForm();

    function addStatus(cell: any, row: any) {
        return (
            <div
                className={
                    cell === "Conciliado"
                        ? "bg-analise w-100"
                        : cell === "Faturado"
                            ? "bg-analise w-100"
                            : cell === "Pago"
                                ? "bg-ativo w-100"
                                : cell === "Pendente"
                                    ? "bg-inativo w-100"
                                    : cell === "Recebido"
                                        ? "bg-ativo w-100"
                                        : ""
                }
            >
                {cell}
            </div>
        );
    }

    function convertValue(cell: any) {
        console.log(cell)
        return (
            <>
                <span>R$ {cell},00</span>
            </>
        )
    }

    function formatValue(cell: any, row: any) {
        if(cell) {
          return (
            <>
              <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")} {row.sufixovalor}</span>
            </>
          )  
        } else {
          return (
            <>
              <span className=""></span>
            </>
          )
        }
      }

    function convertDate(cell: any, row: any) {
        if (cell !== null) {
            //var date = convertUTCDateToLocalDate(new Date(cell));
            var aux: any = cell.split("T")[0].split("-");
            var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
            return (
                <>
                    {date}
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }

    function addDescricao(cell: any, row: any) {
        return (
            <>
                <OverlayTrigger
                    overlay={
                        <Tooltip id="tooltip">{cell}</Tooltip>
                    }>
                    <span className="text-wrap-custom">
                        {cell}
                    </span>
                </OverlayTrigger>
            </>

        )
    }

    function addDate(cell: any, row: any) {
        const dataop = convertDate(row.dataOperacao, row);

        if(row.datasOperacao && row.datasOperacao.includes(',')) {
            return (
                <OverlayTrigger
                    overlay={
                        <Tooltip id="tooltip">{row.datasOperacao}</Tooltip>
                    }>
                    <span className="">
                        {convertDate(cell, row)}
                    </span>
                </OverlayTrigger>
            )
        } else {
            return (
                <>{convertDate(cell, row)}</>
            )
        }
        
    }

    const rowClasses = (row: any, rowIndex: any) => {
        /* console.log(row, rowIndex) */
        if (row.statusPagamento === "Conciliado" || row.statusPagamento === "Faturado") {
            return 'border-analise';
        } else if (row.statusPagamento === "Recebido") {
            return 'border-ativo';
        } else {
            return 'border-inativo';
        }
    };

    const columns = [
        {
            dataField: 'localizador',
            text: ``,
            formatter: addCheckButton
        },
        {
            dataField: "dataOperacao",
            text: "Data Operação",
            formatter: addDate,
        },
        {
            dataField: "tour",
            text: "Tour/Descrição",
            sort: true,
            formatter: addDescricao
        },
        {
            dataField: "cliente",
            text: "Cliente",
            sort: true,
        },
        {
            dataField: "valor",
            text: "Valor",
            formatter: formatValue,
        },
        {
            dataField: "statusPagamento",
            text: "Status",
            formatter: addStatus,
        },
    ];

    function addCheckButton(cell: any, row: any) {
        return (
            <>
                <div className="center-table">
                    <Controller
                        control={control}
                        name={'' + cell}
                        render={({ field }: any) => (
                            <Form.Check
                                {...field}
                                fullWidth
                                variant="standard"
                                margin="normal"
                                type="checkbox"
                                defaultChecked={confereChecked(cell)}
                                value={cell}
                                onClick={() => { handleClick(cell) }}
                            />
                        )}
                    />
                </div>
            </>
        )
    }

    return (
        <>
            <div className="table-invoice filter d-flex w-100 justify-content-center mt-4">
                <div className="table-container">
                    <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={invoiceBills}
                        columns={columns}
                        rowClasses={rowClasses}
                        //striped={true}
                    />
                    <Pagination
                        totalRows={totalRows}
                        pageCount={invoicePageCount}
                        setPageCount={setInvoicePageCount}
                        rowsPerPage={invoiceRowsPerPage}
                        setRowsPerPage={setInvoiceRowsPerPage}
                        selectNumberRows="yes"
                    />
                </div>
            </div>
        </>
    )
}

export default TableInvoice;