/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import api from '../../../../services/api';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Modal } from "react-bootstrap";

import { useTranslation } from 'react-i18next';
import { Controller, useForm } from "react-hook-form";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

//STYLES
import "./TableAccountsExtract.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
import InsertReceivable from "../InsertReceivable/InsertReceivable";
import InsertPayable from "../InsertPayable/InsertPayable";
import InsertAccountTransfer from "../InsertAccountTransfer/InsertAccountTransfer";
import EditExtract from "../EditExtract/EditExtract";
import ViewExtract from "../ViewExtract/ViewExtract";

export interface propTable {
  billsPay: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  exportBills: any;
  printBills: any;
  loading: any;
  
  selectedCheck: any;
  setSelectedCheck: any;
  itemsToSettle: any;
  setItemsToSettle: any;
  refreshData?: any;
}

const TableAccountsExtract: React.FC<propTable> = ({
  billsPay, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportBills, printBills, loading, refreshData,
  selectedCheck, setSelectedCheck, itemsToSettle, setItemsToSettle
}: propTable) => {
  /* VARIÁVEIS */
  const { t } = useTranslation();

  const [selectedSingleRow, setSelectedSingleRow] = useState<any>();
  const [modalShow, setModalShow] = useState<any>(false);
  const [modalResponse, setModalResponse] = useState<any>(null);
  const [datapgto, setDatapgto] = useState<any>(undefined);
  const [allowReload, setAllowReload] = useState<any>(false);

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  //const [selectedCheck, setSelectedCheck] = useState<any>([]);
  //const [itemsToSettle, setItemsToSettle] = useState<any>([]);

  const [deleteExtractModalShow, setDeleteExtractModalShow] = useState<any>(false);
  const [viewExtractModal, setViewExtractModal] = useState<any>(false);

  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;

  const level = atob(JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil.nivel)

  const validated = false;


  /* FUNÇÕES */
  function refreshDataWrap() {
    setItemsToSettle([]);
    setSelectedCheck([]);
    refreshData();
  }


  function convertValue(cell: any, row: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        {cell !== 0 ?
          (row.pago === 1 ?
          <span className={row.valorPago > 0 ? "negative" : "positive"}>{cell.toFixed(2).replace(".", ",")} <FontAwesomeIcon size="sm" icon={["fal", "check"]} color="navy" /></span>
          :<span className={"not-paid"}>{cell.toFixed(2).replace(".", ",")} </span>)
        : <></>}
      </>
    )
  }

  function convertValueSaldo(cell: any, row: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : "positive"}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  /*converte a data UTC para a data local do usuário*/
  /* function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  } */

  function convertDate(cell: any, row: any) {
    if (cell !== null) {
      //var date = convertUTCDateToLocalDate(new Date(cell));
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
      return (
        <>
          {date}
        </>
      );
    } else {
      return (
        <>
        </>
      );
    }
  }

  /* SOBRE A TABELA */

  function addActionButton(cell: any, row: any) {
    if(row.supMerchant !== 3) {
      return (
        <>
          <div className="d-flex justify-content-center">
            <button className="btn btn-default p-0 m-0" style={{width:"20px"}} onClick={(e:any) => viewExtractDetails(cell)}><FontAwesomeIcon
              size="sm"
              icon={["fal", "eye"]}
              style={{ color: "#707070", margin: "0px", padding: "0px"}}
            /></button>
          </div>
        </>
      );
    } else {
      return (<div style={{width:"20px"}}></div>);
    }
  }

  function viewExtractDetails(id: any) {
    setSelectedSingleRow(id);
    setViewExtractModal(true);
  }

  function addCheckButton(cell: any, row: any) {
    // eslint-disable-next-line eqeqeq
    if(row.admOperacao == 2) {
      return (
        <>
          <div className="center-table">
            <Controller
              control={control}
              name={'' + cell}
              render={({ field }: any) => (
                <Form.Check
                  {...field}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  type="checkbox"
                  defaultChecked={confereChecked(cell)}
                  value={cell}
                  onClick={() => { handleClick(cell) }}
                />
              )}
            />
          </div>
        </>
      )
    } else {
      return (<div style={{width:"20px"}}></div>);
    }
  }

  function handleClick(cell: any) {
    const getBills = billsPay.find((element: { id: number; }) => element.id === parseInt(cell));
    setSelectedCheck((prevState: any) => [...prevState, getBills]);
  }

  useEffect(() => {
    if (selectedCheck.length > 1) {
      var lastOne: any = selectedCheck[selectedCheck.length - 1];
      var aux: any = [];
      var add: boolean = true;

      for (var i: any = 0; i < itemsToSettle.length; i++) {
        if (itemsToSettle[i].id !== lastOne.id) {
          aux.push(itemsToSettle[i]);
        } else {
          add = false;
        }
      }

      if (add === true) {
        setItemsToSettle((prevState: any) => [...prevState, lastOne]);
      } else {
        setItemsToSettle(aux);
      }

    } else if (selectedCheck.length === 1) {
      setItemsToSettle(selectedCheck);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCheck])

  function confereChecked(cell: any) {
    for (var i: any = 0; i < itemsToSettle.length; i++) {
      if (itemsToSettle[i].id === cell) {
        return true;
      }
    }
    return false;
  }

  function deleteExtract(evt: any) {
    if(itemsToSettle.length === 0) {
      setResponseText("Selecione ao menos um item para excluir");
      setModalResponse('warning');
      setLog(2);
      setModalSuccess(true);
    } else {
      setDeleteExtractModalShow(true);
    }
  }

  function handleActionDeleteExtractModal() {
    setDeleteExtractModalShow(false);

    const items: any = [];

    for(var i = 0; i < itemsToSettle.length; i++) {
      items.push(itemsToSettle[i].id);
    }
    
    setModalSuccess(true);
    setModalContent("loading");
    //if (verifyDate(data.data, data.vencimento)) {
    const doDeleteExtract = async () => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const res = await api.post(`/Extract/DeleteExtractInBath`,
                {
                    "items": items,
                    
                    "uid": uId,
                    "usuario": `${userName} ${userLastName}`
                }, config);
            if (res.status !== 400) {
                setResponseText(res.data.data.texto);
                setLog(res.data.data.log);
                setModalContent("success");
                console.log(res)
            } else {
                setResponseText(res.data.data.texto);
                setLog(res.data.data.log);
                setModalContent("error");
            }
            
        } catch (error: any) {
            setModalContent("error");
        }
    };

    doDeleteExtract();
  }

  const columns = [
    {
      dataField: 'id',
      text: ``,
      formatter: addCheckButton
    },
    {
      dataField: "data",
      text: "Data",
      formatter: convertDate,
    },
    {
      dataField: "dataCompetencia",
      text: "Data Competência",
      formatter: convertDate,
    },
    { dataField: "categoria", text: "Categoria" },
    { dataField: "descricao", text: "Descrição" },
    { dataField: "clienteFornecedor", text: "Cliente/Fornecedor" },
    { dataField: "pagoA", text: "Pago A" },
    {
      dataField: "valorRecebido",
      text: "Valor Recebido",
      formatter: convertValue,
    },
    {
      dataField: "valorPago",
      text: "Valor Pago",
      formatter: convertValue,
    },
    {
      dataField: "saldo",
      text: "Saldo",
      formatter: convertValueSaldo,
    },
    { dataField: "id", text: "Ação", formatter: addActionButton },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando Extratos", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "extratos", text: "Extratos" }];

  const notFoundProducts = [{ extratos: "Nenhum extato encontrado" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.pago === 1) {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  /* FORMULÁRIO */

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (modalShow === false) {
      reset({
        data: undefined
      })
    }
  }, [reset, /*  */modalShow])

  

  useEffect(() => {
    if (modalResponse === null && allowReload === true) {
      window.location.reload();
    }
  }, [allowReload, /*  */modalResponse])

  /* RENDER */
  if (billsPay !== null) {
    /* console.log(props.suppliers) */
    return (
      <>
        <div className="table-default extract-table">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  <div>
                    {/* <ModalDefault
                      title="Editar Extrato"
                      name="Editar em Lote"
                      icon="yes"
                      typeIcon="edit"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    > */}
                      <EditExtract 
                        itemsToEdit={itemsToSettle}
                        show={setModalSuccess}
                        setModalContent={setModalContent}
                        setResponseText={setResponseText}
                        setLog={setLog}
                      />
                    {/* </ModalDefault> */}
                  </div>
                  <div>
                    <ModalDefault
                      title="Incluir Recebimento"
                      name="Novo Recebimento"
                      icon="yes"
                      typeIcon="plus-circle"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    >
                      <InsertReceivable
                        show={setModalSuccess}
                        setModalContent={setModalContent}
                        setResponseText={setResponseText}
                        setLog={setLog}
                      />
                    </ModalDefault>
                  </div>
                  <div>
                    <ModalDefault
                      title="Incluir Pagamento"
                      name="Novo Pagamento"
                      icon="yes"
                      typeIcon="plus-circle"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    >
                      <InsertPayable 
                        show={setModalSuccess}
                        setModalContent={setModalContent}
                        setResponseText={setResponseText}
                        setLog={setLog}
                      />
                    </ModalDefault>
                  </div>
                  <div>
                    <ModalDefault
                      title="Nova Trânferência Entre Contas"
                      name="Nova Transferência"
                      icon="yes"
                      typeIcon="plus-circle"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    >
                      <InsertAccountTransfer 
                        show={setModalSuccess}
                        setModalContent={setModalContent}
                        setResponseText={setResponseText}
                        setLog={setLog}
                      />
                    </ModalDefault>
                  </div>
                  <div>
                    {/* <ModalDefault
                      title="Excluir"
                      name="Excluir"
                      icon="yes"
                      typeIcon="trash-alt"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    >
                    </ModalDefault> */}
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={deleteExtract}>
                      <FontAwesomeIcon
                        icon={["fal", "trash-alt"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Excluir
                    </div>
                  </div>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={exportBills}>
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Exportar
                    </div>
                  </div>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={printBills}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Imprimir
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={billsPay}
              columns={columns}
              rowClasses={rowClasses}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
        
        {/* Modal de Success */}
        <Modal
          className={"modal-confirm loading-modal"}
          show={modalSuccess}
          onHide={() => setModalSuccess(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos'>
              {
                modalContent !== "loading"
                  ?
                  <>
                    <div>
                      {
                        log === 2 || log === "2"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "exclamation-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                          :
                          log === 1 || log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              className="text-primary"
                              style={{ fontSize: "7.5em" }}
                            />
                            :
                            log === 0 || log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                      }
                    </div>
                    <div>
                      {responseText}
                    </div>
                    <div className="d-flex justify-content-center">
                      {
                        modalContent === 'success'
                          ?
                          <button
                            onClick={() => {refreshDataWrap(); setModalSuccess(false);}}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                          :
                          modalContent === 'warning'
                            ?
                            <button
                              onClick={() => setModalSuccess(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                            :
                            <button
                              onClick={() => setModalShow(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                      }
                    </div>
                  </>
                  :
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
              }
            </Modal.Body>
          </>
        </Modal>
        {/* END - Modal de Success */}

        {/* modal excluir extrato */}
        <ModalQuestion
            modalShow={deleteExtractModalShow}
            setModalShow={setDeleteExtractModalShow}
            titulo="Excluir Extrato(s)"
            mensagem="Tem Certeza que deseja excluir este(s) item(ns)? Essa ação sera permanente."
            handleActionConfirmModal={handleActionDeleteExtractModal}
            //handleActionCancelModal={handleActionCancelDeleteClick}
          />
        {/* END - modal excluir extrato */}

        {/* modal excluir extrato */}
        <Modal
            className={"modal-custom modalAuth modal-large"}
            show={viewExtractModal}
            onHide={() => {setViewExtractModal(false);}}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header closeButton>
            <div className="d-flex justify-content-between w-100">
              <h3>Detalhes Lançamento</h3>  
            </div>
            </Modal.Header>
            <hr style={{ margin: "30px 0" }} />
            <ViewExtract extractId={selectedSingleRow} />
          </>
        </Modal>
        {/* END - modal excluir extrato */}
      </>
    );
  } else if (billsPay === null && loading === true) {
    return (
      <>
        <div className="table-default extract-table loading not-found">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  <div>
                    <button style={{ cursor: 'pointer' }} className="btn btn-bills" disabled={true}>
                      <FontAwesomeIcon
                        icon={["fal", "edit"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Editar em Lote
                    </button>
                  </div>
                  <div>
                    <button style={{ cursor: 'pointer' }} className="btn btn-bills" disabled={true}>
                      <FontAwesomeIcon
                        icon={["fal", "plus-circle"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Novo Recebimento
                    </button>
                  </div>
                  <div>
                    <button style={{ cursor: 'pointer' }} className="btn btn-bills" disabled={true}>
                      <FontAwesomeIcon
                        icon={["fal", "plus-circle"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Novo Pagamento
                    </button>
                  </div>
                  <div>
                    <button style={{ cursor: 'pointer' }} className="btn btn-bills" disabled={true}>
                      <FontAwesomeIcon
                        icon={["fal", "plus-circle"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Nova Transferência
                    </button>
                  </div>
                  <div>
                    <button style={{ cursor: 'pointer' }} className="btn btn-bills" disabled={true}>
                      <FontAwesomeIcon
                        icon={["fal", "trash-alt"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Excluir
                    </button>
                  </div>
                  <div>
                    <button style={{ cursor: 'pointer' }} className="btn btn-bills" disabled={true}>
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Exportar
                    </button>
                  </div>
                  <div>
                    <button style={{ cursor: 'pointer' }} className="btn btn-bills" disabled={true}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      Imprimir
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default extract-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableAccountsExtract;
