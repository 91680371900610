import React, { useEffect, useState, Key } from 'react';
import api from '../../../services/api';

import DetailsCkeckoutTour from './DetailsCkeckoutTour';
import DetailsCkeckoutTickets from './DetailsCkeckoutTickets';
import DetailsCkeckoutTransfer from './DetailsCkeckoutTransfer';

import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { updateDadosCart } from "../../../store/cart";
import DetailsCkeckoutCombo from './DetailsCkeckoutCombo';

export interface propCard {
    info: any,
};

export interface cartInfo {
    cart: any;
    t?: any;
}
const DetailsCkeckout: React.FC<cartInfo> = ({
    cart, t
}: cartInfo) => {

    const dispatch = useAppDispatch();
    const cartSIG = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));
    const [numberValidCupons, setNumberValidCupons] = useState<any>(0);
    console.log(cart)
    useEffect(() => {
        var dados: any = [...cartSIG.dados];
        let qtd: any = 0;

        if (cartSIG?.cupom?.type === 3) {

            dados.forEach((cItem: any, index: any) => {
                if (cItem.discountBool === true) {
                    qtd = qtd + ((cItem.priceAdults * (cItem.discount / 100)) * parseInt(cItem.adults)) + ((cItem.priceChilds * (cItem.discount / 100)) * parseInt(cItem.childs));
                }
            });
            setNumberValidCupons(qtd);

        }

    }, [cartSIG])

    useEffect(() => {
        if (cartSIG?.cupom?.type === 3) {
            var dados: any = [...cartSIG.dados]

            dados.forEach((cItem: any, index: any) => {
                const validateTourCodeProduct = async () => {
                    try {
                        const { data } = await api.get(`/TourCode/ValidateTourCodeProduct/${cartSIG.cupom.codeName}/${cItem.productCode}`);
                        if (data.statusCode === 200) {
                            cItem.discountBool = true;
                            cItem.discount = data.data.valueDiscount;
                        } else {
                            cItem.discountBool = false;
                            cItem.discount = 0;
                        }

                        if (index === dados.length - 1) {
                            dispatch(updateDadosCart(dados));
                        }

                    } catch (error: any) {
                    }
                }

                validateTourCodeProduct();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="card-title">
                <h5>{t("checkout.detailsCheckout.subtitle")}</h5>
            </div>
            <div className="mt-2">


                {cart.length > 0
                    ? cart.map((info: any, index: Key) => {
                        if (info.isCombo) {
                            return (
                                <div key={index} style={{ marginBottom: "20px" }}>
                                    <DetailsCkeckoutCombo info={info}/>
                                </div>
                            )
                        } else if (info.productType === 1 /* info.typeProduct === 'TOUR' */) {
                            return (
                                <div key={index} style={{ marginBottom: "20px" }}>
                                    <DetailsCkeckoutTour info={info} t={t}/>
                                </div>
                            )
                        } else if (info.productType === 4 /* info.typeProduct === 'TICKET' */) {
                            return (
                                <div key={index} style={{ marginBottom: "20px" }}>
                                    <DetailsCkeckoutTickets info={info} t={t} />
                                </div>
                            )
                        } else if (info.productType === 2 /* info.typeProduct === 'TRANSFER' */) {
                            return (
                                <div key={index} style={{ marginBottom: "20px" }}>
                                    <DetailsCkeckoutTransfer info={info} t={t} />
                                </div>
                            )
                        } else {
                            return null;
                        }
                    })
                    : ''
                }
                <div className="subTotalControl bg-light border">
                    {/* {
                        cartSIG?.cupom?.type === 2
                            ?
                            <>
                                <small className="d-flex space-between align-items-center"> <p className='mb-0'>{cartSIG.cupom.codeName}</p>  <p className='mb-0'>{cartSIG.cupom.value.toFixed(2).split(".").join(",")}</p></small>
                                <small className='text-right'><p className='exclude-cupom' onClick={() => dispatch(removeCupomCart())}>Remover <FontAwesomeIcon icon={["fal", "times"]} size="1x" style={{ marginRight: "5px" }} /></p></small>
                                <div className="d-flex space-between align-items-center"><p>Subtotal</p> <div className="d-flex"><small>R$</small> <p className="priceSubtotal ml-1">{(cartSIG.totalCart).toFixed(2).split(".").join(",")}</p></div></div>
                            </>
                            :
                            cartSIG?.cupom?.type === 3
                                ?
                                <>
                                    {numberValidCupons > 0 ?
                                        <>
                                            <small className="d-flex space-between align-items-center"> <p className='mb-0'>{cartSIG.cupom.codeName}</p>  <p className='mb-0'>{`${cartSIG.cupom.value.toFixed(2).split(".").join(",")} X ${numberValidCupons} cupons válidos`}</p></small>
                                            <small className='text-right'><p className='exclude-cupom' onClick={() => dispatch(removeCupomCart())}>Remover <FontAwesomeIcon icon={["fal", "times"]} size="1x" style={{ marginRight: "5px" }} /></p></small>
                                        </>
                                        :
                                        <></>
                                    }
                                    <div className="d-flex space-between align-items-center"><p>Subtotal</p> <div className="d-flex"><small>R$</small> <p className="priceSubtotal ml-1">{(cartSIG?.totalCart - (cartSIG.cupom.value * numberValidCupons))?.toFixed(2).split(".").join(",")}</p></div></div>
                                </>
                                :
                                cartSIG?.cupom?.type === 4
                                    ?
                                    <>
                                        <small className="d-flex space-between align-items-center"> <p className='mb-0'> {cartSIG.cupom.codeName}</p>  <p className='mb-0'>{cartSIG.cupom.value}%  </p> </small>
                                        <small className='text-right'><p className='exclude-cupom' onClick={() => dispatch(removeCupomCart())}>Remover <FontAwesomeIcon icon={["fal", "times"]} size="1x" style={{ marginRight: "5px" }} /></p></small>
                                        <div className="d-flex space-between align-items-center"><p>Subtotal</p> <div className="d-flex"><small>R$</small> <p className="priceSubtotal ml-1">{(cartSIG.totalCart).toFixed(2).split(".").join(",")}</p></div></div>
                                    </>
                                    :
                                    <div className="d-flex space-between align-items-center"><p>Subtotal</p> <div className="d-flex"><small>R$</small> <p className="priceSubtotal ml-1">{(cartSIG.totalCart)?.toFixed(2).split(".").join(",")}</p></div></div>

                    } */}
                    {cartSIG.cupom?.type === 2 ?
                    <small style={{ display: "flex", justifyContent: "space-between" }}><span>{t("checkout.detailsCheckout.discount")}</span>       <span>R$ {cartSIG.cupom?.value.toFixed(2).split(".").join(",")}</span></small>
                    : ''}

                  {cartSIG.cupom?.type === 3 ?
                    <small style={{ display: "flex", justifyContent: "space-between", paddingRight: '0' }}><span>{t("checkout.detailsCheckout.discount")}</span>       <span>{numberValidCupons === 0 ? 'Nenhum produto válido' : `R$ ${(numberValidCupons).toFixed(2).split(".").join(",")}`}</span></small>
                    : ''}

                  {cartSIG.cupom?.type === 4 ?
                    <small style={{ display: "flex", justifyContent: "space-between" }}><span>{t("checkout.detailsCheckout.discount")}</span>       <span>R$ {cartSIG.cupom?.value + '%'}</span></small>
                    : ''}
                    <div className="d-flex space-between align-items-center"><p>{t("checkout.detailsCheckout.subtotal")}</p> <div className="d-flex"><small>R$</small> <p className="priceSubtotal ml-1">{(cartSIG.totalCart)?.toFixed(2).split(".").join(",")}</p></div></div>

                </div>
            </div>
        </>
    );

}

export default DetailsCkeckout;