import React, { useEffect, useState } from "react";
import api from "../../../../services/api";

import { useForm } from "react-hook-form";

import "../../../../assets/sass/accordion.scss";

import DesktopDefault from "../../../../templates/DesktopDefault";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";
import ResourceCard from "../ResourceCard/ResourceCard";
import { Form, Modal } from "react-bootstrap";

export interface propEdit {
  info?: any;
}

const EditResource: React.FC<propEdit> = ({ info }) => {
  // const [, setFilterVehicleType] = useState<any>(0);
  // const [, setFilterType] = useState<any>(0);

  const [modalShow, setModalShow] = useState<any>(false)
  const [modalLog, setModalLog] = useState<any>(null)
  const [modalMessage, setModalMessage] = useState<any>('Carregando')

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editResource, setEditResource] = useState<any>(null);

  function getQueryStringParam(param: any) {
    var retorno = window.location.href.split(`${param}=`)[1];

    return retorno.split('&')[0];
  }

  const idRes = getQueryStringParam("id");
  const idResType = getQueryStringParam("type");

  useEffect(() => {
    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function getResource() {
      try {
        const { data } = await api.post(
          `Resources/GetResourcesByIdAsync`, {
            resourceId: idRes,
            resourceTypeId: idResType
          }, config
        );

        if (data.status !== 400) {
          setEditResource(data.data.data);
          setValue('payment', data.data.data.paymentType.toString())
        }
      } catch (error: any) {}
    }
    getResource();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idRes]);

  const onSubmit = (obj: any) => {
    setModalShow(true);

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function updateResource() {
      try {
        const { data } = await api.post(
          "Resources/UpdateResourceAsync",
          {
            resourceId: editResource.resourceId,
            resourceName: obj.resourceName || "",
            resourceType: obj.resourceType.value,
            vehicleType: obj.resourceType.vehicle === 1 ? obj.vehicle.value : '0', 
            seats: obj.resourceType.vehicle === 1 ? obj.seats : 0,
            paymentType: obj.payment,
            //supplierGroupId: obj.supplier.id,
            supplierId: obj.supplier.supplierId,
            employeeUserUid: obj.resourceType.people === 1 ? obj.employeeUser.uid : "",
            status: 1,
            costInformation: obj.resourceType.ticket === 1 ? obj.costInformation : 0,
            vehicleIdentification: obj.resourceType.vehicle === 1 ? obj.vehicleIdentification : ""
          },
          config
        );

        if (data.status !== 400) {
          console.log(data.data);
          //setEditResource(data.data.data.rows);
          setModalLog(data.data.log);
          setModalMessage(data.data.texto);
        }
      } catch (error: any) {
        
      }
    }
    updateResource();
  };

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});
  
  const reload = () => {
      if(modalLog === 0){
        window.location.reload();
      }
    
  }

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={"Resources / Editar Resource"} />

          <div className="register-users mt-4">
            <div className="d-flex align-items-center">
              <span
                className="back"
                onClick={() => {
                  window.location.href = "/listar-resources";
                }}
              >
                <FontAwesomeIcon
                  icon={["fal", "angle-double-left"]}
                  style={{ margin: "0 5px 0 0", fontSize: "16px" }}
                />
                Voltar
              </span>
            </div>
            <h2 className="title">Editar Resource</h2>
          </div>
          {editResource !== null ? (
            <Form
              noValidate
              validated={false}
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="false"
            >
              <ResourceCard
                watch={watch}
                control={control}
                isEdit={true}
                infoEdit={editResource}
                errors={errors}
              />
            </Form>
          ) : (
            <></>
          )}
        </div>

        <Modal
              className="modalAuth"
              show={modalShow}
              onHide={() => { reload(); setModalLog(null); setModalMessage('Carregando'); setModalShow(false); }}
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Body className='modal-body text-center sucess-pos d-flex justify-content-evenly' style={{ height: "600px" }}>
                  {modalLog === null
                    ?
                    <>
                    <div className="loading-modal">

                      <div className="load"></div>
                    </div>
                    </>
                    :
                    <div>
                      {modalLog === 0
                        ?
                        <FontAwesomeIcon
                          icon={["fal", "check-circle"]}
                          size="5x"
                          className="text-primary"
                          style={{ fontSize: "7.5em" }}
                        />
                        :
                        <FontAwesomeIcon
                          icon={["fal", "times-circle"]}
                          size="5x"
                          className="text-primary"
                          style={{ fontSize: "7.5em" }}
                        />
                      }
                    </div>
                  }
                  <div>
                    {modalMessage}
                  </div>
                  <div className="d-flex justify-content-center pt-3">
                    <button
                      onClick={() => { reload(); setModalLog(null); setModalMessage('Carregando'); setModalShow(false); }}
                      className="btn btn-primary mx-2 w-25"
                    >
                      Confirmar
                    </button>
                  </div>

                </Modal.Body>
              </>
            </Modal>

      </DesktopDefault>
    </>
  );
};

export default EditResource;
