/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { /* Controller,  */useForm } from "react-hook-form";
/* import { ErrorMessage } from "@hookform/error-message";
import GetSuppliersFilter from "../../../../components/C2Points/GetSuppliersFilter"; */

import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../../services/api";
import './Photo.scss';

import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { addPhoto, ticketAddPhoto } from "../../../store/product";

export interface productTour {
    action: any,
    receiveObject: any,
    tourObject: any,
    isEdit: any;
    isTicket?: boolean;
    editFunction?: any;
};

const Photo: React.FC<productTour> = React.memo(({
    action, receiveObject, tourObject, isEdit, isTicket = false, editFunction = () => { }
}: productTour) => {
    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const tourSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.tour)));
    /* Controle Redux  */

    const [profileImg, setProfileImg] = useState<any>(tourObject.allImages || []);
    const [controlLoop, setControlLoop] = useState<boolean>(false); //para controlar o bug do useeffect que possui "tourSIG" no array de dependência

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalMessage, setModalMessage] = useState<any>('Carregando');
    const [modalLog, setModalLog] = useState<any>(null);

    useEffect(() => {
        if ((tourSIG.tour.productUrl && controlLoop === false) || isEdit) {
            setControlLoop(true);

            let aux: any = [];
            let ordernated: any = [];
            let maxPicOrder: number = Math.max.apply(null, tourSIG?.tour?.productImages.map((elem: any) => elem.picOrder));

            if (isEdit) {
                console.log(tourSIG?.tour?.productImages)
                for (let i: number = 0; i < maxPicOrder; i++) {
                    ordernated.push(tourSIG?.tour?.productImages.find((elem: any) => { return elem.picOrder === i + 1 }))
                }
                
                ordernated = ordernated.filter((elem: any) => elem !== undefined);

                for (let i: number = 0; i < ordernated?.length; i++) {
                    if (isEdit) {
                        aux.push({
                            "id": ordernated[i].id,
                            "picOrder": ordernated[i].picOrder,
                            /* "imageBase64": `${tourSIG.tour.productUrl}/${ordernated[i].imageLarge}`, */
                            "imageName": tourSIG.tour.productUrl + `${isTicket ? '' : '/'}` + ordernated[i][isTicket ? 'mediumImg' : 'imageMedium']
                        })
                    } else {
                        aux.push({
                            "imageBase64": `${tourSIG.tour.productUrl}/${ordernated[i][isTicket ? 'largeImg' : 'imageLarge']}`,
                            "imageName": ordernated[i].imageName
                        })
                    }
                }
                setProfileImg(aux)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getImageSize(url: any, success: any, error: any) {
        var img = new Image();
        img.onload = function () {
            success(img.naturalWidth, img.naturalHeight);
        };
        img.onerror = error;
        img.src = url;
    }

    const imageHandler = (e: any) => {
        let aux: any = [];
        for (let i: number = 0; i < e.target.files.length; i++) {
            if (e.target.files[i].size <= 5242880) {
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        getImageSize(reader.result, function (largura: any, altura: any) {
                            if (largura < 800 || altura < 600) {
                                //alert(`Dimensões da imagem ${i + 1} não pode ser menor que 800x600!`);
                                setModalLog(1);
                                setModalMessage(`Dimensões da imagem ${i + 1} não pode ser menor que 800x600!`);
                                setModalShow(true);
                            } else {
                                aux.push({ "imageBase64": reader.result, "imageName": e.target.files[i].name });
                                if (i === e.target.files.length - 1) {
                                    setProfileImg([...profileImg, ...aux]);
                                }
                            }
                        }, function () {
                            //console.log(`Erro ao carregar a imagem ${i + 1}`);
                        });
                    }
                }
                reader.readAsDataURL(e.target.files[i]);
                //setProfileNameImg(e.target.files[0].name);
            } else {
                //alert(`Tamanho da imagem ${i + 1} não pode ser maior que 5MB!`);
                setModalLog(1);
                setModalMessage(`Tamanho da imagem ${i + 1} não pode ser maior que 5MB!`);
                setModalShow(true);

            }
        }
    }

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        setProfileImg((array: any) => arrayMove(array, oldIndex, newIndex));
    };

    const {
        handleSubmit,
    } = useForm({});

    const onSubmit = (data: any) => {
        data.allImages = profileImg;

        const finishSubmit = (tokens: any, allImages: any) => {
            let productImages: any = [];
            let tokenIndex: number = 0;

            for (let i: number = 0; i < allImages.length; i++) {
                if (allImages[i].imageBase64 !== undefined) {
                    productImages.push({ "tokenImage": tokens[tokenIndex], "picOrder": i + 1 })
                    tokenIndex += 1;
                } else {
                    productImages.push({ ...allImages[i], "picOrder": i + 1 })
                }
            }

            //tokens.map((e: any, index: number) => { "tokenImage": e, "picOrder": index + 1 });
            //tokens.map((e: any, index: number) => productImages.push({ "tokenImage": e, "picOrder": index + 1 }));

            if (isEdit !== true) {
                if (isTicket) {
                    dispatch(ticketAddPhoto(productImages));
                } else {
                    dispatch(addPhoto(productImages));
                }
                action("modalities", data);
            } else {
                editFunction(isTicket ? { "ticketImages": productImages } : { "productImages": productImages }, true)
                
            }
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const convertImageToToken = async () => {

            try {
                const res = await api.post(`File/UploadBase64?switchCaseParam=${isTicket ? '5' : '1'}`,
                    data.allImages.filter((e: any) => e.imageBase64 !== undefined).map((e: any) => e.imageBase64)
                    , config);
                if (res.status !== 400) {
                    if (res.data.data.log === 0) {
                        setModalShow(false);
                        finishSubmit(res.data.data.data, data.allImages);
                    } else {
                        setModalLog(1);
                        setModalMessage(res.data.data.texto);
                    }
                } else {
                    setModalLog(1);
                    setModalMessage('Erro ao processar operação');
                }
            } catch (error: any) {
                setModalLog(1);
                setModalMessage('Erro ao processar operação');
            }
        }

        if (data.allImages.filter((elem: any) => elem.id !== undefined).length === data.allImages.length) {
            finishSubmit(data.allImages, data.allImages)
        } else {
            setModalShow(true);
            convertImageToToken();
        }
    }

    const deletePhoto = (index: number) => {
        let aux: any = [];

        for (let i: number = 0; i < profileImg.length; i++) {
            if (i !== index) {
                aux.push(profileImg[i]);
            }
        }
        setProfileImg(aux);
    }

    useEffect(()=>{
        console.log(profileImg)
    },[profileImg])

    return (
        <>
            {/* CONTENT */}
            {
                (isEdit === false || tourSIG?.tour?.productName) ?
                    <Col md={9} lg={10}>
                        <Card className="pt-content photo">
                            <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                <h3 className="text-primary pt-title mb-0">Fotos</h3>

                                <div className="pt-content-info" style={{ border: "none" }}>
                                    <Row className="pb-5">
                                        <div className="d-flex justify-content-between">
                                            <small>A imagem escolhida como foto principal aparecerá nos resultados de busca</small>
                                            {/* <div className="btn-edit" onClick={() => setEditName(!editName)}>
                                        <FontAwesomeIcon
                                            icon={["fal", "edit"]}
                                            size="1x"
                                            className="mr-2"
                                        />
                                        Editar
                                    </div> */}
                                        </div>
                                        <div>
                                            <div className="bg-image-info mt-3">
                                                <small>
                                                    Resolução mínima <b>800x600</b> | Tamanho máximo <b>5mb</b>
                                                </small>
                                            </div>
                                        </div>

                                        <Form.Group as={Col} md="8" controlId="" className="mt-4">

                                            <label className="d-flex image-upload" htmlFor="input" style={{ gap: "1rem" }}>
                                                <div className="form-control w-75" style={{ height: "36px" }}>
                                                    <p className="mb-0" style={{ opacity: ".8" }}>
                                                        {
                                                            profileImg.length === 0
                                                                ?
                                                                `Selecione arquivos para upload`
                                                                :
                                                                profileImg.length === 1
                                                                    ?
                                                                    `${profileImg.length} arquivo foi selecionado`
                                                                    :
                                                                    `${profileImg.length} arquivos foram selecionados`
                                                        }
                                                    </p>
                                                </div>
                                                <div className="btn btn-image w-auto" style={{ height: "36px", minWidth: "158px" }}>
                                                    Adicionar Imagens
                                                </div>
                                            </label>
                                            <input autoComplete='off' type="file" multiple accept="image/*" name="image-upload" id="input" onChange={imageHandler} />

                                        </Form.Group>
                                    </Row>

                                    <SortableList
                                        onSortEnd={onSortEnd}
                                        className="gap-4"
                                        draggedItemClassName="dragged"
                                    >
                                        {profileImg.map((image: any, index: any) => (
                                            <SortableItem key={index}>
                                                <div className="item-drag">
                                                    <img className="imageGrap" src={image.imageBase64 || image.imageName} alt="nameImage" />
                                                    <span className='btn trash' onClick={(e: any) => { deletePhoto(index) }}>
                                                        <FontAwesomeIcon
                                                            size="lg"
                                                            icon={["fal", "trash-alt"]}
                                                            style={{ color: "#707070" }}
                                                            title="Excluir"
                                                        />
                                                    </span>
                                                </div>

                                            </SortableItem>
                                        ))}
                                    </SortableList>
                                </div>

                                <div className="h-100 d-flex justify-content-end align-items-end">
                                    {
                                        isEdit !== true
                                            ?
                                            <Row className="mx-0 mt-5">
                                                <Button
                                                    variant="outline-primary"
                                                    className="form-button mr-3"
                                                    onClick={() => action('descriptionsAndHigh')}
                                                >
                                                    Voltar
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                >
                                                    Continuar
                                                </Button>
                                            </Row>
                                            :
                                            <Row className=" mx-0 mt-5">
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                >
                                                    Salvar
                                                </Button>
                                            </Row>
                                    }
                                </div>
                            </Form>

                            <Modal
                                className="modalAuth"
                                show={modalShow}
                                onHide={() => {
                                    setModalLog(null);
                                    setModalMessage("Carregando");
                                    setModalShow(false);
                                }}
                                aria-labelledby="contained-modal-title-vcenter"
                            >
                                <>
                                    <Modal.Body
                                        className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                                        style={{ height: "600px" }}
                                    >
                                        {modalLog === null ? (
                                            <>
                                                <div className="loading-modal">
                                                    <div className="load"></div>
                                                </div>
                                            </>
                                        ) : (
                                            <div>
                                                {modalLog === 0 ? (
                                                    <FontAwesomeIcon
                                                        icon={["fal", "check-circle"]}
                                                        size="5x"
                                                        className="text-primary"
                                                        style={{ fontSize: "7.5em" }}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={["fal", "times-circle"]}
                                                        size="5x"
                                                        className="text-primary"
                                                        style={{ fontSize: "7.5em" }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        <div>{modalMessage}</div>
                                        <div className="d-flex justify-content-center pt-3">
                                            <button
                                                onClick={() => {
                                                    setModalLog(null);
                                                    setModalMessage("Carregando");
                                                    setModalShow(false);
                                                }}
                                                className="btn btn-primary mx-2 w-25"
                                            >
                                                Confirmar
                                            </button>
                                        </div>
                                    </Modal.Body>
                                </>
                            </Modal>
                        </Card>
                    </Col >
                    :
                    <>
                        <Col md={9} lg={10}>
                            <Card className="pt-content h-100">
                                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="load mb-4"></div>
                                    <div>Carregando dados do produto</div>
                                </div>
                            </Card>
                        </Col>
                    </>
            }
        </>
    )
});

export default Photo;