import React, { useEffect, useState } from "react";
import api from "../../services/api";
import configData from "../../config/config.json";

import DatePicker from "react-multi-date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-multi-date-picker/styles/layouts/mobile.css"

import "./Calendar.scss";
import i18next from "i18next";

const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
];

const numberMonths = window.innerWidth < 768 ? 1 : 2;

export interface productInfo {
    modalityID: any;
    product: any;
    productCode: any;
    changePriceDateTour: any;
    index: any;
    dateTour: any;
    isTicket?: boolean;
    infoCombo: any;
    setRanges: any;
}

let auxRanges: any = [];

const CustomMultipleInput = ({ openCalendar, value, modalityID, valueCalendar, startTimeLocal, setNewGetDate, infoCombo, index, loading, error }: any) => {
    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : "BR";

    if (startTimeLocal) {
        if (valueCalendar.data === undefined) {
            var teste: any = document.getElementById(`date-${modalityID}`);
            if (teste !== null) {
                value = startTimeLocal.split('-').reverse().join('/');
            }
        }
    }

    function getArrowLimit() {
        var today: any = new Date();
        var monthControl = (today.getMonth() + 1);
        //var actual: any = monthControl;

        let timer1 = setTimeout(() => {

            var teste: any = document.getElementsByClassName('rmdp-arrow-container');


            if (teste.length >= 1) {

                teste[0].addEventListener("click", (e: any) => {
                    monthControl = monthControl - 1;
                }, false)

                teste[1].addEventListener("click", (e: any) => {
                    monthControl = monthControl + 1;
                    setNewGetDate(monthControl);

                }, false)
            }

        }, 500);

        return () => {
            clearTimeout(timer1);
        };
    }

    return (
        <>
            <div className="d-flex">
                {loading ?
                    <>
                        <div style={{ height: "20px", position: 'absolute', margin: '8px 0 0 8px' }}>
                            <div className="load primary"></div>
                        </div>
                        <div style={{ height: '34px' }} className={`form-control rounded-0 bg-white py-2 rounded calendar-input date-${modalityID}`}></div>
                    </>
                    :
                    <>
                        <FontAwesomeIcon
                            icon={["fal", "calendar-alt"]}
                            size="1x"
                            style={{ color: "#000", position: "absolute", transform: "translate(10px, 10px)" }}
                        />
                        <div className="d-flex flex-column w-100">
                            <input
                                style={{ borderColor: error !== null ? 'red' : '' }}
                                className={`form-control rounded-0 bg-white py-2 rounded calendar-input date-${modalityID}`}
                                onFocus={() => { openCalendar(); getArrowLimit(); }}
                                value={value}
                                data-object={JSON.stringify(valueCalendar)}
                                readOnly
                                id={`date-${modalityID}`}
                                autoComplete='off'
                                placeholder={infoCombo[index]?.date === "" ? lng : infoCombo[index]?.date?.split("-").reverse().join("/")}
                            />
                            {error !== null && <><small style={{ color: 'red' }}>{error}</small></>}
                        </div>
                    </>
                }
            </div>
        </>
    );
};

const DoubleCalendarCombo: React.FC<productInfo> = ({
    modalityID,
    product,
    productCode,
    changePriceDateTour,
    dateTour,
    index,
    isTicket = false,
    infoCombo,
    setRanges,
}: productInfo) => {
    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : "BR";
    const [dayProduct, setDayProduct] = React.useState<any>({});
    const [valueCalendar, setValueCalendar] = useState<any>({});
    const [newGetDate, setNewGetDate] = React.useState<any>(null);
    //const [ranges, setRanges] = React.useState<any>({});
    const [, setTariffs] = React.useState<any>({});
    const [loading, setLoading] = useState<any>(true);
    const [error, setError] = useState<string | null>(null);

    const [width, setWidth] = useState<any>(window.innerWidth);

    const [auxTarif, setAuxTarif] = useState<any>(null);

    useEffect(() => {
        window.addEventListener("resize", function () {
            var newWidth = window.innerWidth;
            setWidth(newWidth);
        });
    }, []);

    // função que fecha o calendario em smalls devices forçando o clique no ok
    function handleOk() {
        setTimeout(() => {
            let buttonOK: any = document.querySelector('.rmdp-action-buttons .rmdp-action-button:nth-child(1)');
            buttonOK?.click();
        }, 100);
    }

    function handleDateTour(objCalendar: any) {
        var aux: any = dateTour;

        let auxTar: any = [];


        for (let i = 0; i < auxTarif.length; i++) {
            if (auxTarif[i].selectedDate.split("T")[0] === objCalendar.data) {
                auxTar.push(auxTarif[i])
            }
        }

        var objTemp = {
            data: objCalendar.data,
            idSellingType: /* product?.ranges?.isActivePrivate === true ? 1 : 2 */2,
            daysUniqueTarCode: objCalendar.daysUniqueTarCode,
            prodModUniqueCode: objCalendar.prodModUniqueCode,
            productCode: productCode,
            tarUniqueCode: objCalendar.tarUniqueCode,
            idTarifario: objCalendar.idTarif,
            isFreeSale: objCalendar.isFreeSale,
            auxTarif: auxTar,
            //ranges: ranges,
            adult: objCalendar.isFreeSale === 1 ? objCalendar.priceAdultFinal : undefined,
            child: objCalendar.isFreeSale === 1 ? objCalendar.priceChildFinal : undefined,
            infant: objCalendar.isFreeSale === 1 ? objCalendar.priceInfantFinal : undefined,
            student: objCalendar.isFreeSale === 1 ? objCalendar.priceStudentFinal : undefined,
            elders: objCalendar.isFreeSale === 1 ? objCalendar.priceEldersFinal : undefined,
            global: objCalendar.isFreeSale === 1 ? objCalendar.priceGlobalFinal : undefined,
        }

        aux[index] = objTemp;

        changePriceDateTour(aux, index, true);
        /* setShowHours(true); */
    }

    useEffect(() => {
        let aux: any = [];
        let idChannel: number = 1;

        async function getDays() {
            setError(null);
            setLoading(true);

            var today: any;
            var todayDay: any;
            var todayMonth: any;
            var todayYear: any;
            var endMonth: any;
            var endYear: any;

            if (newGetDate === null) {
                today = new Date();
                todayDay = today.getDate() < 28 ? today.getDate() : 28;
                todayMonth = (today.getMonth() + 1);
                todayYear = today.getFullYear();
                endMonth = (today.getMonth() + 3);
                endYear = today.getFullYear();

                if (endMonth > 11) {
                    endMonth = endMonth - 11;
                    endYear = parseInt(endYear) + 1;
                }
            } else {
                today = new Date();
                todayDay = today.getDate() < 28 ? today.getDate() : 28;
                todayMonth = newGetDate - 1;
                todayYear = today.getFullYear();
                endMonth = newGetDate + 2;
                endYear = today.getFullYear();

                if (todayMonth > 12) {
                    todayMonth = todayMonth - 12;
                    todayYear = parseInt(todayYear) + 1;
                }
                if (endMonth > 12) {
                    endMonth = endMonth - 12;
                    endYear = parseInt(endYear) + 1;
                }
            }

            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };

            try {
                const obj = {
                    dataInicio: todayYear + '-' + todayMonth + '-' + todayDay,
                    dataFim: endYear + '-' + endMonth + '-' + todayDay,
                    productCode: `${product.productCode}`,
                    productType: `${product.productType}`,
                    idCanal: idChannel,
                    comboCode: `${productCode}`,
                    lang: lng,
                }
                const { data } = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/Products/ComboCalendar`, obj, config
                );
                if (data.status !== 400) {
                    aux.push(...data.data.data[0].calendar[0].dates);
                    auxRanges.push({ ...data.data.data[0].calendar[0].ranges, tarCode: product.tarCode });

                    setTariffs(data.data.data[0].calendar[0].tariffs);
                    setLoading(false);

                    data.data.data[0].calendar[0].dates.forEach((dayProduct: any) => {
                        let year = dayProduct.selectedDate.split("-")[0];
                        let month = dayProduct.selectedDate.split("-")[1];
                        let day = dayProduct.selectedDate.split("-")[2];

                        let controlObject: { [x: number]: { price: any; data: any, idTarif: any, priceAdultFinal: any, priceChildFinal: any, priceGlobalFinal: any, priceInfantFinal: any, idSellingType: any, daysUniqueTarCode: any, prodModUniqueCode: any, tarUniqueCode: any } };
                        let sControl: any = year + "-" + month + "-" + day.split("T")[0];
                        controlObject = {
                            [sControl]: {
                                price:
                                    dayProduct.priceAdultFinal === 0.0
                                        ? dayProduct.priceGlobalFinal
                                        : dayProduct.priceAdultFinal,
                                idTarif: dayProduct.idTarifario,
                                priceAdultFinal: dayProduct.priceAdultFinal,
                                priceChildFinal: dayProduct.priceChildFinal,
                                priceInfantFinal: dayProduct.priceInfantFinal,
                                priceGlobalFinal: dayProduct.priceGlobalFinal,
                                idSellingType: dayProduct.idSellingType,
                                daysUniqueTarCode: dayProduct.daysUniqueTarCode,
                                prodModUniqueCode: dayProduct.prodModUniqueCode,
                                tarUniqueCode: dayProduct.tarUniqueCode,
                                data: year + "-" + month + "-" + day.split("T")[0],
                            },
                        };
                        setDayProduct((curObjDeal: any) => ({
                            ...curObjDeal,
                            ...controlObject,
                        }));

                        setRanges(auxRanges);
                        setAuxTarif(aux);
                    });
                } else {
                    setLoading(false);
                    setError(data?.errorMessage)
                }
            } catch (error: any) {
                setLoading(false);
                setError("Erro desconhecido")
            }
        }

        getDays();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, productCode, newGetDate]);

    if (dayProduct !== null) {
        return (
            <DatePicker
                weekDays={weekDays}
                numberOfMonths={numberMonths}
                disableMonthPicker
                disableYearPicker
                months={months}
                format={configData.DATE.PT}
                render={<CustomMultipleInput modalityID={modalityID} valueCalendar={valueCalendar} setNewGetDate={setNewGetDate} startTimeLocal={dateTour?.length > 0 && dateTour[index]?.data !== undefined ? dateTour[index]?.data : undefined} infoCombo={infoCombo} index={index} loading={loading} error={error} />}
                className={width > 767 ? "multi-locale-days" : "rmdp-mobile"}
                mapDays={({ date }): any => {
                    let controlNumber =
                        JSON.stringify(date.day).length === 1 ? "0" + date.day : date.day;
                    let controlMonth =
                        JSON.stringify(date.month.number).length === 1
                            ? "0" + date.month.number
                            : date.month.number;
                    let controlYear = date.year;

                    const objCalendar = dayProduct[controlYear + "-" + controlMonth + "-" + controlNumber];

                    if (objCalendar !== undefined && objCalendar.price !== "null") {
                        if (
                            controlYear + "-" + controlMonth + "-" + controlNumber ===
                            objCalendar.data
                        ) {
                            return {
                                children: (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            fontSize: "14px",
                                            height: "100%",
                                            justifyContent: "center"
                                        }}
                                        onClick={() => { setValueCalendar(objCalendar); handleOk(); handleDateTour(objCalendar); }}
                                    >
                                        <div style={{ textAlign: "center" }}>
                                            {date.format("D")}
                                        </div>
                                        {/* <div
                                            style={{
                                                textAlign: "center",
                                                fontSize: "9px",
                                                minHeight: "14px",
                                            }}
                                        >
                                            {" "}
                                            {objCalendar !== undefined
                                                ? objCalendar.price !== "null"
                                                    ? `R$ ${objCalendar.price
                                                        .toFixed(2)
                                                        .replace(".", ",")}`
                                                    : ""
                                                : ""}
                                        </div> */}
                                    </div>
                                ),
                            };
                        } else {
                            return {
                                disabled: true,
                            };
                        }
                    } else {
                        return {
                            disabled: true,
                        };
                    }
                }}
            ></DatePicker>
        );
    } else {
        return <></>;
    }
};

export default DoubleCalendarCombo;
