import React, { useEffect, useState } from 'react';

import { Container, Row, Col, Card, InputGroup, Modal } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";

import { ErrorMessage } from '@hookform/error-message';


import '../../../../../assets/sass/accordion.scss';
import SingleCalendar from '../../../../../components/Calendar/SingleCalendarWithKeyOption';
//import GetUserRolesIntern from '../../../../../components/C2Points/GetUserRolesIntern';
import api from '../../../../../services/api';
import ImageDefault from "../../../../../assets/img/image-default.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import GetUserRolesInternMulti from '../../../../../components/C2Points/GetUserRolesInternMulti';

export interface propEdit {
    isEdit?: any;
    actionModalUser: any;
    userInfo?: any;
    setIsEdit?: any;
}

const RegisterUsers: React.FC<propEdit> = ({
    isEdit = false, actionModalUser, userInfo, setIsEdit = () => { }
}: propEdit) => {
    const { t } = useTranslation();
    const [profileImg, setProfileImg] = useState<any>(null);
    const [profileNameImg, setProfileNameImg] = useState<any>('None');
    const validated = false;
    const [date, setDate] = useState<any>([]);
    const [supplierArray, setSupplierArray] = useState<object[]>([]);
    const [isForeign, setIsForeign] = useState<boolean>(isEdit === true ? (userInfo.isForeign === 1) : false);
    const [isPromoter, /* setIsPromoter */] = useState<boolean>(isEdit === true && userInfo.promoter !== 2 ? true : false);
    const [modalMessage, setModalMessage] = useState<any>(`${t('editUser.editUser.registerUser.filInTheFields')}`);
    const [modalShow, setModalShow] = useState<any>(false);
    const [modalLog, setModalLog] = useState<any>(null);

    var regex = /^(?=.*[a-z]{1})(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const query = window.location.href;
    const url = new URL(query);
    const segredo = url.searchParams.get("segredo");

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstName: isEdit === true ? userInfo.firstName : '',
            lastName: isEdit === true ? userInfo.lastName : '',
            email: isEdit === true ? userInfo.email : '',
            phone: isEdit === true ? userInfo.phone : '',
            foreign: isEdit === true ? userInfo.isForeign : '',
            cpf: isEdit === true ? userInfo.cpf : '',
            passport: isEdit === true ? userInfo.passport : '',
            level: isEdit === true ? {
                "description": userInfo.userType,
                "id": userInfo.level
            } : '',
            permissionStatus: isEdit === true ? userInfo.statusUser.toString() : '',
            password: isEdit === true ? '' : '',
            confirm: isEdit === true ? '' : '',
            promoter: isEdit === true ? '' : '',
            isDefaultUser: isEdit === true ? '' : '',
            notAcceptExchange: isEdit === true ? '' : '',
            supplierUserPermissionDTO: isEdit === true ? userInfo.supplierUserPermissionDTO : []
        }
    });

    const watchSupplier = watch("supplierUserPermissionDTO", []);
    const watchStatusUser = watch("permissionStatus", "");

    useEffect(()=>{
        var aux:any = [];
        if(watchSupplier?.length> 0){
            watchSupplier?.forEach((elem:any)=>{
                aux.push({
                    "user_role_id": elem.id,
                    "status": watchStatusUser,
                });
            });
        }
        setSupplierArray(aux)
    },[watchSupplier, watchStatusUser])

    useEffect(() => {
        if (isEdit === true) {
            setProfileImg(userInfo?.photoUrl);
        }
    }, [isEdit, /*  */userInfo])

    const imageHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setProfileImg(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setProfileNameImg(e.target.files[0].name);
    }

    function cpfValidator(strCPF: any) {
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF === "00000000000") return false;

        for (var i: any = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }

   

    const onSubmitEdit = (data: any) => {
       
        setModalShow(true);
        setModalMessage(`${t('editUser.editUser.registerUser.loading')}`);

        const idUser: any = window.location.href.split('?id=')[1];
        data.notAcceptExchange = data.notAcceptExchange === true ? 1 : 2;  //1 = senha não troca
        data.foreign = isForeign === true ? 1 : 2;   // 1 = foreign , 2 = brasileiro
        data.promoter = isPromoter === true ? 1 : 2;  // 1 = promoter, 2 = não promoter
        data.birthdayDate = date !== '' ? date.split("/").reverse().join("-") : userInfo.birthdayDate;
        data.photoId = profileImg;

        const updateConta = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/UserPermission/UpdateUserPermissionById`,
                    {
                        "firstName": data.firstName,
                        "lastName": data.lastName,
                        "password": data.password,
                        // "statusUser": data.permissionStatus.toString(),
                        // "level": data.level.id,
                        "foreign": data.foreign,
                        "passport": data.passport,
                        "cpf": data.cpf,
                        "phone": data.phone,
                        "photoUrl": data.photoId, //null pra remover a foto
                        "promoter": data.promoter1,
                        "notAcceptExchange": data.notAcceptExchange1,
                        "birthdayDate": data.birthdayDate,
                        "id": parseInt(idUser),
                        "supplierUserPermissionDTO": supplierArray
                    }, config);
                if (res.status === 200) {
                    setModalMessage(res.data.data.texto);
                    setModalLog(res.data.data.log);
                }
            } catch (error: any) {
                setModalLog(1);
                if (error.response?.status === 401) {
                    setModalMessage(error?.response?.statusText || `${t('editUser.editUser.registerUser.processError')}`);
                } else {
                    setModalMessage(error?.response?.data.errorMessage || `${t('editUser.editUser.registerUser.processError')}`);
                }
            }
        };

        updateConta();
    }

    
    const onSubmit = (data: any) => {
        console.log(profileImg)
        if (isEdit === true) {
            onSubmitEdit(data);
            return;
        }

        setModalShow(true);

        data.cpf = data.cpf.replaceAll('.', '').replaceAll('-', '');
        if (cpfValidator(data.cpf) !== true && isForeign === false) {
            setModalLog(1);
            setModalMessage(`${t('editUser.editUser.registerUser.invalidCpf')}`);

            return;
        }

        if (data.password !== data.confirm) {
            setModalLog(1);
            setModalMessage(`${t('editUser.editUser.registerUser.diferentPasswords')}`);

            return;
        }

        if (!regex.exec(data.password)) {
            setModalLog(1);
            setModalMessage(`${t('editUser.editUser.registerUser.passwordRuleError')}`);

            return;
        }

        setModalMessage(`${t('editUser.editUser.registerUser.loading')}`);

        data.notAcceptExchange = data.notAcceptExchange === true ? 1 : 2;  //1 = senha não troca
        data.foreign = isForeign === true ? 1 : 2;   // 1 = forein , 2 = brasileiro
        data.promoter = isPromoter === true ? 1 : 2;  // 1 = promoter, 2 = não promoter
        data.level = data.level.id;
        data.image = profileNameImg;
        data.groupId = 0;
        data.passport = data.passport === undefined ? "" : data.passport;
        data.birthdayDate = date?.split("/")?.reverse()?.join("-");
        //data.supplierUserPermissionDTO = data.supplierUserPermissionDTO;
        data.photoId = profileImg;

        const insertConta = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/UserPermission/TryInsertUserPermission`,
                    {
                        "firstName": data.firstName,
                        "lastName": data.lastName,
                        "groupId": 0,
                        "email": data.email,
                        "password": data.password,
                        // "permissionStatus": data.permissionStatus,
                        // "level": data.level,
                        "foreign": data.foreign,
                        "passport": data.passport,
                        "cpf": data.cpf,
                        "phone": data.phone,
                        "photoUrl": data.photoId,
                        "promoter": data.promoter,
                        "notAcceptExchange": data.notAcceptExchange,
                        "birthdayDate": data.birthdayDate,
                        "supplierUserPermissionDTO": supplierArray,
                    }, config);
                if (res.status !== 400) {
                    setModalMessage(res.data.data.texto);
                    setModalLog(res.data.data.log);
                    const newUser = [
                        {
                            "id": res.data.data.data.id,
                            "name": res.data.data.data.name,
                            "lastName": null,
                            "email": res.data.data.data.email,
                            "cpf": res.data.data.data.cpf
                        }
                    ]
                    
                    actionModalUser(newUser);
                } else {
                    setModalMessage(res.data.data.texto);
                    setModalLog(res.data.data.log);
                }
            } catch (error: any) {
                setModalLog(1);
                setModalMessage(error?.response?.statusText || `${t('editUser.editUser.registerUser.processError')}`);
            }
        };        

        const getAuthenticateAccountDestinow = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {        
            const resp = await api.post('https://backend.destinow.com.br/api/UserPermission/TryInsertUserPermission', { ///LOGIN API        
                "firstName": data.firstName,
                "lastName": data.lastName,
                "groupId": 0,
                "email": data.email,
                "password": data.password,
                // "permissionStatus": data.permissionStatus,
                // "level": data.level,
                "foreign": data.foreign,
                "passport": data.passport,
                "cpf": data.cpf,
                "phone": data.phone,
                "photoUrl": data.photoId,
                "promoter": data.promoter,
                "notAcceptExchange": data.notAcceptExchange,
                "birthdayDate": data.birthdayDate,
                "supplierUserPermissionDTO": supplierArray
            }, config);      
            if (resp.status !== 400) {
                //console.log(resp)
            } else {
                //console.log(resp)
            }  
           
            } catch (error: any) {   
            }           
           }
           if(segredo === 'bruno' && window.location.hostname === 'sig.c2rio.travel'){
                getAuthenticateAccountDestinow();
           }        

        insertConta();
    }     

    return (
        <>
            <Card className="bg-white mt-4">
                <Accordion >
                    <Card.Body>
                        <Container className="p-0 mt-3" fluid>
                            <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                                <Row className="mb-3">
                                    <div className="label">
                                        <label className="image-upload" htmlFor="input">
                                            <h5 className="text-center">{t('editUser.editUser.registerUser.profilePicture')}</h5>
                                            {profileImg !== null ?
                                                <div className="logo-user mx-3 mb-5 mt-2"
                                                    style={{ backgroundImage: `url(${profileImg})`, backgroundSize: 'cover', cursor: 'pointer' }}>
                                                </div>
                                                :
                                                <div className="logo-user mx-3 mb-5 mt-2"
                                                    style={{ backgroundImage: `url(${ImageDefault})`, backgroundSize: 'cover', cursor: 'pointer' }}>
                                                </div>
                                            }
                                        </label>
                                        <input
                                            autoComplete='off'
                                            type="file"
                                            accept="image/*"
                                            name="photoUrl"
                                            id="input"
                                            onChange={imageHandler} />
                                    </div>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>
                                            {t('editUser.editUser.registerUser.name')}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="firstName"
                                            rules={{ required: { value: true, message: `${t('editUser.editUser.registerUser.nameError')}` } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    autoComplete='off'
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="firstName"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>
                                            {t('editUser.editUser.registerUser.lastName')}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="lastName"
                                            rules={{ required: { value: true, message: `${t('editUser.editUser.registerUser.lastNameError')}` } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    autoComplete='off'
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="lastName"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    {isEdit === false
                                        ?
                                        <>
                                            <Form.Group as={Col} md="4" controlId="">
                                                <Form.Label>
                                                    {t('editUser.editUser.registerUser.email')}
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="email"
                                                    rules={{ required: { value: true, message: `${t('editUser.editUser.registerUser.emailError')}` } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="email"
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="email"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                        </>
                                        :
                                        <>
                                        </>
                                    }
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="formGridDate">
                                        <Form.Label>{t('editUser.editUser.registerUser.birthday')}</Form.Label>
                                        <InputGroup
                                            hasValidation
                                            className=""
                                            size="sm"
                                            placeholder={t('editUser.editUser.registerUser.when')}
                                        >
                                            <SingleCalendar setValue={setDate} startTimeLocal={isEdit === true ? userInfo.birthdayDate : undefined} disableFutureDates={true} />
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>
                                            {t('editUser.editUser.registerUser.telephone')}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="phone"
                                            rules={{ required: { value: true, message: `${t('editUser.editUser.registerUser.telephoneError')}` } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    variant="standard"
                                                    margin="normal"
                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    required
                                                    autoComplete='off'
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="phone"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="2" controlId="">
                                        <div className="d-flex align-items-center pt-4 h-100 justify-content-start">
                                            <Controller
                                                control={control}
                                                name="foreign"
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        onClick={() => { setIsForeign(false) }}
                                                        defaultChecked={isForeign === false}
                                                        type="radio"
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        className="mx-2"
                                                    />
                                                )}
                                            />
                                            <Form.Label className="mb-0">
                                                {t('editUser.editUser.registerUser.brazilian')}
                                            </Form.Label>
                                        </div>
                                    </Form.Group>

                                    <Form.Group as={Col} md="2" controlId="">
                                        <div className="d-flex align-items-center pt-4 h-100 justify-content-start">
                                            <Controller
                                                control={control}
                                                name="foreign"
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        onClick={() => { setIsForeign(true) }}
                                                        defaultChecked={isForeign === true}
                                                        type="radio"
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        className="mx-2"
                                                    />
                                                )}
                                            />
                                            <Form.Label className="mb-0">
                                                {t('editUser.editUser.registerUser.foreigner')}
                                            </Form.Label>
                                        </div>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    {isForeign === false ?
                                        <Form.Group as={Col} md="4" controlId="">
                                            <Form.Label>
                                                {t('editUser.editUser.registerUser.cpf')}
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="cpf"
                                                rules={{ required: { value: true, message: `${t('editUser.editUser.registerUser.cpfError')}` } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="cpf"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>

                                        :

                                        <Form.Group as={Col} md="4" controlId="">
                                            <Form.Label>
                                                {t('editUser.editUser.registerUser.passport')}
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="passport"
                                                rules={{ required: { value: true, message: `${t('editUser.editUser.registerUser.passportError')}` } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="passport"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    }

                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>
                                            {t('editUser.editUser.registerUser.level')}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="supplierUserPermissionDTO"
                                            rules={{ required: { value: true, message: `${t('editUser.editUser.registerUser.levelError')}` } }}
                                            render={({ field }: any) => (
                                                <GetUserRolesInternMulti
                                                    propsField={field}
                                                    propsLabel={"nível"}
                                                    propsErrors={errors}
                                                    defaultSelected={isEdit === true ? userInfo : undefined}
                                                    setValue={setValue} />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="level"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>
                                            {t('editUser.editUser.registerUser.status')}
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="permissionStatus"
                                            rules={{ required: { value: false, message: `${t('editUser.editUser.registerUser.statusError')}` } }}
                                            defaultValue={'2'}
                                            render={({ field }: any) => (
                                                <div className='endpoint'>
                                                    <Form.Select
                                                        {...field}
                                                        as="select"
                                                        variant="standard"
                                                        margin="normal"
                                                    >
                                                        <option value="">{t('editUser.editUser.registerUser.statusSelect.select')}</option>
                                                        <option value="1" >{t('editUser.editUser.registerUser.statusSelect.activeEmployee')}</option>
                                                        <option value="2" selected={true}>{t('editUser.editUser.registerUser.statusSelect.inactiveEmployee')}</option>
                                                    </Form.Select>
                                                </div>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="permissionStatus"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>

                                {isEdit === false
                                    ?
                                    <>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4" controlId="">
                                                <Form.Label>
                                                    {t('editUser.editUser.registerUser.password')}
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="password"
                                                    rules={{ required: { value: !isEdit, message: `${t('editUser.editUser.registerUser.passwordError')}` } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="password"
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="password"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="4" controlId="">
                                                <Form.Label>
                                                    {t('editUser.editUser.registerUser.confirmPassword')}
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="confirm"
                                                    rules={{ required: { value: !isEdit, message: `${t('editUser.editUser.registerUser.confirmPasswordError')}` } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="password"
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="confirm"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                        </Row>
                                    </>
                                    :
                                    <></>
                                }

                                <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                    {isEdit === true
                                        ?
                                        <div className="data" style={{ width: 'unset' }}>
                                            <div className="btn-default mr-2 text-uppercase btn btn-outline-primary" onClick={() => { setIsEdit(false) }}>{t('editUser.editUser.registerUser.cancel')}</div>
                                        </div>
                                        :
                                        <></>
                                    }
                                    {/* <ModalConfirm
                                        confirm={modalMessage}
                                        modalContent={modalResponse}
                                        log={log}
                                        isEdit={isEdit}
                                    /> */}
                                    <div className="d-flex justify-content-end" style={{ width: "unset" }} /* onClick={() => setModalShow(true)} */>
                                        <button className="btn btn-default btn-primary px-4 text-uppercase " type="submit">
                                            {isEdit === true ? `${t('editUser.editUser.registerUser.save')}` : `${t('editUser.editUser.registerUser.register')}`}
                                        </button>
                                    </div>
                                </Row>

                            </Form>

                            <Modal
                                className="modalAuth"
                                show={modalShow}
                                onHide={() => {
                                    setModalLog(null);
                                    setModalMessage(`${t('editUser.editUser.registerUser.loading')}`);
                                    setModalShow(false);
                                }}
                                aria-labelledby="contained-modal-title-vcenter"
                            >
                                <>
                                    <Modal.Body
                                        className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                                        style={{ height: "600px" }}
                                    >
                                        {modalLog === null ? (
                                            <>
                                                <div className="loading-modal">
                                                    <div className="load"></div>
                                                </div>
                                            </>
                                        ) : (
                                            <div>
                                                {modalLog === 0 ? (
                                                    <FontAwesomeIcon
                                                        icon={["fal", "check-circle"]}
                                                        size="5x"
                                                        className="text-primary"
                                                        style={{ fontSize: "7.5em" }}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={["fal", "times-circle"]}
                                                        size="5x"
                                                        className="text-primary"
                                                        style={{ fontSize: "7.5em" }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        <div>{modalMessage}</div>
                                        <div className="d-flex justify-content-center pt-3">
                                            <button
                                                onClick={() => {
                                                    setModalLog(null);
                                                    setModalMessage("Carregando");
                                                    setModalShow(false);
                                                }}
                                                className="btn btn-primary mx-2 w-25"
                                            >
                                                {t('editUser.editUser.registerUser.confirm')}
                                            </button>
                                        </div>
                                    </Modal.Body>
                                </>
                            </Modal>
                        </Container>
                    </Card.Body>
                </Accordion>
            </Card>
        </>
    );
}

export default RegisterUsers;