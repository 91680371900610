import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import { useTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';
import Select from 'react-select';

export interface propPlus {
  propsField: any,
  propsErrors: any,
  isMulti?: any,
};

const GetProductsList: React.FC<propPlus> = ({
  propsField, propsErrors, isMulti = false
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  
    const onMenuOpen = () => {
      if (isMenuOpen === false) {
        setIsMenuOpen(true);
      } else {
        setIsMenuOpen(false);
      }
    }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function productsListCod() {
      try {
        const { data } = await api.get(`/DropDown/GetActiveProductsTourAndTicket`, config);
        if (data.status !== 400) {
          setInfo(data.data)
        }
      } catch (error: any) {
        setTimeout(() => {
          productsListCod();
        }, 5000);
      }
    }
    productsListCod();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (info !== null) {
    const transformed = info.map(({ id, description }: any) => ({ value: id, label: description }));
    return (
      <>
        <div className="endpoint">
          {isMulti === false ?
            <>
            <Select
                {...propsField}
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                name="aria-live-color"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuOpen}
                className="basic-multi-select"
                classNamePrefix="select"
                as="select"
                variant="standard"
                margin="normal"
                placeholder={"Selecione"}
                options={transformed}
                getOptionLabel={(option: any) => `${option.label}`}
              />
              {/* <Form.Control
                as="select"
                {...propsField}
                required
                autoComplete='off'
                className="form-select"
              >
                <option value='0'>{t("salesReport.all")}</option>
                {info.length > 0 ? info.map((info: any) => (
                  <option value={info.code}>{info.name}</option>
                )) : ''}
              </Form.Control> */}
            </>
            :
            <>
              <Select
                {...propsField}
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                name="aria-live-color"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuOpen}
                className="basic-multi-select"
                classNamePrefix="select"
                as="select"
                variant="standard"
                margin="normal"
                placeholder={t("salesReport.select")}
                isMulti
                options={transformed}
                getOptionLabel={(option: any) => `${option.label}`}
              />
            </>}
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )
  }
}

export default GetProductsList;