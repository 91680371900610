import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal, OverlayTrigger, Dropdown, Tooltip, Button } from 'react-bootstrap';

import DetailsReservation from '../DetailsReservation/DetailsReservation';
import Pagination from '../../../../components/Pagination/Pagination';
import Reminder from '../../../../components/Reminder/Reminder';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

//STYLES
import './ReservationsTableResponsive.scss';
import '../../../../assets/sass/accordion.scss';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ReservationsLog from '../ReservationsLog/ReservationsLog';

import GetReasonCancel from "../../../../components/C2Points/GetReasonCancel";
import { Controller, useForm } from "react-hook-form";
import ModalShow from "../../../../components/Modal/ModalShow/ModalShow";

export interface propTable {
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  reservations: any;
  confirm: any;
  log: any;
  cancelReservation: any;
  modalContent: any;
  setConfirm: any;
  setLog: any;
  handleModal: any;
  loading: any;
  exportReservations: any;
  setReason: any;
  valReason: any;
  reloadTable: any;
}

const ReservationsTableResponsive: React.FC<propTable> = ({
  totalRows,
  pageCount,
  setPageCount,
  rowsPerPage,
  setRowsPerPage,
  reservations,
  confirm,
  log,
  cancelReservation,
  modalContent,
  setConfirm,
  setLog,
  handleModal,
  loading,
  exportReservations,
  valReason,
  setReason,
  reloadTable,
}: propTable) => {
  const { t } = useTranslation();
  const [editClick, setEditClick] = useState<any>(false);
  const [detailsModal, setDetailsModal] = useState<any>(false);
  const [logModal, setLogModal] = useState<any>(false);
  const [currentReservation, setCurrentReservation] = useState<any>();
  const [cancelReservationObj, setCancelReservationObj] = useState<any>();
  const [modalErrorShow, setModalErrorShow] = useState<any>(false);
  const [width, setWidth] = useState<any>(window.innerWidth);
  const [reminderModal, setReminderModal] = useState<any>(false);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('Carregando');
  const [modalLog, setModalLog] = useState<any>(null);
  const level:any = atob(JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil.nivel);
  
  
  const {
    control,
    watch,
    setValue,
    //getValues,
    formState: { errors },
  } = useForm();

  const watchReason = watch('reason', false);
  setReason(watchReason);

  function seeDetails(cell: any) {
    setEditClick(false);
    setCurrentReservation(cell);
    setDetailsModal(true);
  }

  function seeLogs(cell: any) {
    setEditClick(false);
    setCurrentReservation(cell);
    setLogModal(true);
  }

  function seeDetailsEdit(cell: any) {
    setEditClick(true);
    setCurrentReservation(cell);
    setDetailsModal(true);
  }

  function seeReminder(cell: any) {
    setCurrentReservation(cell);
    setReminderModal(true);
  }

  function handleModalShow() {
    setValue('reason', '0');
  }

  function getVoucher(idItem: any) {
    // setCancelamentoModal(true);

    const getVou = async () => {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const { data } = await api.post(
          `${process.env.REACT_APP_SERVER_URL_API}/checkout/PdfSecondWay/${idItem}`,
          {},
          config,
        );
        if (data.status !== 400) {
          if (data.data.log === 0 || data.data.log === '0') {
            const link = document.createElement('a');
            link.href = `data:${data.data.data.voucherFile.contentType};base64, ${data.data.data.voucherFile.content}`;
            link.setAttribute('download', data.data.data.voucherFile.fileName);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // // Clean up and remove the link
            link?.parentNode?.removeChild(link);
          } else if (data.data.log === 1 || data.data.log === '1') {
            alert(data.data.data.texto);
          }
        }
      } catch (error: any) { }
    };

    getVou();
  }

  function getReciboEmail(info: any) {
    
    // setCancelamentoModal(true);
    let lng = info.idioma === 'PT' || 'Portugues' ? 'BR' : info.idioma;

    const getRecibo = async () => {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const { data } = await api.post(
          `${process.env.REACT_APP_SERVER_URL_API}/Checkout/EmailTotemSecondWay?IdItem=${info.idNewItems || info.idItem}&language=${lng}`,
          {},
          config,
        );
        if (data.status !== 400) {
          if (data.data.log === 0 || data.data.log === '0') {
            const link = document.createElement('a');
            link.href = `data:${data.data.data.voucherFile.contentType};base64, ${data.data.data.voucherFile.content}`;
            link.setAttribute('download', data.data.data.voucherFile.fileName);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // // Clean up and remove the link
            link?.parentNode?.removeChild(link);
          } else if (data.data.log === 1 || data.data.log === '1') {
            alert(data.data.data.texto);
          }
        }
      } catch (error: any) { }
    };

    getRecibo();
  }

  function addActionButton(cell: any, row: any) {
    if(level === 29 || level === "29" && process.env.REACT_APP_CLIENT_NAME === "Destinow"){
      return (
        <>
          <div className="d-flex justify-content-center">
            <Dropdown drop="start" className="dropdown-custom">
              <Dropdown.Toggle variant="light ">
                <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
              </Dropdown.Toggle>
  
              <Dropdown.Menu>
              <Dropdown.Item
                  eventKey="1"
                  onClick={() => {
                    seeDetails(cell);
                  }}
                >
                  <FontAwesomeIcon
                    icon={['fal', 'file-alt']}
                  //className="mr-2"
                  />
                  <span>{t('reservations.table.dropdown.details')}</span>
                </Dropdown.Item>                            
                <Dropdown.Item
                  eventKey="3"
                  onClick={() => {
                    getVoucher(cell);
                  }}
                >
                  <FontAwesomeIcon
                    icon={['fal', 'download']}
                  //className="mr-2"
                  />
                  <span>{t('reservations.table.voucher')}</span>
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  onClick={() => {
                    getReciboEmail(row);
                  }}
                >
                  <FontAwesomeIcon
                    icon={['fal', 'download']}
                  //className="mr-2"
                  />
                  <span>{t('reservations.table.receipt')}</span>
                </Dropdown.Item>
                
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      );
    }else {
      return (
        <>
          <div className="d-flex justify-content-center">
            <Dropdown drop="start" className="dropdown-custom">
              <Dropdown.Toggle variant="light ">
                <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
              </Dropdown.Toggle>
  
              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => {
                    seeDetails(cell);
                  }}
                >
                  <FontAwesomeIcon
                    icon={['fal', 'file-alt']}
                  //className="mr-2"
                  />
                  <span>{t('reservations.table.dropdown.details')}</span>
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => {
                    seeDetailsEdit(cell);
                  }}
                >
                  <FontAwesomeIcon
                    icon={['fal', 'edit']}
                  //className="mr-2"
                  />
                  <span>{t('reservations.table.dropdown.edit')}</span>
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  onClick={() => {
                    seeLogs(cell);
                  }}
                >
                  <FontAwesomeIcon icon={['fal', 'bell']} />
                  <span>Log</span>
                </Dropdown.Item>             
                <Dropdown.Item
                  eventKey="3"
                  onClick={() => {
                    getVoucher(cell);
                  }}
                >
                  <FontAwesomeIcon
                    icon={['fal', 'download']}
                  //className="mr-2"
                  />
                  <span>{t('reservations.table.voucher')}</span>
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  onClick={() => {
                    getReciboEmail(row);
                  }}
                >
                  <FontAwesomeIcon
                    icon={['fal', 'download']}
                  //className="mr-2"
                  />
                  <span>{t('reservations.table.receipt')}</span>
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  onClick={() => {
                    seeReminder(row);
                  }}
                >
                  <FontAwesomeIcon
                    icon={['fal', 'info-circle']}
                  //className="mr-2"
                  />
                  <span>{t('reservations.table.reminder')}</span>
                </Dropdown.Item>
                {/* <Dropdown.Item eventKey="2" disabled={true} ><FontAwesomeIcon icon={['fal', 'comment-alt-dollar']} className="mr-2" />   {t('reservations.table.dropdown.account')}</Dropdown.Item>
                <Dropdown.Item eventKey="4" disabled={true}><FontAwesomeIcon icon={['fal', 'circle']} className="mr-2" />   {t('reservations.table.dropdown.status')}</Dropdown.Item>
                <Dropdown.Item eventKey="5" disabled={true}><FontAwesomeIcon icon={['fal', 'file-contract']} className="mr-2" />  {t('reservations.table.dropdown.contract')}</Dropdown.Item> */}
                {row.status === 1 ? (
                  <Dropdown.Item
                    eventKey="3"
                    disabled={false}
                    className="text-danger remove"
                    onClick={() => {
                      handleModal(false);
                      setCancelReservationObj(row);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'ban']}
                    //className="mr-2"
                    />
                    <span>{t('reservations.table.dropdown.remove')}</span>
                  </Dropdown.Item>
                ) : (
                  <></>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      );
    }
   
  }

  /*converte a data UTC para a data local do usuário*/
  /* function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any, row: any) {
    var date = convertUTCDateToLocalDate(new Date(cell));
    return (
      <>
        {date}
      </>
    );
  } */

  /* function convertDate(cell: any) {
    if (cell !== null && cell !== undefined) {
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
      return date;
    }
  } */

  function textReduce(cell: any, row: any) {    
    if (cell !== null) {
      var aux = cell.split(' ');
      if (aux.length >= 4) {
        aux = aux[0] + ' ' + aux[1] + ' ' + aux[2] + ' ' + aux[3] + '...';
      } else {
        aux = cell;
      }

      return (
        <>
          <OverlayTrigger overlay={<Tooltip id="tooltip">{cell}</Tooltip>}>
            <span className="text-wrap-custom">{cell}</span>
          </OverlayTrigger>
        </>
      );
    } else {
      return <></>;
    }
  }

  function addStatus(cell: any, row: any) {
    if (cell <= 2) {
      cell = row.status;
    }
    var status =
      cell === 1
        ? `${t('reservations.table.active')}`
        : cell === 2
          ? `${t('reservations.table.canceled')}`
          : cell === 3
            ? `${t('reservations.table.processing')}`
            : cell === 4
              ? `${t('reservations.table.abandonedCart')}`
              : cell === 5
                ? `${t('reservations.table.pendingSupplier')}`
                : cell === 6
                  ? `${t('reservations.table.onHold')}`
                  : cell === 7
                    ? `${t('reservations.table.preBooking')}`
                    : cell === 9
                      ? `${t('reservations.table.unapprovedPayment')}`
                      : '';
    return (
      <div
        className={
          status === 'Substituído'
            ? 'bg-reservation-analise'
            : cell === 1
              ? 'bg-reservation-ativo'
              : cell === 2
                ? 'bg-reservation-inativo'
                : cell === 7
                  ? 'bg-reservation-preReserva'
                  : cell === 9
                    ? 'bg-reservation-naoAprovado'
                    : ''
        }
      >
        {status}
      </div>
    );
  }

  const prevColumns = [
    {
      dataField: 'dataEmissao',
      text: `${t('reservations.table.table01')}`,
      sort: true,
      formatter: (cell: any) => {
        return cell.split('T')[0].split('-').reverse().join('/');
      },
    }, //trocar
    {
      dataField: 'reservaC2',
      text: `${t('reservations.table.table02')}`,
      sort: true,
    },
    {
      dataField: 'startTimeLocal',
      text: `${t('reservations.table.table03')}`,
      sort: true,
      formatter: (cell: any) => {
        return cell.split('T')[0].split('-').reverse().join('/');
      },
    },
    {
      dataField: 'productName',
      text: `${t('reservations.table.table04')}`,
      sort: true,
      formatter: textReduce,
    },
    {
      dataField: 'nomeTodoRevelado',
      text: `${t('reservations.table.table05')}`,
      sort: true,
    },
    {
      dataField: 'quantidadePax',
      text: `${t('reservations.table.table06')}`,
      sort: true,
    },
    !process.env.REACT_APP_LEVEL_AGENTS?.split(',').includes(
      atob(
        JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil
          .nivel,
      ),
    ) && {
      dataField: 'compName',
      text: `${t('reservations.table.table07')}`,
      sort: true,
      formatter: textReduce,
    },
    !process.env.REACT_APP_LEVEL_AGENTS?.split(',').includes(
      atob(
        JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil
          .nivel,
      ),
    ) && {
      dataField: 'source',
      text: `${t('reservations.table.table08')}`,
      sort: true,
      formatter: textReduce,
    },
    {
      dataField: 'status',
      text: `${t('reservations.table.table09')}`,
      sort: true,
      formatter: addStatus,
    },
    {
      dataField: 'idNewItems',
      text: `${t('reservations.table.table10')}`,
      formatter: addActionButton,
    },
  ];  

  const columns: any = prevColumns.filter((e: any) => e !== false);

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    );
  }

  const loadingColumns = [
    {
      dataField: 'contasd',
      text: `${t('reservations.table.loadingReservations')}`,
      formatter: loadingFunc,
    },
  ];

  const loadingProducts = [
    { contas: '' },
    { contas: '' },
    { contas: '' },
    { contas: '' },
    { contas: '' },
    { contas: '' },
    { contas: '' },
    { contas: '' },
    { contas: '' },
    { contas: '' },
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [
    { dataField: 'clientes', text: `${t('reservations.table.reservations')}` },
  ];

  const notFoundProducts = [{ clientes: t('reservations.table.notFound') }];
  /* [END] In case of empty table*/

  /* const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    //showTotal: true,
    //alwaysShowAllBtns: true,
  }); */

  const rowClasses = (row: any) => {
    if (row.status === 3) {
      return 'border-analise';
    } else if (row.status === 1) {
      return 'border-ativo';
    } else if (row.status === 2) {
      return 'border-inativo';
    } else {
      return '';
    }
  };

  const borderResp = (row: any) => {
    if (row.status === 3) {
      return 'border-analise-resp';
    } else if (row.status === 1) {
      return 'border-ativo-resp';
    } else if (row.status === 2) {
      return 'border-inativo-resp';
    } else {
      return '';
    }
  };

  const rowClassesResp = (row: any) => {
    if (row.status === 3) {
      return 'Em analise';
    } else if (row.status === 1) {
      return 'Ativo';
    } else if (row.status === 2) {
      return 'Inativo';
    } else {
      return 'Em análise';
    }
  };

  useEffect(() => {
    //tablesizing
    window.addEventListener('resize', function () {
      var newWidth = window.innerWidth;
      setWidth(newWidth);
    });
  }, []);

  if (reservations !== null) {
    return (
      <>
        <div className="table-default reservations-table">
          <div className="table-container">
            {/* <div className="table-title">
              <h3 className="mb-4">Reservas</h3>
            </div> */}
            <div>
              <div
                style={{ cursor: 'pointer' }}
                className="btn btn-bills"
                onClick={exportReservations}
              >
                <FontAwesomeIcon
                  icon={['fal', 'share']}
                  size="1x"
                  style={{ marginRight: '5px' }}
                />
                {t('reservations.table.export')}
              </div>
            </div>

            {width > 640 ? (
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={reservations}
                columns={columns}
                rowClasses={rowClasses}
              />
            ) : (
              <div className="table-mobile">
                <Table className="table table-bordered">
                  <Thead>
                    <Tr>
                      <Th>
                        {columns.map((elem: any, index: any) => {
                          return <Td key={index}>{elem.text}</Td>;
                        })}
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {reservations.map((elem: any, index: any) => {
                      return (
                        <Tr className={borderResp(elem)}>
                          <Th key={elem.idNewItems}>
                            <Td>
                              <strong>{columns[0].text}:</strong> &nbsp;
                              {elem.dataEmissao
                                .split('T')[0]
                                .split('-')
                                .reverse()
                                .join('/')}
                            </Td>
                            <Td>
                              <strong>{columns[1].text}:</strong> &nbsp;{' '}
                              {elem.reservaC2}
                            </Td>
                            <Td>
                              <strong>{columns[2].text}:</strong> &nbsp;{' '}
                              {elem.startTimeLocal
                                .split('T')[0]
                                .split('-')
                                .reverse()
                                .join('/')}
                            </Td>
                            <Td>
                              <strong>{columns[3].text}:</strong> &nbsp;{' '}
                              {elem.productName}
                            </Td>
                            <Td>
                              <strong>{columns[4].text}:</strong> &nbsp;{' '}
                              {elem.supplierName}
                            </Td>
                            <Td>
                              <strong>{columns[5].text}:</strong> &nbsp;{' '}
                              {elem.quantidadePax}
                            </Td>
                            <Td>
                              <strong>{columns[6].text}:</strong> &nbsp;{' '}
                              {elem.compName}
                            </Td>
                            <Td>
                              <strong>{columns[7].text}:</strong> &nbsp;{' '}
                              {elem.source}
                            </Td>
                            <Td>
                              <strong>{columns[8]?.text}:</strong> &nbsp;{' '}
                              {rowClassesResp(elem)}
                            </Td>
                            <Td className="d-flex">
                              <strong>{columns[9]?.text}:</strong> &nbsp;
                              {
                                <>
                                  <div className="">
                                    <Dropdown
                                      drop="start"
                                      className="dropdown-custom btn-drop py-0"
                                    >
                                      <Dropdown.Toggle
                                        className=""
                                        variant="light "
                                      >
                                        {/* <FontAwesomeIcon
                                        size="lg"
                                        icon={["fal", "ellipsis-h"]}
                                        color="#707070"
                                      />  */}
                                        <span
                                          style={{
                                            fontSize: '14px',
                                            color: '#707070',
                                          }}
                                        >
                                          ...
                                        </span>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          eventKey="1"
                                          onClick={() => {
                                            seeDetails(elem.idNewItems);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={['fal', 'file-alt']}
                                          //className="mr-2"
                                          />
                                          <span>
                                            {t(
                                              'reservations.table.dropdown.details',
                                            )}
                                          </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          eventKey="2"
                                          onClick={() => {
                                            seeDetailsEdit(elem.idNewItems);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={['fal', 'edit']}
                                          //className="mr-2"
                                          />
                                          <span>
                                            {t(
                                              'reservations.table.dropdown.edit',
                                            )}
                                          </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          eventKey="3"
                                          onClick={() => {
                                            seeLogs(elem.idNewItems);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={['fal', 'bell']}
                                          />
                                          <span>log</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          eventKey="3"
                                          onClick={() => {
                                            getVoucher(elem.idNewItems);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={['fal', 'download']}
                                          //className="mr-2"
                                          />
                                          <span>
                                            {t('reservations.table.voucher')}
                                          </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          eventKey="3"
                                          onClick={() => {
                                            getReciboEmail(elem.idNewItems);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={['fal', 'download']}
                                          //className="mr-2"
                                          />
                                          <span>
                                            {t('reservations.table.receipt')}
                                          </span>
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item eventKey="2" disabled={true} ><FontAwesomeIcon icon={['fal', 'comment-alt-dollar']} className="mr-2" />   {t('reservations.table.dropdown.account')}</Dropdown.Item>
              <Dropdown.Item eventKey="4" disabled={true}><FontAwesomeIcon icon={['fal', 'circle']} className="mr-2" />   {t('reservations.table.dropdown.status')}</Dropdown.Item>
              <Dropdown.Item eventKey="5" disabled={true}><FontAwesomeIcon icon={['fal', 'file-contract']} className="mr-2" />  {t('reservations.table.dropdown.contract')}</Dropdown.Item> */}
                                        {elem.status === 1 ? (
                                          <Dropdown.Item
                                            eventKey="3"
                                            disabled={false}
                                            className="text-danger remove"
                                            onClick={() => {
                                              handleModal(false);
                                              setCancelReservationObj(elem);
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={['fal', 'ban']}
                                            //className="mr-2"
                                            />
                                            <span>
                                              {t(
                                                'reservations.table.dropdown.remove',
                                              )}
                                            </span>
                                          </Dropdown.Item>
                                        ) : (
                                          <></>
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </>
                              }
                            </Td>
                          </Th>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </div>
            )}

            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>

        <Modal
          className="modal-custom reservation-details"
          show={detailsModal !== false}
          onHide={() => {
            setEditClick(false);
            setCurrentReservation(null);
            setDetailsModal(false);
            handleModalShow();
            reloadTable();
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <DetailsReservation
              id={currentReservation}
              setDetailsModal={setDetailsModal}
              action={() => {
                handleModal(false);
              }}
              setCancelReservationObj={setCancelReservationObj}
              editClick={editClick}
              getReciboEmail={getReciboEmail}
              setModalErrorShow={setModalErrorShow}
            />
          </>
        </Modal>

        <Modal
          className="reminder modal-custom reservation-details"
          show={reminderModal !== false}
          onHide={() => {
            setEditClick(false);
            setCurrentReservation(null);
            setReminderModal(false);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="reminder-container">
            <Reminder id={currentReservation} />
          </div>
        </Modal>

        <Modal
          className="modal-custom reservation-logs"
          show={logModal !== false}
          onHide={() => {
            setEditClick(false);
            setCurrentReservation(null);
            setLogModal(false);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <ReservationsLog
              id={currentReservation}
              setLogModal={setLogModal}
            />
          </>
        </Modal>

        <Modal
          className="modal-confirm modal-reservation-table"
          show={modalErrorShow === true}
          onHide={() => {
            setModalErrorShow(false);
          }}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <div className="modal-body text-center">
            <div>
              <FontAwesomeIcon
                icon={['fal', 'times-circle']}
                size="5x"
                className="text-primary"
                style={{ fontSize: '7.5em' }}
              />
            </div>

            <div className="px-md-1" style={{ padding: '0 1rem' }}>
              Erro ao processar operação
            </div>
            <div>
              <Button
                type="submit"
                className="btn btn-primary form-button px-4 py-2"
                onClick={() => setModalErrorShow(false)}
              >
                Fechar
              </Button>
            </div>
          </div>
        </Modal>

        {/* Modal de Cancelamento de Reservas */}
        <div className="bg-modal-custom modal-cancel-reservation modal-close">
          <div className="modal-confirm">
            <div className="modal-content modal-small">
              <div className="modal-body text-center">
                {modalContent !== 'loading' ? (
                  <>
                    <div>
                      {log === 1 || log === '1' ? (
                        <FontAwesomeIcon
                          icon={['fal', 'times-circle']}
                          size="5x"
                          className="text-primary"
                          style={{ fontSize: '7.5em' }}
                        />
                      ) : log === 0 || log === '0' ? (
                        <FontAwesomeIcon
                          icon={['fal', 'check-circle']}
                          size="5x"
                          className="text-primary"
                          style={{ fontSize: '7.5em' }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={['fal', 'question-circle']}
                          size="5x"
                          className="text-primary"
                          style={{ fontSize: '7.5em' }}
                        />
                      )}
                    </div>
                    {log === 0 || log === '0' || log === 1 || log === '1' ? (
                      <div dangerouslySetInnerHTML={{ __html: confirm }}></div>
                    ) : (
                      <div>
                        <p>{confirm}</p>
                        { }
                        <Controller
                          control={control}
                          name={`reason`}
                          //rules={{ required: { value: true, message: 'Por favor, informe esse campo.' } }}
                          render={({ field }: any) => (
                            <div
                              className={
                                valReason === true
                                  ? 'endpoint-error'
                                  : 'endpoint'
                              }
                              style={{ width: '75%', margin: 'auto' }}
                            >
                              <GetReasonCancel
                                propsField={field}
                                propsErrors={errors}
                                propsLabel="Motivo de Cancelamento"
                              />
                            </div>
                          )}
                        />
                        {valReason === true ? (
                          <small style={{ color: 'red' }}>
                            Por favor, informe o motivo do cancelamento
                          </small>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                    {log === 1 || log === '1' || log === 0 || log === '0' ? (
                      <div>
                        <button
                          className="btn btn-outline-primary mx-2"
                          onClick={() => {
                            handleModal(false);
                            setConfirm(
                              'Tem certeza que deseja cancelar esse item?',
                            );
                            setLog(undefined);
                            handleModalShow();
                            reloadTable();
                          }}
                        >
                          Sair
                        </button>
                      </div>
                    ) : (
                      <div className="space-modal">
                        <button
                          className="btn btn-outline-primary mx-2"
                          onClick={() => {
                            handleModal(false);
                            setConfirm(
                              'Tem certeza que deseja cancelar esse item?',
                            );
                            setLog(undefined);
                            handleModalShow();
                          }}
                        >
                          Recusar
                        </button>
                        <button
                          onClick={() => {
                            cancelReservation(
                              cancelReservationObj?.idNewItems,
                              cancelReservationObj?.reservaC2,
                              false,
                            );
                            setConfirm(
                              'Tem certeza que deseja cancelar esse item?',
                            );
                            setLog(undefined);
                            handleModalShow();
                          }}
                          data-btn="cancelItem"
                          title=""
                          id="73910"
                          data-controler="CDN2382"
                          data-toggle="modal"
                          className="btn btn-primary mx-2"
                        >
                          Confirmar
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="modal-body">
                    <div className="loading-modal">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <ModalShow
            modalShow={modalShow}
            setModalShow={setModalShow}
            modalMessage={modalMessage}
            setModalMessage={setModalMessage}
            modalLog={modalLog}
            setModalLog={setModalLog}
          />
        </div>
        {/* END - Modal de Cancelamento de Reservas */}
      </>
    );
  } else if (reservations === null && loading === true) {
    return (
      <>
        <div className="table-default reservations-table loading not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default reservations-table not-found">
          <div className="table-container">
            <div className="table-title">
              <h3 className="">{t('reservations.table.reservations')}</h3>
            </div>

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
};

export default ReservationsTableResponsive;
