import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';
import Select from 'react-select';
//import { useTranslation } from 'react-i18next';

export interface propPlus {
  propsField: any,
  propsLabel?: any
  propsErrors: any,
  setFilterSupplier?: any
  supplier?: any,
  setValue?: any,
  defaultId?: any,
  disabled?: boolean
};

const GetSuppliersFilterProduct: React.FC<propPlus> = ({
  propsField, propsErrors, setFilterSupplier, supplier, setValue, defaultId = null, disabled = false
}: propPlus) => {
  //const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listAllSuppliers() {
      try {
        const { data } = await api.get(`/BillsPayable/GetSuppliers`, config);
        if (data.status !== 400) {
          setInfo(data.data);

          if(defaultId !== null){
            let aux: any = data.data.find((e: any) => parseInt(e.supplierId) === parseInt(defaultId))
            if(aux){
              setValue('supplier', { 'compName': aux?.compName, 'supplierId': aux?.supplierId, "supplierIdentity": aux?.supplierIdentity });
            }
          }
        }
      } catch (error: any) {
        setTimeout(()=>{
          listAllSuppliers();
        },5000)
      }
    }
    listAllSuppliers();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(info !== null && info?.length <= 1) {
      setValue('supplier', info[0])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info])

  if (info !== null) {
    if (setFilterSupplier) {
      setFilterSupplier(propsField.value?.supplierId)
    }

    return (
      <>
        <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error" : "endpoint"} style={{height: '38px'}}>
          <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            isDisabled={disabled}
            {...propsField}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={`${supplier ? supplier : "Selecione"}`}
            options={Array.from(info)}
            getOptionValue={(option: any) => `${option.supplierId}`}
            getOptionLabel={(option: any) => `${option.compName}`}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetSuppliersFilterProduct;