import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
//import GetResourcesTypeFilter from "../../../../components/C2Points/GetResourcesTypeFilter";
//import GetResourceVehicleTypeFilter from "../../../../components/C2Points/GetResourceVehicleTypeFilter";

import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { addSEO } from "../../../store/product";

export interface operation {
    action: any;
    receiveObject: any;
    tourObject: any;
    isEdit: any;
    isMigration?: any;
    isTicket?: boolean;
    editFunction?: any;
};

const SEO: React.FC<operation> = React.memo(({
    action, receiveObject, tourObject, isEdit, isMigration = false, isTicket = false, editFunction = () => { }
}: operation) => {

    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const tourSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.tour)));
    /* Controle Redux  */

    const minLengthPT = 5;
    const maxLengthPT = 350;
    const minLengthEN = 5;
    const maxLengthEN = 350;
    const minLengthES = 5;
    const maxLengthES = 350;
    const [numberOfCaractersPT, setNumberOfCaractersPT] = useState<any>("0");
    const [numberOfCaractersEN, setNumberOfCaractersEN] = useState<any>("0");
    const [numberOfCaractersES, setNumberOfCaractersES] = useState<any>("0");

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({});

    const createTicketObject = (data: any) => {
        data.isTicket = true;

        data.metaTitle = [
            {
                "text": data.metaTitleBR,
                "lang": 1
            },
            {
                "text": data.metaTitleEN,
                "lang": 2
            },
            {
                "text": data.metaTitleES,
                "lang": 3
            }
        ]


        data.metaDescription = [
            {
                "text": data.metaDescriptionBR,
                "lang": 1
            },
            {
                "text": data.metaDescriptionEN,
                "lang": 2
            },
            {
                "text": data.metaDescriptionES,
                "lang": 3
            }
        ]

        data.palavraChave = [
            {
                "text": data.palavraChaveBR,
                "lang": 1
            },
            {
                "text": data.palavraChaveEN,
                "lang": 2
            },
            {
                "text": data.palavraChaveES,
                "lang": 3
            }
        ]
        data.slug = [
            {
                "text": data.palavraChaveBR,
                "lang": 1
            },
            {
                "text": data.palavraChaveEN,
                "lang": 2
            },
            {
                "text": data.palavraChaveES,
                "lang": 3
            }
        ]


        return data;
    }

    const createTourObject = (data: any) => {
        data.slug = [
            {
                "text": data.palavraChaveBR,
                "lang": 1
            },
            {
                "text": data.palavraChaveEN,
                "lang": 2
            },
            {
                "text": data.palavraChaveES,
                "lang": 3
            }
        ]
        return data;
    }

    const onSubmit = (data: any) => {
        let dataToInsert: any;

        if (isTicket) {
            dataToInsert = createTicketObject(data);
        } else {
            dataToInsert = createTourObject(data);
        }

        if (!isEdit && !isMigration) {
            dispatch(addSEO(dataToInsert));
            action("finish", dataToInsert);
        } else {
            //console.log(dataToInsert)
            editFunction(dataToInsert);
            //receiveObject(data);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (tourSIG.tour !== undefined) {
            setValue("metaTitleBR", tourSIG.tour.metaTitleBR || (tourSIG.tour?.metaTitle?.length > 0 ? tourSIG.tour?.metaTitle[0].text : ''));
            setValue("metaTitleEN", tourSIG.tour.metaTitleEN || (tourSIG.tour?.metaTitle?.length > 0 ? tourSIG.tour?.metaTitle[1].text : ''));
            setValue("metaTitleES", tourSIG.tour.metaTitleES || (tourSIG.tour?.metaTitle?.length > 0 ? tourSIG.tour?.metaTitle[2].text : ''));

            setValue("metaDescriptionBR", tourSIG.tour.metaDescriptionBR || (tourSIG.tour?.metaDescription?.length > 0 ? tourSIG.tour?.metaDescription[0].text : ''));
            setValue("metaDescriptionEN", tourSIG.tour.metaDescriptionEN || (tourSIG.tour?.metaDescription?.length > 0 ? tourSIG.tour?.metaDescription[1].text : ''));
            setValue("metaDescriptionES", tourSIG.tour.metaDescriptionES || (tourSIG.tour?.metaDescription?.length > 0 ? tourSIG.tour?.metaDescription[2].text : ''));

            setValue("palavraChaveBR", tourSIG.tour.palavraChaveBR || (tourSIG.tour?.palavraChave?.length > 0 ? tourSIG.tour?.palavraChave[0].text : ''));
            setValue("palavraChaveEN", tourSIG.tour.palavraChaveEN || (tourSIG.tour?.palavraChave?.length > 0 ? tourSIG.tour?.palavraChave[1].text : ''));
            setValue("palavraChaveES", tourSIG.tour.palavraChaveES || (tourSIG.tour?.palavraChave?.length > 0 ? tourSIG.tour?.palavraChave[2].text : ''));
        }
        
        let data: any = getValues();
        
        if(data.palavraChaveBR === ''){
            setValue("palavraChaveBR", tourSIG.tour.productName[0].text.replaceAll(' ', '-').toLowerCase());
        }
        if(data.palavraChaveEN === ''){
            setValue("palavraChaveEN", tourSIG.tour.productName[1].text.replaceAll(' ', '-').toLowerCase());
        }
        if(data.palavraChaveES === ''){
            setValue("palavraChaveES", tourSIG.tour.productName[2].text.replaceAll(' ', '-').toLowerCase());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            {
                (isEdit === false || tourSIG?.tour?.productName) ?
                    <Col md={9} lg={10}>
                        <Card className="pt-content">
                            <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                <h3 className="text-primary pt-title mb-0" onClick={() => { console.log(tourSIG.tour) }}>Search Engine Optimization</h3>

                                <div>
                                    <h4 className="my-4 pt-subtitle">Meta Data</h4>
                                </div>
                                <div className="" style={{ border: "none", padding: '24px 0' }}>

                                    <Row>
                                        <div>
                                            <h5>Português</h5>
                                        </div>

                                        <Col md='6'>
                                            <Row>
                                                <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                    <Form.Label className="label-small">
                                                        Chave URL (Português)
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name="palavraChaveBR"
                                                        rules={{
                                                            required: { value: true, message: 'Por favor, informe o Slug' },
                                                            minLength: {
                                                                value: 5,
                                                                message: 'Por favor, insira ao menos 5 caracteres'
                                                            },
                                                            maxLength: {
                                                                value: 200,
                                                                message: 'Por favor, insira no máximo 200 caracteres'
                                                            },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                aria-invalid={errors?.palavraChaveBR ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => {
                                                                    setValue('palavraChaveBR', e.target.value)
                                                                }}
                                                                onKeyPress={(e) => !/[a-zA-Z0-9-_]+/i.test(e.key) && e.preventDefault()}
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="palavraChaveBR"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                    <Form.Label className="label-small">
                                                        Meta Title (Português)
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name="metaTitleBR"
                                                        rules={{
                                                            required: { value: true, message: 'Por favor, informe o meta title' },
                                                            minLength: {
                                                                value: 5,
                                                                message: 'Por favor, insira ao menos 5 caracteres'
                                                            },
                                                            maxLength: {
                                                                value: 200,
                                                                message: 'Por favor, insira no máximo 200 caracteres'
                                                            },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                aria-invalid={errors?.metaTitleBR ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => {
                                                                    setValue('metaTitleBR', e.target.value)
                                                                }}
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="metaTitleBR"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                            </Row>
                                        </Col>



                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                            <Form.Label className="label-small d-flex justify-content-between">
                                                <span>Meta Description (Português)</span>
                                                <span style={{ fontWeight: 400 }}>{numberOfCaractersPT}/{maxLengthPT}</span>
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name="metaDescriptionBR"
                                                rules={{
                                                    required: { value: true, message: 'Por favor, informe o meta description' },
                                                    minLength: {
                                                        value: minLengthPT,
                                                        message: `Por favor, insira ao menos ${minLengthPT} caracteres`
                                                    },
                                                    maxLength: {
                                                        value: maxLengthPT,
                                                        message: `Por favor, insira no máximo ${maxLengthPT} caracteres`
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        as="textarea"
                                                        rows={5}
                                                        type="text"
                                                        aria-invalid={errors?.metaDescriptionBR ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        onChange={(e: any) => {
                                                            setValue('metaDescriptionBR', e.target.value);
                                                            setNumberOfCaractersPT(e.target.value.length)
                                                        }}
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="metaDescriptionBR"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                </div>

                                <hr />

                                <div className="" style={{ border: "none", padding: '24px 0' }}>
                                    <Row>
                                        <div>
                                            <h5>Inglês</h5>
                                        </div>
                                        <Col md='6'>
                                            <Row>
                                                <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                    <Form.Label className="label-small">
                                                        Chave URL (Inglês)
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name="palavraChaveEN"
                                                        rules={{
                                                            required: { value: true, message: 'Por favor, informe o Slug' },
                                                            minLength: {
                                                                value: 5,
                                                                message: 'Por favor, insira ao menos 5 caracteres'
                                                            },
                                                            maxLength: {
                                                                value: 200,
                                                                message: 'Por favor, insira no máximo 200 caracteres'
                                                            },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                aria-invalid={errors?.palavraChaveEN ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => {
                                                                    setValue('palavraChaveEN', e.target.value)
                                                                }}
                                                                onKeyPress={(e) => !/[a-zA-Z0-9-_]+/i.test(e.key) && e.preventDefault()}
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="palavraChaveEN"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>


                                                <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                    <Form.Label className="label-small">
                                                        Meta Title (Inglês)
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name="metaTitleEN"
                                                        rules={{
                                                            required: { value: true, message: 'Por favor, informe o meta title' },
                                                            minLength: {
                                                                value: 5,
                                                                message: 'Por favor, insira ao menos 5 caracteres'
                                                            },
                                                            maxLength: {
                                                                value: 200,
                                                                message: 'Por favor, insira no máximo 200 caracteres'
                                                            },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                aria-invalid={errors?.metaTitleEN ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => {
                                                                    setValue('metaTitleEN', e.target.value)
                                                                }}
                                                                //value={metaTitleEN}
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="metaTitleEN"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                            </Row>
                                        </Col>

                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                            <Form.Label className="label-small d-flex justify-content-between">
                                                <span>Meta Description (Inglês)</span>
                                                <span style={{ fontWeight: 400 }}>{numberOfCaractersEN}/{maxLengthEN}</span>
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name="metaDescriptionEN"
                                                rules={{
                                                    required: { value: true, message: 'Por favor, informe o meta description' },
                                                    minLength: {
                                                        value: minLengthEN,
                                                        message: `Por favor, insira ao menos ${minLengthEN} caracteres`
                                                    },
                                                    maxLength: {
                                                        value: maxLengthEN,
                                                        message: `Por favor, insira no máximo ${maxLengthEN} caracteres`
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        as="textarea"
                                                        rows={5}
                                                        type="text"
                                                        aria-invalid={errors?.metaDescriptionEN ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        onChange={(e: any) => {
                                                            setValue('metaDescriptionEN', e.target.value);
                                                            setNumberOfCaractersEN(e.target.value.length);
                                                        }}
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="metaDescriptionEN"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                </div>

                                <hr />

                                <div className="" style={{ border: "none", padding: '24px 0' }}>
                                    <Row>
                                        <div>
                                            <h5>Espanhol</h5>
                                        </div>
                                        <Col md='6'>
                                            <Row>
                                                <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                    <Form.Label className="label-small">
                                                        Chave URL (Espanhol)
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name="palavraChaveES"
                                                        rules={{
                                                            required: { value: true, message: 'Por favor, informe o Slug' },
                                                            minLength: {
                                                                value: 5,
                                                                message: 'Por favor, insira ao menos 5 caracteres'
                                                            },
                                                            maxLength: {
                                                                value: 200,
                                                                message: 'Por favor, insira no máximo 200 caracteres'
                                                            },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                aria-invalid={errors?.palavraChaveES ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => {
                                                                    setValue('palavraChaveES', e.target.value)
                                                                }}
                                                                onKeyPress={(e) => !/[a-zA-Z0-9-_]+/i.test(e.key) && e.preventDefault()}
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="palavraChaveES"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                    <Form.Label className="label-small">
                                                        Meta Title (Espanhol)
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name="metaTitleES"
                                                        rules={{
                                                            required: { value: true, message: 'Por favor, informe o meta title' },
                                                            minLength: {
                                                                value: 5,
                                                                message: 'Por favor, insira ao menos 5 caracteres'
                                                            },
                                                            maxLength: {
                                                                value: 200,
                                                                message: 'Por favor, insira no máximo 200 caracteres'
                                                            },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                aria-invalid={errors?.metaTitleES ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => {
                                                                    setValue('metaTitleES', e.target.value)
                                                                }}
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="metaTitleES"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                            </Row>
                                        </Col>

                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                            <Form.Label className="label-small d-flex justify-content-between">
                                                <span>Meta Description (Espanhol)</span>
                                                <span style={{ fontWeight: 400 }}>{numberOfCaractersES}/{maxLengthES}</span>
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name="metaDescriptionES"
                                                rules={{
                                                    required: { value: true, message: 'Por favor, informe o meta description' },
                                                    minLength: {
                                                        value: minLengthES,
                                                        message: `Por favor, insira ao menos ${minLengthES} caracteres`
                                                    },
                                                    maxLength: {
                                                        value: maxLengthES,
                                                        message: `Por favor, insira no máximo ${maxLengthES} caracteres`
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        as="textarea"
                                                        rows={5}
                                                        type="text"
                                                        aria-invalid={errors?.metaDescriptionES ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        onChange={(e: any) => {
                                                            setValue('metaDescriptionES', e.target.value);
                                                            setNumberOfCaractersES(e.target.value.length);
                                                        }}
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="metaDescriptionES"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                </div>

                                <div className="h-100 d-flex justify-content-end align-items-end">
                                    {
                                        isEdit !== true
                                            ?
                                            <Row className="mx-0 mt-5">
                                                <Button
                                                    variant="outline-primary"
                                                    className="form-button mr-3"
                                                    onClick={() => action('instructions')}
                                                >
                                                    Voltar
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                >
                                                    Continuar
                                                </Button>
                                            </Row>
                                            :
                                            <Row className=" mx-0 mt-5">
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                //onClick={() => action('meetingPoint')}
                                                >
                                                    Salvar
                                                </Button>
                                            </Row>
                                    }
                                </div>
                            </Form>
                        </Card>
                    </Col >
                    :
                    <>
                        <Col md={9} lg={10}>
                            <Card className="pt-content h-100">
                                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="load mb-4"></div>
                                    <div>Carregando dados do produto</div>
                                </div>
                            </Card>
                        </Col>
                    </>
            }
        </>
    )
})

export default SEO;

