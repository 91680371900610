import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import InputGroup from 'react-bootstrap/InputGroup';

import './FilterBillsPay.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import { Controller, useForm } from "react-hook-form";
import GetSuppliersIdentity from "../../../../components/C2Points/GetSuppliersIdentity";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
import GetAgents from "../../../../components/C2Points/GetAgentsFilter";
import i18next from "i18next";
import GetSuppliersType from "../../../../components/C2Points/GetSuppliersSupplierType";
import GetAccountingAccountsBillFilter from "../../../../components/C2Points/GetAccountingAccountsBillFilter";
//import GlobalAlert from "../../../Alert/GlobalAlert/GlobalAlert";

export interface propFilter {
    buscar: any,
    billsPay: any,
    error: any,
    clearActualValues: any;
    allowLoadFromFilter?: any;
    t?: any;
}

const FilterBillsPay: React.FC<propFilter> = ({
    buscar, billsPay, error, clearActualValues, allowLoadFromFilter, t
}) => {

    const today = new Date();
    const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const [filterLocal, setFilterLocal] = useState<any>("");
    const [filterStatus, setFilterStatus] = useState<any>(2);
    const [filterType, setFilterType] = useState<any>(null);
    const [filterCategory, setFilterCategory] = useState<any>(null);
    const [filterSupplier, setFilterSupplier] = useState<any>("");
    const [filterSupplierType, setFilterSupplierType] = useState<any>("");
    const [filterSupplierAgent,] = useState<any>("1");
    const [filterEmpresa, setFilterEmpresa] = useState<any>(null);
    const [filterTypeDate, setFilterTypeDate] = useState<any>(3);
    const [filterDate, setFilterDate] = useState<any>([todayStr]);

    //const [alert, setAlert] = useState<any>();
    //const [checked, setChecked] = useState<boolean>(true);

    /* Date Interval Confirmation */
    const [dateIntervalConfirmModalShow, setDateIntervalConfirmModalShow] = useState<any>(false);
    /* END - Date Interval Confirmation */

    const {
        control,
        formState: { errors },
    } = useForm({});

    const handleFilterClick = (event: any) => {

        var dataIntervalLong = false;

        if (filterDate.length === 2) {
            let beginDate = filterDate[0].split('/')
            let endDate = filterDate[1].split('/')

            const dataIni = new Date(parseInt(beginDate[2]), parseInt(beginDate[1]) - 1, parseInt(beginDate[0]));
            const dataFim = new Date(parseInt(endDate[2]), parseInt(endDate[1]) - 1, parseInt(endDate[0]));

            const days = (dataFim.getTime() - dataIni.getTime()) / (1000 * 60 * 60 * 24);

            dataIntervalLong = (days > 90); // 3 meses

        }

        if (dataIntervalLong) {
            setDateIntervalConfirmModalShow(true);
        } else {
            handleActionBuscar();
        }
    }

    const handleActionBuscar = () => {
        setDateIntervalConfirmModalShow(false);
        //console.log(filterLocal, filterStatus, filterType, filterSupplier, filterTypeDate, filterDate);
        buscar(filterLocal, filterStatus, filterType, filterCategory, filterSupplier, filterSupplierType, filterTypeDate, filterDate, filterEmpresa);
    };

    useEffect(() => {
        buscar(filterLocal, filterStatus, filterType, filterCategory, filterSupplier, filterSupplierType, filterTypeDate, filterDate, filterEmpresa)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18next.language])

    const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            if (selectValue[i].id === "rowsPerPage") {
                selectValue[i].value = "10";
            } else {
                selectValue[i].value = "";
            }
        }

        setFilterLocal("");
        setFilterStatus(2);
        setFilterType(null);
        setFilterCategory(null);
        setFilterSupplier("");
        setFilterSupplierType("");
        setFilterEmpresa(null);
        setFilterTypeDate(3);
        setFilterDate([todayStr]);
        buscar("", 2, null, null, "", "", 3, [todayStr], null);
        clearActualValues();
    };



    const handleChangeLocal = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterLocal(newValue);
    }

    const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatus(newValue);
    }

    const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterType(newValue);
    }

    /* const handleChangeSupplierType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        //setFilterSupplierType(newValue);

        setFilterSupplier("");
    } */

    const handleChangeEmpresa = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterEmpresa(newValue);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleChangeSupplier = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterSupplier(newValue);
    }

    const handleChangeTypeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterTypeDate(newValue);
    }

    /*     useEffect(() => {
            if (suppliers.length === 0 || error === true) {
                setAlert(
                    <GlobalAlert alertType={"danger"}>
                        Não foi possível encontrar nenhum fornecedor.
                    </GlobalAlert>
                )
                setTimeout(() => setAlert(null), 2000);
            } else {
                setAlert(null);
            }
        }, [suppliers, error]) */

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">{t('billsToPay.filter.searchBills')}</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            {/* {alert} */}
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('billsToPay.filter.locator')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    onChange={handleChangeLocal}
                                                    autoComplete='off'
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Empresa</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            autoComplete='off'
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeEmpresa(e)}
                                                            value={filterEmpresa}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value='1'>C2RIO</option>
                                                            <option value='2'>DESTINOW</option>
                                                            <option value='3'>DESTITECH</option>
                                                            <option value='4'>TAH</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <   Form.Label>{t('billsToPay.filter.paymentStatus')}</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            autoComplete='off'
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatus(e)}
                                                            value={filterStatus}
                                                        >
                                                            <option value="">{t('billsToPay.filter.paymentStatusSelect.all')}</option>
                                                            <option value="1">{t('billsToPay.filter.paymentStatusSelect.pending')}</option>
                                                            <option value="2">{t('billsToPay.filter.paymentStatusSelect.reconciled')}</option>
                                                            {/* <option value="3">{t('billsToPay.filter.paymentStatusSelect.scheduled')}</option> */}
                                                            {/* <option value="4">{t('billsToPay.filter.paymentStatusSelect.authorized')}</option> */}
                                                            <option value="5">{t('billsToPay.filter.paymentStatusSelect.paid')}</option>
                                                            {/* 0222<option value="6">{t('billsToPay.filter.paymentStatusSelect.canceled')}</option> */}
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('billsToPay.filter.type')}</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            autoComplete='off'
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeType(e)}
                                                            value={filterType}
                                                        >
                                                            <option value="">{t('billsToPay.filter.typeSelect.all')}</option>
                                                            <option value="1">{t('billsToPay.filter.typeSelect.operational')}</option>
                                                            <option value="2">{t('billsToPay.filter.typeSelect.nonOperational')}</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('billsToPay.filter.category')}</Form.Label>

                                                <Controller
                                                    control={control}
                                                    name="categoria"
                                                    render={({ field }: any) => (
                                                        <GetAccountingAccountsBillFilter propsField={field} propsErrors={errors} propsLabel="Categorias" setFilterAccount={setFilterCategory} />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('billsToPay.filter.supplierType')}</Form.Label>

                                                <Controller
                                                    control={control}
                                                    name="fornecedor"
                                                    render={({ field }: any) => (
                                                        <GetSuppliersType propsField={field} propsErrors={errors} propsLabel="Fornecedores" setFilterType={setFilterSupplierType} />
                                                    )}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            {/* <Form.Group as={Col} md="2">
                                                <Form.Label>Fornecedor/Agente</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                        autoComplete='off'
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeSupplierType(e)}
                                                            value={filterSupplierType}
                                                        >
                                                            <option value="">Todos</option>
                                                            <option value="1">Fornecedor</option>
                                                            <option value="2">Agente</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group> */}
                                            {filterSupplierAgent === "1" ?
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>{t('billsToPay.filter.supplier')}</Form.Label>
                                                    {/* <Form.Control
                                                    type="text"
                                                    autoComplete='off'
                                                    onChange={handleChangeSupplier}
                                                /> */}
                                                    <Controller
                                                        control={control}
                                                        name="fornecedor"
                                                        render={({ field }: any) => (
                                                            <GetSuppliersIdentity propsField={field} propsErrors={errors} propsLabel="Fornecedores" setSupplier={setFilterSupplier} t={t} />
                                                        )}
                                                    />
                                                </Form.Group>
                                                : filterSupplierAgent === "2" ?
                                                    <Form.Group as={Col} md="2">
                                                        <Form.Label>Agente</Form.Label>
                                                        <Controller
                                                            control={control}
                                                            name="agente"
                                                            render={({ field }: any) => (
                                                                <GetAgents propsField={field} propsErrors={errors} propsLabel="Agentes" actionAgents={setFilterSupplier} />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                    : <></>}
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('billsToPay.filter.dateType')}</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            autoComplete='off'
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeTypeDate(e)}
                                                            value={filterTypeDate}
                                                        >
                                                            <option value="">{t('billsToPay.filter.dateTypeSelect.select')}</option>
                                                            <option value="3">{t('billsToPay.filter.dateTypeSelect.expiration')}</option>
                                                            <option value="2">{t('billsToPay.filter.dateTypeSelect.operation')}</option>
                                                            <option value="1">{t('billsToPay.filter.dateTypeSelect.reservation')}</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('billsToPay.filter.date')}</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{allowLoadFromFilter === true ? <p className="load"></p> : t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <ModalQuestion
                modalShow={dateIntervalConfirmModalShow}
                setModalShow={setDateIntervalConfirmModalShow}
                titulo=""
                mensagem="Intervalo de data excede 3 meses. Continua?"
                handleActionConfirmModal={handleActionBuscar}
            />
        </div>
    )
}

export default FilterBillsPay;