import React, { useEffect, useState } from 'react';

import { Container, Row, Col, Card, Modal } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";
//import InputMask from "react-input-mask";
import Button from 'react-bootstrap/Button';

import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';

import '../../../../assets/sass/accordion.scss';

import api from '../../../../services/api';
import GroupFilePassengers from '../GroupFilePassengers/GroupFilePassengers';
import GroupFileServices from '../GroupFileServices/GroupFileServices';
import SearchAllAgent from '../../../../components/C2Points/GroupFile/SearchAllAgent';
import ModalContentPayment from '../ModalContentPayment/ModalContentPayment';

import GetStates from "../../../../components/C2Points/GetStates";
import GetCitysByState from "../../../../components/C2Points/GetCitysByState";
import DropdownNumberPeople from '../../../../components/DropdownNumberPeopleGroupFile/DropdownNumberPeopleGroupFile';

export interface propAccountsForm {
    action: any,
    info: any
};

function GroupFileForm({
    action, info
}: propAccountsForm) {

    const { t } = useTranslation();

    const today = new Date();
    const todayStr = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`;

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;
    const userId: any = tourAuth.user.id;
    const [customerType, setCustomerType] = useState<any>(info ? info.customerType : 1);
    const [operatorId,] = useState<any>(info ? info.operatorId : userId);
    const [operatorName,] = useState<any>(info ? `${info.operatorUser.name} ${info.operatorUser.lastName}` : `${userName} ${userLastName}`);
    //const [clientIdentity, setClientIdentity] = useState<any>(info ? info.clientIdentity : "");
    const [client, setClient] = useState<any>(info ? {code: info.clientIdentity, name: info.clientName} : null);
    //const [groupName, setGroupName] = useState<any>(info ? info.groupName : "");

    const [tarType, setTarType] = useState<any>(info ? info.tarType : 1);
    //const [comission, setComission] = useState<any>(info ? formatValue(info.comission) : "");
    //const [markup, setMarkup] = useState<any>(info ? formatValue(info.markup) : "");

    const [dateCreated, ] = useState<any>(info ? info.dateCreated : todayStr);
    const [firstServiceDate, setFirstServiceDate] = useState<any>(info ? info.firstServiceDate : "");
    //const [currency, setCurrency] = useState<any>(info ? info.currency : "BRL");
    
    const [fileNo, setFileNo] = useState<any>(info ? info.fileNo : '');
    const [operationStatus, ] = useState<any>(info ? info.operationStatus : 1);
    const [financialStatus, ] = useState<any>(info ? info.financialStatus : 1);

    const [arrayPassengers, setArrayPassengers] = useState<any>(info ? info.passengers : 
        []//[{id:-1,name:'',passportId:'',email:'',phone:''}]
    );
    const [passengersDeleted, setPassengersDeleted] = useState<any>([]);

    const [arrayServices, setArrayServices] = useState<any>(info ? info.services : 
        [
            //{id:-1,type:'6',resourceId:'',productCode:'',supplierId:'',globalPrice:'1',netAmount:'',saleAmount:'',serviceDate:'',city:'',language:'1',status:'1'}
            //{id:-1,type:'6',resourceId:'',productCode:'PRDC-ZC839',serviceName:'Teste',supplierId:'414',supplierName:'C2Rio',globalPrice:'1',netAmount:'',saleAmount:'',serviceDate:'',city:'Rio de Janeiro',language:'1',status:'1'}
            //,{id:-2,type:'7',resourceId:'',productCode:'PNY6966',serviceName:'Barco do Forró no Rio Sergipe',supplierId:'482',supplierName:'C2Rio Transporte',globalPrice:'1',netAmount:'',saleAmount:'',serviceDate:'',city:'Arraial do Cabo',language:'1',status:'1'}
        ]
    );
    const [servicesDeleted, setServicesDeleted] = useState<any>([]);
    
    const [totalReceive, setTotalReceive] = useState<any>(info ? info.totalReceive : 0);
    const [totalPay, setTotalPay] = useState<any>(info ? info.totalPay : 0);
    const [profitAmount, setProfitAmount] = useState<any>(info ? info.profitAmount : 0);
    const [profitTax, setProfitTax] = useState<any>(info ? info.profitTax : 0);

    const [invoiceType, setInvoiceType] = useState<any>(info ? info.invoiceType : 1);

    const [totalQuantity, setTotalQuantity] = useState<any>(info ? info.totalQuantity : 1);
    const [totalAdult, setTotalAdult] = useState<any>(info ? info.invoiceType : 1);
    const [totalChild, setTotalChild] = useState<any>(info ? info.invoiceType : 0);
    const [totalInfant, setTotalInfant] = useState<any>(info ? info.invoiceType : 0);

    //const [totalAmount, setTotalAmount] = useState<any>(info ? formatValue(info.totalAmount) : "");

    const [modalResponse, setModalResponse] = useState<any>(null);
    const [checkoutResponse, setCheckoutResponse] = useState<any>('');
    const [modalContent, setModalContent] = useState<any>('loading');
    const [disableButton, setDisableButton] = useState<boolean>(false);

    const [controlZipCode, setControlZipCode] = useState<any>(0);

    const [errorComission, setErrorComission] = useState<boolean>(false);
    const [errorMarkup, setErrorMarkup] = useState<any>([]);

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            customerType: info ? info.customerType : 1,
            clientIdentity: info ? info.clientIdentity : '',
            groupName: info ? info.groupName : '',
            email: info ? info.email : '',
            phone: info ? info.phone : '',
            addressZipCode: info ? info.addressZipCode : '',
            //addressState: info ? info.addressState : '',
            //addressCity: info ? info.addressCity : '',

            city: info ? { description: info.cityDesc, id: info.cityId } : null,
            state: info ? { description: info.stateDesc, id: info.state } : null,

            address: info ? info.address : '',
            addressDistr: info ? info.addressDistr : '',
            addressNumber: info ? info.addressNumber : '',
            addressCompl: info ? info.addressCompl : '',
            observation: info ? info.observation : '',
            tarType: info ? info.tarType : 1,
            comission: info ? info.comission : '0,00',
            markup: info ? info.markup : '10,00',
            currency: info ? info.currency : 'BRL',
            invoiceType: info ? info.invoiceType : 1,
            totalAmount: info ? info.totalAmount : ''
        }
    });

    const watchZipCode = watch(`addressZipCode`, false);
    const watchState = watch(`state`, null);

    const watchComission = watch(`comission`, '0,00');
    const watchMarkup = watch(`markup`, '10,00');

    useEffect(() => {
        setControlZipCode(watchZipCode?.length)
        if (watchZipCode?.length === 5) {
            if (watchZipCode?.length > controlZipCode) {
                setValue('addressZipCode', `${watchZipCode}-`)
            }
        }


        if (watchZipCode?.length > 9) {
            var controlLength: any = watchZipCode;
            controlLength = controlLength.split('');
            controlLength.pop();
            controlLength = controlLength.join('');
            setValue('addressZipCode', `${controlLength}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchZipCode])

    useEffect(() => {
        if(strToFloat(watchComission) < 0 || strToFloat(watchComission) > 30) {
            setErrorComission(true);
        } else {
            setErrorComission(false);
        }

        var erraos = [];

        if(tarType === 1 && strToFloat(watchMarkup) < strToFloat(watchComission)) {
            erraos.push(t('groupsAndCustomized.validationMsg.markupLessThanComission'));
        } 
        if(strToFloat(watchMarkup) < 10 || strToFloat(watchMarkup) > 50) {
            erraos.push(t('groupsAndCustomized.validationMsg.markupRange'));
        }

        setErrorMarkup(erraos);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchComission,watchMarkup,tarType])


    const strToFloat = (str: string): any => {
        if(!str || str === '') return 0.0;
        str = '' + str;
        return parseFloat(str.replace(/[^0-9,]*/g, '').replace(',', '.'));
    }
    const strToInt = (str: string): any => {
        if(!str || str === '') return 0;
        return parseInt(str);
    }
    
    const onSubmit = (dataObj: any) => {

        if(errorComission || errorMarkup.length > 0)  return;

        if(arrayServices.length === 0) {
            setCheckoutResponse(t('groupsAndCustomized.modalPayment.noServices'));
            setModalResponse(true);
            setModalContent("warning");
            return;
        }

        var route = '';
        
        const comissionF = parseFloat(dataObj.comission ? dataObj.comission.replace(',', '.') : 0);
        const markupF = parseFloat(dataObj.markup ? dataObj.markup.replace(',', '.') : 0);
        const totalAmountF = parseFloat(dataObj.totalAmount ? dataObj.totalAmount.replace(',', '.') : 0);

        const servicesObj: any = [];

        for(let i = 0; i < arrayServices.length; i++) {
            if(!arrayServices[i].serviceDate) {
                setCheckoutResponse(t('groupsAndCustomized.modalPayment.invalidServiceDate'));
                setModalResponse(true);
                setModalContent("warning");
                return;
            }

            const serviceObj = {
                id: arrayServices[i].id,
                type: arrayServices[i].type,
                resourceId: arrayServices[i].resourceId ? arrayServices[i].resourceId : 0,
                productCode: arrayServices[i].productCode,
                supplierId: arrayServices[i].supplierId ? arrayServices[i].supplierId : 0,
                globalPrice: arrayServices[i].globalPrice,
                netAmount: strToFloat(arrayServices[i].netAmount),
                saleAmount: strToFloat(arrayServices[i].saleAmount),
                serviceDate: arrayServices[i].serviceDate,
                city: arrayServices[i].city,
                language: arrayServices[i].language,
                status: arrayServices[i].status,
                totalQuantity: arrayServices[i].totalQuantity,
                totalAdult: arrayServices[i].totalAdult,
                totalChild: arrayServices[i].totalChild,
                totalInfant: arrayServices[i].totalInfant,
                
                productType: arrayServices[i].productType,
                origin: arrayServices[i].origin ? arrayServices[i].origin : '',
                destiny: arrayServices[i].destiny ? arrayServices[i].destiny : '',
                latOrigin: arrayServices[i].latOrigin ? arrayServices[i].latOrigin : '',
                longOrigin: arrayServices[i].longOrigin ? arrayServices[i].longOrigin : '',
                latDestiny: arrayServices[i].latDestiny ? arrayServices[i].latDestiny : '',
                longDestiny: arrayServices[i].longDestiny ? arrayServices[i].longDestiny : ''
            }
            servicesObj.push(serviceObj);
        }

        var params: any = {
            id: info ? info.id : 0,
            customerType: customerType,
            operatorId: operatorId,
            clientIdentity: client.code,
            groupName: dataObj.groupName,
            tarType: dataObj.tarType,
            comission: comissionF,
            markup: markupF,
            dateCreated: dateCreated,
            firstServiceDate: firstServiceDate,
            currency: dataObj.currency,
            fileNo: info ? info.fileNo : '',

            invoiceType: invoiceType,
            totalAmount: totalAmountF,

            passengers: arrayPassengers,
            services: servicesObj,
            passengersDeleted: passengersDeleted,
            servicesDeleted: servicesDeleted,
            payments: [
                {
                    payMethod: 111
                }
            ],
            totalQuantity: totalQuantity,
            totalAdult: totalAdult,
            totalChild: totalChild,
            totalInfant: totalInfant
        };

        if(customerType === 3) {
            params.email = dataObj.email;
            params.phone = dataObj.phone;
            params.addressZipCode = dataObj.addressZipCode;
            params.addressState = dataObj.state.id;
            params.addressCity = dataObj.city.description;
            params.address = dataObj.address;
            params.addressNumber = dataObj.addressNumber;
            params.addressCompl = dataObj.addressCompl;
            params.observation = dataObj.observation;
        }

        //TODO
        console.log(params, "objeto da conta");
        //return;

        setDisableButton(true);
        setModalResponse(true);
        setModalContent("loading");

        if (action === 1) {
            //console.log('insert');
            route = 'CreateGroupFile';
        } else {
            //console.log('edit');
            route = 'EditGroupFile';
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const EditAccounts = async () => {
            try {

                const { data } = await api.post(`GroupFile/${route}`, params, config);
                /* console.log(data.data); */
                if (data.status !== 400) {
                    var response = data.data;

                    setCheckoutResponse(data);

                    if(response.log === 1) {
                        setModalContent("warning");
                        setDisableButton(false);
                    } else {
                        setFileNo(response.data.fileNo);

                        
                        setModalContent("success");
                    }
                    // response.data.paymentLink

                    //alert(response.texto);
                    //if (response.log !== 1) {
                    //    window.location.href = window.location.origin + '/list-groups-customized';
                    //} 
                }
            } catch (error: any) {
                setDisableButton(false);
                setModalContent("error");

                // if (error?.response?.status === 401) {
                //     window.location.href = window.location.origin + '/';
                // }
                // if (error?.response?.status === 400) {
                //     window.location.href = window.location.origin + '/list-groups-customized';
                // }
            }
        }
        EditAccounts();
    };

    function resetForm() {
        // TODO 
        
    }

    const getPassengers = (objPosDevices: any, deletedObjects: any) => {

        setArrayPassengers(objPosDevices);

        setPassengersDeleted(deletedObjects);
    }

    const getServices = (objPosDevices: any, deletedObjects: any) => {

        setArrayServices(objPosDevices);

        setServicesDeleted(deletedObjects);

        updateFirstServiceDate(objPosDevices);

        calcTotals(objPosDevices);
    }

    function updateFirstServiceDate(services: any) {
        var temp = firstServiceDate;

        for(var i = 0; i < services.length; i++) {
            if(temp === '' || (services[i].serviceDate !== '' && strToDate(services[i].serviceDate) < strToDate(temp))) {
                temp = services[i].serviceDate;
            }
        }
        if(temp !== firstServiceDate) setFirstServiceDate(temp);

    }

    function strToDate(cell: any): any {
        if(cell === '') return null;
        var aux: any = cell.split("T")[0].split("-");

        return new Date(parseInt(aux[0]), parseInt(aux[1]), parseInt(aux[2]));
    }
    
    function formatDate(cell: any) {
        if (!cell) return '';
        //var date = convertUTCDateToLocalDate(new Date(cell));
        var aux: any = cell.split("T")[0].split("-");
        var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
        return date;
    }

    function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if(cell && cell !== '') {
            return cell.toFixed(2).replace(".", ",");
        } else {
            return '0,00';
        }
    }

    async function createNewFileNumber() {
        try {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };

            const params = {
                serviceDate: firstServiceDate
            };

            const { data } = await api.post(`GroupFile/CreateNewGroupFileNumber`, params, config);
            /* console.log(data.data); */
            if (data.status !== 400) {
                var response = data.data;

                if(response.log !== 0) {
                    alert(response.texto);
                } else {
                    setFileNo(response.data);
                }
            }
        } catch (error: any) {
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
            if (error?.response?.status === 400) {
                window.location.href = window.location.origin + '/list-groups-customized';
            }
        }
    }

    function getOperationStatusText(statusCode: any) {
        var statusStr = "";
        switch(statusCode) {
            case 1: statusStr = "quote"; break;
            case 2: statusStr = "bookingsRequested"; break;
            case 3: statusStr = "bookingsConfirmed"; break;
            case 4: statusStr = "partialPayment"; break;
            case 5: statusStr = "inOperation"; break;
            case 6: statusStr = "operated"; break;
            case 7: statusStr = "canceled"; break;
            default: return "";
        }
        return t(`groupsAndCustomized.operationStatusOpts.${statusStr}`);
    }

    function getFinancialStatusText(statusCode: any) {
        var statusStr = "";
        switch(statusCode) {
            case 1: statusStr = "pending"; break;
            case 2: statusStr = "invoiceSent"; break;
            case 3: statusStr = "partialReceived"; break;
            case 4: statusStr = "fullReceived"; break;
            default: return "";
        }
        return t(`groupsAndCustomized.financialStatusOpts.${statusStr}`);
    }

    function calcTotals(services: any) {
        var totalR = 0;
        var totalP = 0;
        var totalPax = totalQuantity;
        

        for(var i = 0; i < services.length; i++) {
            //var multi = 1;
            //if(services[i].globalPrice === '2') {
            //    //multi = totalPax;
            //    multi = services[i].totalQuantity;
            //}
            //totalP += parseFloat(services[i].netAmount ? services[i].netAmount.replace(',', '.') : 0) * multi;
            //totalR += parseFloat(services[i].saleAmount ? services[i].saleAmount.replace(',', '.') : 0) * multi;

            totalP += parseFloat(services[i].netAmount ? services[i].netAmount : 0);
            totalR += parseFloat(services[i].saleAmount ? services[i].saleAmount : 0);
        }

        //console.log(totalP, 'oh lacrymosaaa');

        if(tarType === 1) {
            const comissionF = strToFloat(watchComission);

            totalP = totalR * comissionF / 100;
        }

        setTotalReceive(totalR);
        setTotalPay(totalP);
        setProfitAmount(totalR - totalP);
        setProfitTax(totalR ? ((totalR - totalP) / totalR) * 100 : 0);
    }

    function openAddPartners() {
        // linkao
        window.open(`partners/add-partners`, "_blank");
    }

    function handleTotalPax(people: any) {
        setTotalAdult(people.adults);
        setTotalChild(people.childs);
        setTotalInfant(people.infants);

        const totQ = people.adults + people.childs + people.infants;
        setTotalQuantity(totQ);
        
        for(let i = 0; i < arrayServices.length; i++) {

            if(arrayServices[i].globalPrice === 1) {
                arrayServices[i].totalQuantity = totQ;
                arrayServices[i].totalAdult = people.adults;
                arrayServices[i].totalChild = people.childs;
                arrayServices[i].totalInfant = people.infants;
            }
        }
    }

    // useEffect(() => {
    //     if(fileNo === '' && firstServiceDate && action === 1) {
    //         createNewFileNumber();
    //     }
    // },[action]);

    return (
        <>
            <Card className="bg-white mt-4">
                <Accordion >
                    <Card.Body>
                        <Container className="p-0 mt-3" fluid>
                            <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={6}>
                                        <Controller
                                            control={control}
                                            name="customerType"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className='d-flex align-items-center' style={{ height: "86px" }}>
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.customerType.registeredCustomer")}
                                                        // name="customerType"
                                                        id="customerTypeRadios1"
                                                        onClick={() => { setCustomerType(1) }}
                                                        checked={customerType === 1}
                                                        className="col-md-4"
                                                    />
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.customerType.newCustomer")}
                                                        // name="customerType"
                                                        id="customerTypeRadios2"
                                                        onClick={() => { setCustomerType(2); openAddPartners(); }}
                                                        checked={customerType === 2}
                                                        className="col-md-4"
                                                    />
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.customerType.quickFile")}
                                                        // name="customerType"
                                                        id="customerTypeRadios3"
                                                        onClick={() => { setCustomerType(3) }}
                                                        checked={customerType === 3}
                                                        className="col-md-4"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6}>
                                        <Form.Label>{t("groupsAndCustomized.operator")}</Form.Label>
                                        <p>{operatorName}</p>
                                    </Form.Group>
                                </Row>    
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={6} controlId="formGridSupplier">
                                        <Form.Label>{t("groupsAndCustomized.client")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="clientIdentity"
                                            rules={{ required: { value: !client, message: t("groupsAndCustomized.validationMsg.client") }, }}
                                            render={({ field }: any) => (
                                                <SearchAllAgent
                                                    //propsField={field}
                                                    //propsErrors={errors}
                                                    //propsLabel="Cliente"
                                                    agent={client}
                                                    //agent={{code: 'SPPFRUT', name: 'C2Rio', tarType: 1}}
                                                    setAgent={(agent: any) => { setClient(agent); setTarType(agent.tarType); }}
                                                    errors={errors?.clientIdentity ? true : false}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="clientIdentity"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    {customerType !== 3 ?
                                    <Form.Group as={Col} md={6} controlId="formGridDesc">
                                        <Form.Label>{t("groupsAndCustomized.groupName")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="groupName"
                                            rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.groupName") }, }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.groupName ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    //onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    //value={groupName}
                                                    //onChange={(e: any) => { setGroupName(e.target.value); field.onChange(e); }}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="groupName"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    :<></>}
                                </Row>
                                {customerType === 3 ? 
                                <>
                                    <Row className="mb-3">
                                        {/* email */}
                                        <Form.Group as={Col} md={6} controlId="formGridDesc">
                                            <Form.Label>{t("groupsAndCustomized.email")}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="email"
                                                rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.email") }, }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.email ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        //onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        //value={email}
                                                        //onChange={(e: any) => { setEmail(e.target.value); field.onChange(e); }}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="email"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        {/* telefone */}
                                        <Form.Group as={Col} md={6} controlId="formGridDesc">
                                            <Form.Label>{t("groupsAndCustomized.phone")}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="phone"
                                                rules={{ required: { value: true, message: t("groupsAndCustomized.validationMsg.phone") }, }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.phone ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        //onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        //value={phone}
                                                        //onChange={(e: any) => { setPhone(e.target.value); field.onChange(e); }}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="phone"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        {/* endereço */}
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyCEP">
                                            <Form.Label>{t('accordionInfoCompany.cep')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="addressZipCode"
                                                render={({ field }: any) => (
                                                    <Controller
                                                        control={control}
                                                        name="addressZipCode"
                                                        rules={{ required: { value: true, message: t('groupsAndCustomized.validationMsg.addressZipCode') } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.addressZipCode ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                required
                                                                //value={addressZipCode}
                                                                //onChange={(e: any) => { setAddressZipCode(e.target.value); field.onChange(e); }}
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="addressZipCode"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyState">
                                            <Form.Label>{t('accordionInfoCompany.state')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="state"
                                                rules={{ required: { value: true, message: t('groupsAndCustomized.validationMsg.addressState') } }}
                                                //defaultValue={addressState}
                                                render={({ field }: any) => (
                                                    <GetStates
                                                        propsField={field}
                                                        propsLabel={t('accordionInfoCompany.state')}
                                                        propsErrors={errors}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="state"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyCity">
                                            <Form.Label>{t('accordionInfoCompany.city')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="city"
                                                rules={{ required: { value: true, message: t('groupsAndCustomized.validationMsg.addressCity') } }}
                                                //defaultValue={addressCity}
                                                render={({ field }: any) => (
                                                    <GetCitysByState propsField={field} propsErrors={errors} uf={watchState?.id} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="city"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        {/* address pt 2 */}
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyDistrict">
                                            <Form.Label>{t('accordionInfoCompany.distr')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="addressDistr"
                                                rules={{ required: { value: true, message: t('groupsAndCustomized.validationMsg.addressDistr') } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.addressDistr ? "true" : ""}
                                                        label={t('accordionInfoCompany.distr')}
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        autoComplete='off'
                                                        //value={addressDistr}
                                                        //onChange={(e: any) => { setAddressDistr(e.target.value); field.onChange(e); }}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="addressDistr"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyAddress">
                                            <Form.Label>{t('accordionInfoCompany.address')}:</Form.Label>
                                            <Controller
                                                control={control}
                                                name="address"
                                                rules={{ required: { value: true, message: t('groupsAndCustomized.validationMsg.address') } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.address ? "true" : ""}
                                                        label={t("accordionInfoCompany.address")}
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        autoComplete='off'
                                                        //value={address}
                                                        //onChange={(e: any) => { setAddress(e.target.value); field.onChange(e); }}
                                                    />
                                                )}
                                            />
                                            {/* {addressError === true
                                                ? <small style={{ color: "red" }}>Por favor, informe um endereço com pelo menos 4 caracteres.</small>
                                                : <></>
                                            } */}
                                            <ErrorMessage
                                                errors={errors}
                                                name="address"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyNumber">
                                            <Form.Label>{t('accordionInfoCompany.num')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="addressNumber"
                                                rules={{ required: { value: true, message: t('groupsAndCustomized.validationMsg.addressNumber') } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        aria-invalid={errors?.addressNumber ? "true" : ""}
                                                        label={t("accordionInfoCompany.num")}
                                                        variant="standard"
                                                        margin="normal"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        required
                                                        autoComplete='off'
                                                        //value={addressNumber}
                                                        //onChange={(e: any) => { setAddressNumber(e.target.value); field.onChange(e); }}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="addressNumber"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyComplement">
                                            <Form.Label>{t('accordionInfoCompany.comp')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="addressCompl"
                                                //rules={{ required: { value: true, message: 'Por favor, informe o nome fantasia.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        label={t("accordionInfoCompany.comp")}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete='off'
                                                        //value={addressCompl}
                                                        //onChange={(e: any) => { setAddressCompl(e.target.value); field.onChange(e); }}
                                                    />
                                                )}
                                            />
                                            {/* <ErrorMessage
                                                errors={errors}
                                                name="addressCompl"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            /> */}
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        {/* observação */}
                                        <Form.Group as={Col} md="4" controlId="formInfoCompanyComplement">
                                            <Form.Label>{t('groupsAndCustomized.observation')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="observation"
                                                //rules={{ required: { value: true, message: 'Por favor, informe o nome fantasia.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        label={t("groupsAndCustomized.observation")}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete='off'
                                                        //value={observation}
                                                        //onChange={(e: any) => { setObservation(e.target.value); field.onChange(e); }}
                                                    />
                                                )}
                                            />
                                            {/* <ErrorMessage
                                                errors={errors}
                                                name="observation"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            /> */}
                                        </Form.Group>
                                    </Row>
                                </>
                                : <></>}
                                {customerType !== 3 ? 
                                <Row className="mb-3">    
                                    <Form.Group as={Col} md={6}>
                                        <Controller
                                            control={control}
                                            name="tarType"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className='d-flex align-items-center' style={{ height: "86px" }}>
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.tarTypeOpts.clientCommissioned")}
                                                        // name="customerType"
                                                        id="tarTypeRadios1"
                                                        onClick={() => { setTarType(1) }}
                                                        checked={tarType === 1}
                                                        className="col-md-6"
                                                    />
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.tarTypeOpts.clientNet")}
                                                        // name="customerType"
                                                        id="tarTypeRadios2"
                                                        onClick={() => { setTarType(2) }}
                                                        checked={tarType === 2}
                                                        className="col-md-6"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                    {tarType === 1 ?
                                    <Form.Group as={Col} md={3} controlId="formGridCat">
                                        <Form.Label>{t("groupsAndCustomized.comission")}</Form.Label>
                                        {/* <Form.Control autoComplete='off' type="text" /> */}
                                        <Controller
                                            control={control}
                                            name="comission"
                                            rules={{ required: { value: true, message: t('groupsAndCustomized.validationMsg.comission') }, }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.comission || errorComission ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    //onChange={(e: any) => { setComission(e.target.value); field.onChange(e); }}
                                                    //value={comission}
                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && !(e.key === ',' && field.value.indexOf(',') < 0) && e.preventDefault()}
                                                    //onKeyPress={(e) => console.log((e.key === ',' && field.value.indexOf(',') < 0))}
                                                />
                                            )}
                                        />
                                        {errorComission ? <small style={{ color: "red" }}>{t('groupsAndCustomized.validationMsg.comissionRange')}</small> :
                                        <ErrorMessage
                                            errors={errors}
                                            name="comission"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />}
                                    </Form.Group>
                                    : <></>}
                                    <Form.Group as={Col} md={3} controlId="formGridCat">
                                        <Form.Label>{t("groupsAndCustomized.markup")}</Form.Label>
                                        {/* <Form.Control autoComplete='off' type="text" /> */}
                                        <Controller
                                            control={control}
                                            name="markup"
                                            rules={{ required: { value: true, message: t('groupsAndCustomized.validationMsg.markup') }, }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.markup || errorMarkup.length > 0 ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    //onChange={(e: any) => { setMarkup(e.target.value); field.onChange(e); }}
                                                    //value={markup}
                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && !(e.key === ',' && field.value.indexOf(',') < 0) && e.preventDefault()}
                                                />
                                            )}
                                        />
                                        {errorMarkup.length > 0 ? 
                                        errorMarkup.map((message: any) => <div><small style={{ color: "red" }}>{message}</small></div>)
                                        :
                                        <ErrorMessage
                                            errors={errors}
                                            name="markup"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />}
                                    </Form.Group>
                                </Row>
                                : <></>}
                                <Row className="mb-3">
                                    {/* Data da Abertura    Data do 1o Serviço    Moeda INVOICE(so "Real - BRL" por enquanto) */}
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>{t("groupsAndCustomized.dateCreated")}</Form.Label>
                                        <p>{formatDate(dateCreated)}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>{t("groupsAndCustomized.firstServiceDate")}</Form.Label>
                                        <p>{formatDate(firstServiceDate)}</p>
                                    </Form.Group>
                                    {customerType !== 3 ?
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>{t("groupsAndCustomized.currency")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="currency"
                                            rules={{ required: { value: true, message: t('groupsAndCustomized.validationMsg.currency') } }}
                                            //defaultValue={currency}
                                            render={({ field }: any) => (
                                                <div className={errors?.currency !== undefined ? "endpoint-error" : "endpoint"}>
                                                    <Form.Select
                                                        aria-labelledby="aria-label"
                                                        inputId="aria-example-input"
                                                        name="aria-live-color"
                                                        {...field}
                                                        as="select"
                                                        variant="standard"
                                                        margin="normal"
                                                        //onChange={(e: any) => {setCurrency(e.target.value); field.onChange(e);}}
                                                        //value={currency}
                                                    >
                                                        <option value="BRL">Real - BRL</option> 
                                                        {/* <option value="USD">Dolar - USD</option>  */}
                                                        {/* <option value="EUR">Euro - EUR</option>  */}
                                                    </Form.Select>
                                                </div>
                                            )} 
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="currency"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    : <></>}
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>{t("groupsAndCustomized.pax")}</Form.Label>
                                        <div className="panel-dropdown bg-white m-0"  style={{ lineHeight: "1.5rem" }}>
                                            <DropdownNumberPeople
                                                actionPeople={(people: any) => handleTotalPax(people)}
                                                ranges={{isActiveAdult: true, isActiveChild: true, isActiveElders: false, isActiveInfant: true, isActiveStudent: false, labelAdult: 'Adulto', labelChild: 'Criança', labelInfant: 'Infante'}}
                                            />
                                        </div>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={4}>
                                        <Form.Label>{t("groupsAndCustomized.fileNo")}</Form.Label>
                                        <p>{fileNo}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md={4}>
                                        <Form.Label>{t("groupsAndCustomized.operationStatus")}</Form.Label>
                                        <p>{getOperationStatusText(operationStatus)}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md={4}>
                                        <Form.Label>{t("groupsAndCustomized.financialStatus")}</Form.Label>
                                        <p>{getFinancialStatusText(financialStatus)}</p>
                                    </Form.Group>
                                </Row>
                                {customerType !== 3 ? 
                                <>
                                    <hr className="my-5" />
                                    <h5 className="text-uppercase mb-3">{t("groupsAndCustomized.passengers.tableTitle")}</h5>
                                    <Row>
                                        {/* tabela de passageiros */}
                                        <GroupFilePassengers
                                        action={getPassengers}
                                        info={arrayPassengers}
                                        />
                                    </Row>
                                </> 
                                : <></>}
                                <hr className="my-5" />
                                <h5 className="text-uppercase mb-3">{t("groupsAndCustomized.services.tableTitle")}</h5>
                                <Row>
                                    {/* tabela de serviços */}
                                    <GroupFileServices
                                       action={getServices}
                                       info={arrayServices}
                                       totalQuantity={totalQuantity}
                                       totalAdult={totalAdult}
                                       totalChild={totalChild}
                                       totalInfant={totalInfant}
                                       markup={watchMarkup}
                                       //control={control}
                                       //errors={errors}
                                       //setValue={setValue}
                                    /> 
                                </Row>
                                <hr className="my-5" />
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>{t("groupsAndCustomized.totalReceive")}</Form.Label>
                                        <p>{formatValue(totalReceive)}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>{t("groupsAndCustomized.totalPay")}</Form.Label>
                                        <p>{formatValue(totalPay)}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>{t("groupsAndCustomized.profitAmount")}</Form.Label>
                                        <p>{formatValue(profitAmount)}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>{t("groupsAndCustomized.profitTax")}</Form.Label>
                                        <p>{formatValue(profitTax)}</p>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    {/* A fatura será paga por? Valor Total; Serviço Discriminado. */}
                                    <Form.Group as={Col} md={6}>
                                        <Form.Label>{t("groupsAndCustomized.invoiceType")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="invoiceType"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className='d-flex align-items-center' style={{ height: "43px" }}>
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.invoiceTypeOpts.totalValue")}
                                                        // name="customerType"
                                                        id="invoiceTypeRadios1"
                                                        onClick={() => { setInvoiceType(1) }}
                                                        checked={invoiceType === 1}
                                                        className="col-md-6"
                                                    />
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.invoiceTypeOpts.serviceItemized")}
                                                        // name="customerType"
                                                        id="invoiceTypeRadios2"
                                                        onClick={() => { setInvoiceType(2) }}
                                                        checked={invoiceType === 2}
                                                        className="col-md-6"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                    {invoiceType === 1?  
                                    <Form.Group as={Col} md={2}>
                                        <Form.Label>{t("groupsAndCustomized.totalAmount")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="totalAmount"
                                            rules={{ required: { value: true, message: t('groupsAndCustomized.validationMsg.totalAmount') }, }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.totalAmount ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    //onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    //value={totalAmount}
                                                    //onChange={(e: any) => { setTotalAmount(e.target.value); }}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="totalAmount"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    : <></>}
                                </Row>
                                <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                    {disableButton === false?
                                    <Button type="submit" className="form-button">{t('groupsAndCustomized.buttonSave')}</Button>
                                    :
                                    <Button disabled={true} className="form-button">{t('groupsAndCustomized.buttonSave')}</Button>
                                    }
                                </Row>
                            </Form>
                        </Container>
                    </Card.Body>
                </Accordion>
            </Card>

            <Modal
                className="modal-custom modalGroupFile modalGroupFilePayment"
                show={modalResponse !== null}
                onHide={() => {
                    // setCheckoutResponse('');
                    setModalContent('loading');
                    setModalResponse(null)
                    setDisableButton(false);
                    //if (paymentMethod !== '111') {
                    //    resetForm();
                    //}
                    if(modalContent === "success") {
                        window.location.href = window.location.origin + '/list-groups-customized';
                    }
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
            >
                <>
                    {modalContent === "success" && <Modal.Header closeButton></Modal.Header>}
                    <Modal.Body>
                        <ModalContentPayment
                            //pix={['96', '100', '100', '111'].includes(paymentMethod)}
                            pix={true}
                            modalContent={modalContent}
                            checkoutResponse={checkoutResponse}
                            resetCart={resetForm}
                            setModalResponse={setModalResponse}
                            //getValues={getValues}
                            watchLanguage={'1'}
                        />

                    </Modal.Body>
                </>
            </Modal>
        </>
    );
}

export default GroupFileForm;