import React, { Key, useEffect, useState } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel?: any,
  propsErrors: any,
  setSupplier?: any,
  setValue?: any,
  t?:any
};

const GetSuppliersIdentity: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, setSupplier, setValue, t
}: propPlus) => {

  const [info, setInfo] = useState<any>(null);

  const handleChangeMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if(setSupplier){
      setSupplier(newValue);
    }
    if(setValue){
      setValue(propsField.name, newValue);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listAccountingAccounts() {
      try {
        const { data } = await api.get(`/BillsPayable/GetSuppliers`, config);
        if (data.status !== 400) {
          setInfo(data.data)
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listAccountingAccounts();
  }, []);

  if (info !== null) {
    console.log(t)
    return (
      <>
        <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error" : "endpoint"}>
          <Form.Select
            {...propsField}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            as="select"
            variant="standard"
            margin="normal"
            required
            onChange={handleChangeMethod}
            //defaultValue={propsField.value}
            //value={value}
          >
            <option value='' >{t !== undefined ? t('billsToPay.filter.all'): 'Todos'}</option>
            {info.length > 0 ? info.map((info: any, index: Key) => (
              <option key={index} value={info.supplierIdentity}>{`${info.compName}`}</option>
            )) : ''}
          </Form.Select>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetSuppliersIdentity;