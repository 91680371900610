import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import api from "../../../../services/api";

import AutoComplete from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

import { useTranslation } from "react-i18next";

import SingleCalendarTime from "../../../../components/Calendar/SingleCalendarTime";

import SetTransfersItem from "../../../../infra/Transfers/SetTransfersItem";

//STYLES
import "./TabSearchs.scss";
import i18next from "i18next";
import { Form, InputGroup, Col } from "react-bootstrap";
import SingleCalendarWithDefaultValue from "../../../../components/Calendar/SingleCalendarWithDefaultValue";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useForm } from "react-hook-form";
import { relative } from "path";

const TabSearchs = (props: any) => {

  const [t] = useTranslation();

  const [lng, setLng] = useState<string>("BR");

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [tours, setTours] = useState<any>("");
  const [locations, setLocations] = useState<any>("");
  const [isOpen, setIsOpen] = useState<any>(false);
  const [searchValue, setSearchValue] = useState<any>("");

  const [num, setNum] = useState<any>(1);
  const [origin, setOrigin] = useState<any>("");
  const [destiny, setDestiny] = useState<any>("");
  const [time, setTime] = useState<any>("");

  const [latOrigin, setLatOrigin] = useState<any>("");
  const [longOrigin, setLongOrigin] = useState<any>("");
  const [latDestiny, setLatDestiny] = useState<any>("");
  const [longDestiny, setLongDestiny] = useState<any>("");

  const [errorDestine, setErrorDestine] = useState<string>("");
  const [errorOrigin, setErrorOrigin] = useState<string>("");
  const [errorDateTime, setErrorDateTime] = useState<string>("");
  const [errorActivity, setErrorActivity] = useState<string>("");

  if (localStorage.getItem("transferSIG") === null) {
    localStorage.setItem("transferSIG", '[]');
  }

  const transferSIG: any = localStorage.getItem("transferSIG");
  const transferItemJSON = JSON.parse(transferSIG);

  useEffect(() => {
    if (i18next.language === "pt-BR" || i18next.language === "pt") {
      setLng("BR")
    } else if (i18next.language === "en") {
      setLng("EN")
    } else {
      setLng("ES")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  const addToTransfers = (itemOption: any) => {
    const item = {
      date: time[0]?.split(' ')[0],
      destine: destiny,
      latDestino: latDestiny,
      latOrigem: latOrigin,
      lngDestino: longDestiny,
      lngOrigem: longOrigin,
      numPeople: num,
      origin: origin,
      time: time[0]?.split(' ')[1]
    }

    //Validação do TabSeach Transfer 
    if (origin === "" || destiny === "" || (time[0]?.split(' ')[1] === undefined && time[0]?.split(' ')[0] === undefined)) {

      if (origin === "") {
        setErrorOrigin(`${t("sales.tabSearchs.fillUpOrigin")}`);

        setTimeout(() => {
          setErrorOrigin("");
        }, 2000);

      }

      if (destiny === "") {
        setErrorDestine(`${t("sales.tabSearchs.fillUpDestination")}`);

        setTimeout(() => {
          setErrorDestine("");
        }, 2000);
      }

      if (time[0]?.split(' ')[1] === undefined && time[0]?.split(' ')[0] === undefined) {
        setErrorDateTime(`${t("sales.tabSearchs.fillUpDateTime")}`);

        setTimeout(() => {
          setErrorDateTime("");
        }, 2000);

      }
    } else {
      props.setSearch(item)
      SetTransfersItem(item)
    }
    //END - Validação do TabSeach Transfer 
  }

  function less() {
    if (num > 1) {
      setNum(num - 1)
    }
  }

  function more() {
    setNum(num + 1)
  }

  function getLocations(data: any) {
    var aux: any = [];

    data.forEach((element: any) => {
      if (!aux.includes(element.eventLocation)) {
        aux.push(element.eventLocation);
      }
    });

    setLocations(aux);
    // 
  }

  const searchTours = async () => {

    const response = await api.post(
      "/Products/SearchByChannel",
      {
        term: searchTerm,
        channel: localStorage.getItem('idCanal') || 1,
        lang: lng,
        productType: 0,
        eventDate: props.eventDate.split('/').reverse().join('-')
      }
    );

    if (response.data.data !== null) {
      setTours(response.data.data);
      getLocations(response.data.data);
      isOpen === false ? setIsOpen(true) : setIsOpen(false);
    }
  };

  const handleClickCity = (event: React.MouseEvent<HTMLElement>) => {
    var element = (event.target as HTMLButtonElement).dataset.city;

    setSearchValue(element);

    setIsOpen(false);
  };

  const handleClickTour = (event: React.MouseEvent<HTMLElement>) => {
    var element = (event.target as HTMLButtonElement).dataset.tour;
    //var typeE = (event.target as HTMLButtonElement).dataset.busca;
    /* var codeE =
      (event.target as HTMLButtonElement).dataset.busca === "tour"
        ? (event.target as HTMLButtonElement).dataset.code
        : null; */
    setSearchValue(element);
    /* const tourSelect = {
      slug: convertToSlug(element?.split("-")[0]),
      type: typeE,
      code: codeE,
    }; */
    //setValueSelect(tourSelect);
    // console.log(valueSelect);
    setIsOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
    setSearchTerm(newValue);
    if (newValue.length > 3) {
      searchTours();
    }
  };

  useEffect(() => {
    if (searchTerm.length > 3) {
      searchTours();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eventDate])

  const handleFocus = () => {

    if (searchValue.length > 3) {
      isOpen === false ? setIsOpen(true) : setIsOpen(false);
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  const handleClickSearch = () => {

    //Validação do TabSeach Tour
    if (searchValue !== "" && searchValue.length >= 3) {
      props.setSearch(searchValue)
    } else {

      if (searchValue === "") {
        setErrorActivity(`${t("sales.tabSearchs.fillUpActivity")}`);
      } else {
        setErrorActivity(`${t("sales.tabSearchs.twoCaracters")}`);
      }

      setTimeout(() => {
        setErrorActivity("");
      }, 2000);

    }
    //END - Validação do TabSeach Transfer

  };

  function activeTabChoice(id: any) {
    let element = document.getElementById(id);
    let disable = document.querySelectorAll(".nav-tab-choice");
    let pane = document.querySelectorAll(".tab-pane");

    disable[0].classList.remove("active");
    disable[1].classList.remove("active");

    element?.classList.add("active");

    if (disable[0].classList.contains('active') === true) {
      pane[0].classList.add("active");
      pane[1].classList.remove("active");
      props.setSelected(1);
    } else if (disable[1].classList.contains('active') === true) {
      pane[1].classList.add("active");
      pane[0].classList.remove("active");
      props.setSelected(2);
    }
  }

  useEffect(() => {
    let element = document.getElementById('nav-2-tab');
    let disable = document.querySelectorAll(".nav-tab-choice");
    let pane = document.querySelectorAll(".tab-pane");
    if (window.location.href.split('/')[3] === 'transfers') {

      //Remover classe
      pane[0].classList.remove("active");
      disable[0].classList.remove("active");

      //Adiciona Classe
      element?.classList.add("active");
      pane[1].classList.add("active");

      setNum(transferItemJSON.numPeople);
      setOrigin(transferItemJSON.origin);
      setDestiny(transferItemJSON.destine);
    } else {

    }
  }, [transferItemJSON.numPeople, transferItemJSON.origin, transferItemJSON.destine])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();


  const onSubmit = (data: any) => {
    //Validação do TabSeach Tour
    if (searchValue !== "" && searchValue.length >= 3) {
      if(props.search !== searchValue){
        props.setSearch(searchValue)
      }else{
        props.setToggleAction((prev: any) => { return (!prev) })
      }
    } else {

      if (searchValue === "") {
        setErrorActivity(`${t("sales.tabSearchs.fillUpActivity")}`);
      } else {
        setErrorActivity(`${t("sales.tabSearchs.twoCaracters")}`);
      }

      setTimeout(() => {
        setErrorActivity("");
      }, 2000);

    }
    //END - Validação do TabSeach Transfer
  }

  return (
    <div className="tab-search-custom py-3">
      <nav className="px-3">
        <div className="row m-0 bg-white">
          <div
            className="nav nav-tabs col-12 d-flex justify-content-between border-bottom-0"
            id="nav-tab"
            role="tablist"
          >
            <div className="d-flex pt-2">
              <Link
                className="nav-item nav-link rounded-top active text-center display-6 text-uppercase text-muted nav-tab-choice"
                onClick={() => activeTabChoice("nav-1-tab")}
                id="nav-1-tab"
                data-toggle="tab"
                data-btn="tab"
                data-linkcontent="nav-content-3"
                to="#"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >
                <span id="passeio">
                  {t("sales.tabSearchs.activities")}
                </span>
              </Link>

              <Link
                className="nav-item nav-link rounded-top text-center display-6 text-uppercase text-muted nav-tab-choice"
                onClick={() => activeTabChoice("nav-2-tab")}
                id="nav-2-tab"
                data-toggle="tab"
                to="#"
                data-btn="tab"
                data-linkcontent="nav-content-6"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >

                <span>
                  {t("sales.tabSearchs.transfers")}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <Form
        className="h-100"
        noValidate
        validated={false}
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e: any) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}
      >
        {/* <!-- Barra de pesquisas--> */}
        <div
          className="tab-content"
          id="nav-tabContent"
          style={{ borderLeft: "1px solid #fff" }}
        >
          {/* <!--Nav Atividades --> */}
          <div
            className="tab-pane fade show active p-3 py-2 py-md-3"
            id="nav-3"
            role="tabpanel"
            aria-labelledby="nav-3-tab"
          >
            <div className="row d-flex bd-highlight">
              <div className="col col-12 col-md-8 col-lg-10 ">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text rounded-left-lg spanIcon"
                      id="homeDestino"
                    >
                      {props.isLoading === false
                        ?
                        <FontAwesomeIcon
                          icon={["fal", "search"]}
                          size="1x"
                          className="mr-2"
                        />
                        :
                        <>
                          <div className="load primary"></div></>
                      }
                    </span>
                  </div>
                  <input
                    type="text"
                    value={searchValue}
                    className="form-control form-control-lg frm-sm pl-5 rounded"
                    style={{ marginRight: '24px' }}
                    data-input="search"
                    autoComplete="off"
                    aria-label="Destino"
                    aria-describedby="tourDestino"
                    id="tourDestino"
                    placeholder={t("sales.tabSearchs.searchForActivity")}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />

                  <Form.Group as={Col} md="2" className="">
                    <div style={{ position: 'relative' }}>
                      <Form.Label style={{ position: 'absolute', top: '-30px' }}>
                        Dia do evento
                      </Form.Label>
                    </div>
                    <Controller
                      control={control}
                      name="eventDate"
                      render={({ field }: any) => (
                        <InputGroup hasValidation className="" size="sm">
                          <SingleCalendarWithDefaultValue
                            date={props.eventDate}
                            setValue={props.setEventDate}
                            deleteButton={true}
                          />
                        </InputGroup>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="eventDate"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <div
                    id="search-menu"
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: "0px",
                      zIndex: 10,
                      backgroundColor: "#fff",
                      padding: "10px",
                      width: "81%",
                      color: "#000"
                    }}
                    className={isOpen === true ? "d-block" : "d-none"}

                  >
                    <div id="search-dataset-allCitys">
                      <h5 className="league-name">{t("sales.tabSearchs.cities")}</h5>
                      {locations.length > 0
                        ? locations.map((city: any, index: any) => (
                          <p
                            key={index}
                            onClick={handleClickCity}
                            style={{ cursor: "pointer" }}
                            className="chooice tt-suggestion tt-selectable"
                            data-busca="city"
                            data-input="search"
                            data-value="{city}"
                            data-city={city}
                          >
                            <span className="result-info city">{city}</span>
                          </p>
                        ))
                        : ""}
                    </div>
                    <div id="search-dataset-allTourNames">
                      <h5 className="league-name">{t("sales.tabSearchs.tours")}</h5>
                      {tours.length >= 1
                        ? tours.map((tours: any, index: any) => (
                          <p
                            key={index}
                            onClick={handleClickTour}
                            style={{ cursor: "pointer" }}
                            className="chooice tt-suggestion tt-selectable"
                            data-busca="tour"
                            data-input="search"
                            data-code={tours.productCode}
                            data-tour={tours.description}
                          >
                            <span className="content-text">
                              <span className="result-info">
                                {tours.description}
                              </span>
                              <span className="price-search">
                                {" "}
                                <small>{t("sales.tabSearchs.from")}</small>: R$ {tours.price}
                              </span>
                            </span>
                          </p>
                        ))
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="d-flex mt-1 mx-1">
                  <small style={{ color: "red" }}>{errorActivity}</small>
                </div>

              </div>
              <div className="col col-12 col-md-4 col-lg-2 mt-3 mt-lg-0">
                <button
                  className="btn btn-search text-white form-control py-0 rounded btn-lg"
                  data-btn="search"
                  type="submit"
                  id="button-search"
                // onClick={handleClickSearch}
                >
                  <i className="pl-1 pr-2 fas fa-search"></i>
                  <span id="buscar-tour">{t("sales.tabSearchs.search")}</span>
                </button>
              </div>
            </div>
          </div>
          {/* <!--Nav Transfers --> */}
          <div
            className="tab-pane fade show p-3 py-2 py-md-3"
            id="nav-2"
            role="tabpanel"
            aria-labelledby="nav-2-tab"
          >
            <div className="row d-flex bd-highlight">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="input-group mb-2 mb-lg-0">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text rounded-left-lg spanIcon"
                      id="homeDestino"
                    >
                      <FontAwesomeIcon
                        icon={["fal", "map-marker-alt"]}
                        size="1x"
                        className="mr-2"
                      />
                    </span>
                  </div>
                  <AutoComplete
                    options={{
                      types: ["establishment"],
                      fields: ["name"]
                    }}
                    defaultValue={origin}
                    placeholder={t("sales.tabSearchs.origin")}
                    apiKey="AIzaSyBv2aZ2YO_aW4PIEmXoxHgxC8Ps8DB0o-s"
                    onPlaceSelected={(place) => {
                      console.log(place)
                      setOrigin(place.name);
                      // eslint-disable-next-line no-lone-blocks
                      {
                        geocodeByAddress(`${place.name}`)
                          .then(results => getLatLng(results[0]))
                          .then(({ lat, lng }) => {
                            setLatOrigin(lat);
                            setLongOrigin(lng);
                            console.log(`Origem: LAT - ${lat} - LONG - ${lng}`)
                          }
                          );
                      }
                    }}
                  />
                  <div className="d-flex mt-1 mx-1">
                    <small style={{ color: "red" }}>{errorOrigin}</small>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="input-group mb-2 mb-lg-0">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text rounded-left-lg spanIcon"
                      id="homeDestino"
                    >
                      <FontAwesomeIcon
                        icon={["fal", "map-marker-alt"]}
                        size="1x"
                        className="mr-2"
                      />
                    </span>
                  </div>

                  <AutoComplete
                    options={{
                      types: ["establishment"],
                      fields: ["name"]
                    }}
                    defaultValue={destiny}
                    placeholder={t("sales.tabSearchs.destination")}
                    apiKey="AIzaSyBv2aZ2YO_aW4PIEmXoxHgxC8Ps8DB0o-s"
                    onPlaceSelected={(place) => {
                      setDestiny(place.name);
                      // eslint-disable-next-line no-lone-blocks
                      {
                        geocodeByAddress(`${place.name}`)
                          .then(results => getLatLng(results[0]))
                          .then(({ lat, lng }) => {
                            setLatDestiny(lat);
                            setLongDestiny(lng);
                          }
                          );
                      }
                    }}
                  />
                  <div className="d-flex mt-1 mx-1">
                    <small style={{ color: "red" }}>{errorDestine}</small>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="input-group mb-2 mb-lg-0">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text rounded-left-lg spanIcon"
                      id="homeDestino"
                    >
                      <FontAwesomeIcon
                        icon={["fal", "clock"]}
                        size="1x"
                        className="mr-2"
                      />
                    </span>
                  </div>
                  <SingleCalendarTime setTime={setTime} />

                  <div className="d-flex mt-1 mx-1">
                    <small style={{ color: "red" }}>{errorDateTime}</small>

                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-1 num-people">
                <div className="input-group">
                  <div className="input-group-append">
                    <button className="btn btn-primary btn-less" type="button" onClick={less}>-</button>
                  </div>
                  <div className="icon-male">
                    <FontAwesomeIcon
                      icon={["fal", "male"]}
                      size="1x"
                    />
                  </div>
                  <input autoComplete='off' type="text" className="form-control" id="num" placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2" value={num} disabled />
                  <div className="input-group-append">
                    <button className="btn btn-primary btn-more" type="button" onClick={more}>+</button>
                  </div>
                </div>
              </div>

              <div className="col col-12 col-md-4 col-lg-2 mt-3 mt-lg-0">
                <button
                  className="btn btn-search text-white form-control py-0 rounded btn-lg"
                  data-btn="search"
                  type="button"
                  id="button-search"
                  onClick={addToTransfers}

                >
                  <i className="pl-1 pr-2 fas fa-search"></i>
                  <span id="buscar-tour">{t("sales.tabSearchs.search")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
export default TabSearchs;
